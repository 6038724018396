import Card from "react-bootstrap/Card";

import { useTenantConfig } from "contexts/TenantConfig";
import * as images from "assets/images";
import "./AuthPage.scss";

type AuthPageProps = {
  children: JSX.Element | JSX.Element[];
};

const AuthPage = ({ children }: AuthPageProps) => {
  const tenantConfig = useTenantConfig();
  const brandImg = tenantConfig.design.logo || images.brand;

  return (
    <div className="p-3 flex-grow-1 d-flex justify-content-center align-items-center">
      <Card className="border-0 shadow p-3 py-md-4 px-md-5 rounded-3 auth-card">
        <Card.Img src={brandImg} className="p-3" />
        <Card.Body>{children}</Card.Body>
      </Card>
    </div>
  );
};

export default AuthPage;
