/**
 * @generated SignedSource<<7f64cbf04abebb7aa28628be9469af8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplianceOperations_applianceId$data = {
  readonly id: string;
  readonly " $fragmentType": "ApplianceOperations_applianceId";
};
export type ApplianceOperations_applianceId$key = {
  readonly " $data"?: ApplianceOperations_applianceId$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplianceOperations_applianceId">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplianceOperations_applianceId",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Appliance",
  "abstractKey": null
};

(node as any).hash = "78721f1e3e8110dbfe04add2ca7df484";

export default node;
