type Props = {
  count: number;
  resource: React.ReactNode;
  direction?: "horizontal" | "vertical";
};

const ResourceCounter = ({
  count,
  resource,
  direction = "vertical",
}: Props) => {
  if (direction === "horizontal") {
    return (
      <div className="d-flex align-items-center">
        <span className="h1 mb-0 me-2">{count}</span>
        <small className="text-wrap">{resource}</small>
      </div>
    );
  }
  return (
    <div className="text-center">
      <div className="h1 mb-0">{count}</div>
      <div>{resource}</div>
    </div>
  );
};

export default ResourceCounter;
