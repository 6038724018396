import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { commitLocalUpdate, useRelayEnvironment } from "react-relay";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import type { Profile_getViewer_Query } from "api/__generated__/Profile_getViewer_Query.graphql";
import type { Profile_updateUserPreferences_Mutation } from "api/__generated__/Profile_updateUserPreferences_Mutation.graphql";
import type { Profile_upgradeToFullOrganization_Mutation } from "api/__generated__/Profile_upgradeToFullOrganization_Mutation.graphql";
import type {
  Profile_updateUserPassword_Mutation,
  UpdateUserPasswordInput,
} from "api/__generated__/Profile_updateUserPassword_Mutation.graphql";
import type { Profile_latestPrivacyPolicy_Query } from "api/__generated__/Profile_latestPrivacyPolicy_Query.graphql";
import type { Profile_acceptPrivacyPolicy_Mutation } from "api/__generated__/Profile_acceptPrivacyPolicy_Mutation.graphql";
import type { Profile_latestTermsAndConditions_Query } from "api/__generated__/Profile_latestTermsAndConditions_Query.graphql";
import type { Profile_acceptTermsAndConditions_Mutation } from "api/__generated__/Profile_acceptTermsAndConditions_Mutation.graphql";
import type { Profile_deleteAccount_Mutation } from "api/__generated__/Profile_deleteAccount_Mutation.graphql";
import * as images from "assets/images";
import Button from "components/Button";
import Can from "components/Can";
import ConfirmModal from "components/ConfirmModal";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import PreformattedText from "components/PreformattedText";
import ProfileForm, { Data as ProfileData } from "components/ProfileForm";
import UpgradeToFullOrganizationModal from "components/UpgradeToFullOrganizationModal";
import UpdatePasswordModal from "components/UpdatePasswordModal";
import SectionCard from "components/SectionCard";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tag from "components/Tag";
import { useViewer } from "contexts/Viewer";
import { useSession } from "contexts/Session";

const GET_VIEWER_QUERY = graphql`
  query Profile_getViewer_Query {
    viewer {
      id
      name
      email
      emailVerified
      organization {
        id
        name
      }
      roles {
        id
        name
      }
      preferences {
        language
      }
    }
  }
`;

const UPDATE_USER_PREFERENCES_MUTATION = graphql`
  mutation Profile_updateUserPreferences_Mutation(
    $input: UpdateUserPreferencesInput!
  ) {
    updateUserPreferences(input: $input) {
      user {
        preferences {
          language
        }
      }
    }
  }
`;

const UPGRADE_TO_FULL_ORGANIZATION_MUTATION = graphql`
  mutation Profile_upgradeToFullOrganization_Mutation(
    $input: UpgradeToFullOrganizationInput!
  ) {
    upgradeToFullOrganization(input: $input) {
      organization {
        id
        name
        thin
      }
    }
  }
`;

const UPDATE_USER_PASSWORD_MUTATION = graphql`
  mutation Profile_updateUserPassword_Mutation(
    $input: UpdateUserPasswordInput!
  ) {
    updateUserPassword(input: $input) {
      user {
        id
      }
    }
  }
`;

const GET_PRIVACY_POLICY_QUERY = graphql`
  query Profile_latestPrivacyPolicy_Query {
    latestPrivacyPolicy {
      id
      text
    }
  }
`;

const ACCEPT_PRIVACY_POLICY_MUTATION = graphql`
  mutation Profile_acceptPrivacyPolicy_Mutation(
    $input: AcceptPrivacyPolicyInput!
  ) {
    acceptPrivacyPolicy(input: $input) {
      user {
        id
      }
    }
  }
`;

const GET_TERMS_AND_CONDITIONS_QUERY = graphql`
  query Profile_latestTermsAndConditions_Query {
    latestTermsAndConditions {
      id
      text
    }
  }
`;

const ACCEPT_TERMS_AND_CONDITIONS_MUTATION = graphql`
  mutation Profile_acceptTermsAndConditions_Mutation(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptTermsAndConditions(input: $input) {
      user {
        id
      }
    }
  }
`;

const DELETE_ACCOUNT_MUTATION = graphql`
  mutation Profile_deleteAccount_Mutation {
    deleteAccount {
      user {
        id
      }
    }
  }
`;

type ProfileSidebarProps = {
  user?: Profile_getViewer_Query["response"]["viewer"];
};

const ProfileSidebar = ({ user }: ProfileSidebarProps) => {
  const { isThinOrganization } = useViewer();

  if (!user) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image fallbackSrc={images.profile} />
      </Stack>
    );
  }

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <div>
        <h4>{user.name}</h4>
        <p className="text-muted">{user.email}</p>
      </div>
      <Image fallbackSrc={images.profile} />
      <Can oneOf={["CAN_LIST_ROLES"]}>
        <div>
          {user.roles.map((role) => (
            <Tag className="mb-1 me-1" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </div>
      </Can>
      {!isThinOrganization && (
        <>
          <hr />
          {user.organization.name}
        </>
      )}
    </Stack>
  );
};

interface ViewerContentProps {
  getViewerQuery: PreloadedQuery<Profile_getViewer_Query>;
  getPrivacyPolicyQuery: PreloadedQuery<Profile_latestPrivacyPolicy_Query>;
  getTermsAndConditionsQuery: PreloadedQuery<Profile_latestTermsAndConditions_Query>;
}

const ProfileContent = ({
  getViewerQuery,
  getPrivacyPolicyQuery,
  getTermsAndConditionsQuery,
}: ViewerContentProps) => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [
    showUpgradeToFullOrganizationModal,
    setShowUpgradeToFullOrganizationModal,
  ] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const {
    acceptedLatestPrivacyPolicy,
    setAcceptedLatestPrivacyPolicy,
    acceptedLatestTermsAndConditions,
    setAcceptedLatestTermsAndConditions,
  } = useViewer();
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(
    !acceptedLatestPrivacyPolicy
  );
  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(!acceptedLatestTermsAndConditions);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const viewerData = usePreloadedQuery(GET_VIEWER_QUERY, getViewerQuery);
  // TODO: handle readonly roles type without mapping to mutable type
  const viewer = useMemo(
    () =>
      viewerData.viewer && {
        ...viewerData.viewer,
        organization: {
          ...viewerData.viewer.organization,
        },
        roles: viewerData.viewer.roles.map((role) => ({
          id: role.id,
          name: role.name,
        })),
        language: viewerData.viewer.preferences.language,
      },
    [viewerData.viewer]
  )!;
  const [
    updateUserPreferences,
  ] = useMutation<Profile_updateUserPreferences_Mutation>(
    UPDATE_USER_PREFERENCES_MUTATION
  );
  const [
    upgradeToFullOrganization,
    isUpgradingToFullOrganization,
  ] = useMutation<Profile_upgradeToFullOrganization_Mutation>(
    UPGRADE_TO_FULL_ORGANIZATION_MUTATION
  );
  const [
    updatePassword,
    isUpdatingPassword,
  ] = useMutation<Profile_updateUserPassword_Mutation>(
    UPDATE_USER_PASSWORD_MUTATION
  );
  const [
    acceptPrivacyPolicy,
    isAcceptingPrivacyPolicy,
  ] = useMutation<Profile_acceptPrivacyPolicy_Mutation>(
    ACCEPT_PRIVACY_POLICY_MUTATION
  );
  const [
    acceptTerms,
    isAcceptingTerms,
  ] = useMutation<Profile_acceptTermsAndConditions_Mutation>(
    ACCEPT_TERMS_AND_CONDITIONS_MUTATION
  );
  const [
    deleteAccount,
    isDeletingAccount,
  ] = useMutation<Profile_deleteAccount_Mutation>(DELETE_ACCOUNT_MUTATION);
  const privacyPolicy = usePreloadedQuery(
    GET_PRIVACY_POLICY_QUERY,
    getPrivacyPolicyQuery
  );
  const termsAndConditions = usePreloadedQuery(
    GET_TERMS_AND_CONDITIONS_QUERY,
    getTermsAndConditionsQuery
  );
  const { setAuthToken } = useSession();
  const relayEnvironment = useRelayEnvironment();

  const handleChange = useCallback(
    (data: ProfileData, isValid: boolean) => {
      if (data.language !== viewer.language) {
        const preferences = { language: data.language };
        updateUserPreferences({
          variables: {
            input: { preferences },
          },
          onCompleted(data, errors) {
            if (errors) {
              const errorFeedback = errors
                .map((error) => error.message)
                .join(". \n");
              setErrorFeedback(errorFeedback);
            }
          },
          onError(error) {
            setErrorFeedback(
              <FormattedMessage
                id="pages.Profile.form.loadingErrorFeedback"
                defaultMessage="Could not update the profile, please try again."
                description="Feedback for unknown loading error in the Profile page"
              />
            );
          },
          optimisticResponse: {
            updateUserPreferences: {
              user: {
                id: viewer.id,
                preferences: {
                  language: data.language,
                },
              },
            },
          },
        });
      }
    },
    [updateUserPreferences, viewer.language, viewer.id]
  );

  const handleUpgradeToFullOrganization = useCallback(
    (organizationName: string) => {
      const variables = { input: { organizationName } };
      upgradeToFullOrganization({
        variables,
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
          setShowUpgradeToFullOrganizationModal(false);
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Profile.upgradeToFullOrganizationErrorFeedback"
              defaultMessage="Could not upgrade to full organization, please try again."
              description="Feedback for unknown error while upgrading to full organization"
            />
          );
          setShowUpgradeToFullOrganizationModal(false);
        },
      });
    },
    [upgradeToFullOrganization]
  );

  const handleUpdatePassword = useCallback(
    (input: UpdateUserPasswordInput) => {
      updatePassword({
        variables: { input },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
          setShowUpdatePasswordModal(false);
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Profile.changePasswordErrorFeedback"
              defaultMessage="Could not change the password, please try again."
              description="Feedback for unknown error while chaning the password"
            />
          );
          setShowUpdatePasswordModal(false);
        },
      });
    },
    [updatePassword]
  );

  const handleAcceptPrivacyPolicy = useCallback(() => {
    acceptPrivacyPolicy({
      variables: {
        input: {
          acceptPrivacyPolicy: true,
          acceptedPrivacyPolicyId: privacyPolicy.latestPrivacyPolicy.id,
        },
      },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
        } else {
          setAcceptedLatestPrivacyPolicy(true);
        }
        setShowPrivacyPolicyModal(false);
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Profile.acceptPrivacyPolicyErrorFeedback"
            defaultMessage="Could not accept the privacy policy, please try again."
          />
        );
        setShowPrivacyPolicyModal(false);
      },
    });
  }, [
    acceptPrivacyPolicy,
    setAcceptedLatestPrivacyPolicy,
    privacyPolicy.latestPrivacyPolicy.id,
  ]);

  const handleAcceptTerms = useCallback(() => {
    acceptTerms({
      variables: {
        input: {
          acceptTermsAndConditions: true,
          acceptedTermsAndConditionsId:
            termsAndConditions.latestTermsAndConditions.id,
        },
      },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
        } else {
          setAcceptedLatestTermsAndConditions(true);
        }
        setShowTermsAndConditionsModal(false);
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Profile.acceptTermsErrorFeedback"
            defaultMessage="Could not accept terms and conditions, please try again."
          />
        );
        setShowTermsAndConditionsModal(false);
      },
    });
  }, [
    acceptTerms,
    setAcceptedLatestTermsAndConditions,
    termsAndConditions.latestTermsAndConditions.id,
  ]);

  const handleDeleteAccount = useCallback(() => {
    deleteAccount({
      variables: {},
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
        } else {
          // Invalidate relay store and logout the user
          commitLocalUpdate(relayEnvironment, (store) =>
            // @ts-expect-error wrong Relay types, store should be a RecordSourceSelectorProxy
            store.invalidateStore()
          );
          setAuthToken(null);
        }
        setShowDeleteAccountModal(false);
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Profile.deleteAccountErrorFeedback"
            defaultMessage="Could not delete the account, please try again in a few minutes."
          />
        );
        setShowDeleteAccountModal(false);
      },
    });
  }, [deleteAccount, relayEnvironment, setAuthToken]);

  return (
    <>
      <Alert show={!acceptedLatestPrivacyPolicy} variant="danger">
        <h4>
          <FormattedMessage
            id="pages.Profile.privacyPolicyAlert.title"
            defaultMessage="You have not accepted the latest privacy policy"
          />
        </h4>
        <div>
          <FormattedMessage
            id="pages.Profile.privacyPolicyAlert.message"
            defaultMessage="Since your last visit we updated our <link>Privacy Policy</link> document.{new_line}All features will be available only upon acceptance."
            values={{
              new_line: <br />,
              link: (chunks: React.ReactNode) => (
                <Button
                  variant="link"
                  onClick={() => setShowPrivacyPolicyModal(true)}
                >
                  {chunks}
                </Button>
              ),
            }}
          />
        </div>
      </Alert>
      <Alert show={!acceptedLatestTermsAndConditions} variant="danger">
        <h4>
          <FormattedMessage
            id="pages.Profile.termsAndConditionsAlert.title"
            defaultMessage="You have not accepted the latest terms and conditions"
          />
        </h4>
        <div>
          <FormattedMessage
            id="pages.Profile.termsAndConditionsAlert.message"
            defaultMessage="Since your last visit we updated our <link>Terms and Conditions</link> document.{new_line}All features will be only available upon acceptance."
            values={{
              new_line: <br />,
              link: (chunks: React.ReactNode) => (
                <Button
                  variant="link"
                  onClick={() => setShowTermsAndConditionsModal(true)}
                >
                  {chunks}
                </Button>
              ),
            }}
          />
        </div>
      </Alert>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <ProfileForm
        value={viewer}
        emailVerified={viewer.emailVerified}
        onChange={handleChange}
        onUpgradeToFullOrganization={() =>
          setShowUpgradeToFullOrganizationModal(true)
        }
        onUpdatePassword={() => setShowUpdatePasswordModal(true)}
        readonly
      />
      {/* TODO: use a single Row layout for this page and remove mt-0 */}
      <Row xs={1} lg={2} className="mt-0 g-4">
        <Col>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.Profile.accountTitle"
                defaultMessage="Account"
              />
            }
          >
            <div className="d-flex flex-column flex-md-row">
              <Button
                variant="solid-danger"
                onClick={() => setShowDeleteAccountModal(true)}
              >
                <FormattedMessage
                  id="pages.Profile.deleteAccountButton"
                  defaultMessage="Delete Account"
                />
              </Button>
            </div>
          </SectionCard>
        </Col>
      </Row>
      {showUpgradeToFullOrganizationModal && (
        <UpgradeToFullOrganizationModal
          defaultName={viewer.organization.name}
          onCancel={() => setShowUpgradeToFullOrganizationModal(false)}
          onConfirm={handleUpgradeToFullOrganization}
          isConfirming={isUpgradingToFullOrganization}
        />
      )}
      {showUpdatePasswordModal && (
        <UpdatePasswordModal
          onCancel={() => setShowUpdatePasswordModal(false)}
          onConfirm={handleUpdatePassword}
          isConfirming={isUpdatingPassword}
        />
      )}
      {showPrivacyPolicyModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Profile.privacyPolicyModal.title"
              defaultMessage="Privacy Policy"
            />
          }
          cancelLabel={
            <FormattedMessage
              id="pages.Profile.privacyPolicyModal.rejectButton"
              defaultMessage="Reject"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Profile.privacyPolicyModal.confirmButton"
              defaultMessage="Accept new Privacy Policy"
            />
          }
          onCancel={() => setShowPrivacyPolicyModal(false)}
          onConfirm={handleAcceptPrivacyPolicy}
          isConfirming={isAcceptingPrivacyPolicy}
        >
          <PreformattedText>
            {privacyPolicy.latestPrivacyPolicy.text}
          </PreformattedText>
        </ConfirmModal>
      )}
      {showTermsAndConditionsModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Profile.termsAndConditionsModal.title"
              defaultMessage="Terms and Conditions"
            />
          }
          cancelLabel={
            <FormattedMessage
              id="pages.Profile.termsAndConditionsModal.rejectButton"
              defaultMessage="Reject"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Profile.termsAndConditionsModal.confirmButton"
              defaultMessage="Accept new terms and conditions"
            />
          }
          onCancel={() => setShowTermsAndConditionsModal(false)}
          onConfirm={handleAcceptTerms}
          isConfirming={isAcceptingTerms}
        >
          <PreformattedText>
            {termsAndConditions.latestTermsAndConditions.text}
          </PreformattedText>
        </ConfirmModal>
      )}
      {showDeleteAccountModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Profile.deleteAccountModal.title"
              defaultMessage="Account Deletion"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Profile.deleteAccountModal.confirmButton"
              defaultMessage="Delete My Account"
            />
          }
          confirmVariant="solid-danger"
          onCancel={() => setShowDeleteAccountModal(false)}
          onConfirm={handleDeleteAccount}
          isConfirming={isDeletingAccount}
        >
          <FormattedMessage
            id="pages.Profile.deleteAccountModal.deleteNotice"
            defaultMessage="Deleting your account will delete all your data from our system.{br}This operation cannot be undone, are you sure you want to delete your account?"
            values={{
              br: <br />,
            }}
          />
        </ConfirmModal>
      )}
      <SidebarContent>
        <ProfileSidebar user={viewer} />
      </SidebarContent>
    </>
  );
};

const Profile = () => {
  const viewer = useViewer();
  const [getViewerQuery, getViewer] = useQueryLoader<Profile_getViewer_Query>(
    GET_VIEWER_QUERY
  );
  const [
    getPrivacyPolicyQuery,
    getPrivacyPolicy,
  ] = useQueryLoader<Profile_latestPrivacyPolicy_Query>(
    GET_PRIVACY_POLICY_QUERY
  );
  const [
    getTermsAndConditionsQuery,
    getTermsAndConditions,
  ] = useQueryLoader<Profile_latestTermsAndConditions_Query>(
    GET_TERMS_AND_CONDITIONS_QUERY
  );

  const getData = useCallback(() => {
    getViewer({});
    getPrivacyPolicy({}, { fetchPolicy: "network-only" });
    getTermsAndConditions({}, { fetchPolicy: "network-only" });
  }, [getViewer, getPrivacyPolicy, getTermsAndConditions]);

  useEffect(() => {
    getPrivacyPolicy({}, { fetchPolicy: "network-only" });
  }, [getPrivacyPolicy, viewer.acceptedLatestPrivacyPolicy]);

  useEffect(() => {
    getTermsAndConditions({}, { fetchPolicy: "network-only" });
  }, [getTermsAndConditions, viewer.acceptedLatestTermsAndConditions]);

  useEffect(() => {
    getViewer({});
  }, [getViewer]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Profile.title"
          defaultMessage="Profile Details"
          description="Title for the Profile page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ProfileSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ProfileSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getViewerQuery &&
            getPrivacyPolicyQuery &&
            getTermsAndConditionsQuery && (
              <ProfileContent
                getViewerQuery={getViewerQuery}
                getPrivacyPolicyQuery={getPrivacyPolicyQuery}
                getTermsAndConditionsQuery={getTermsAndConditionsQuery}
              />
            )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Profile;
