/**
 * @generated SignedSource<<2d0c08964fa1c7d045c79d9585f25c7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type i18n_getViewerLanguage_Query$variables = {};
export type i18n_getViewerLanguage_Query$data = {
  readonly viewer: {
    readonly preferences: {
      readonly language: string;
    };
  } | null;
};
export type i18n_getViewerLanguage_Query = {
  response: i18n_getViewerLanguage_Query$data;
  variables: i18n_getViewerLanguage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "i18n_getViewerLanguage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "i18n_getViewerLanguage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7723598922380a32c5674ebd144405a1",
    "id": null,
    "metadata": {},
    "name": "i18n_getViewerLanguage_Query",
    "operationKind": "query",
    "text": "query i18n_getViewerLanguage_Query {\n  viewer {\n    preferences {\n      language\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "964730db32e780ecae8a7dabf504b34d";

export default node;
