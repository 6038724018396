import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import _ from "lodash";

import type { ApplianceModelDetails$key } from "api/__generated__/ApplianceModelDetails.graphql";
import LocaleSelector, { Locale } from "components/LocaleSelector";
import SectionCard from "components/SectionCard";
import Stack from "components/Stack";

const APPLIANCE_MODEL_DETAILS_FRAGMENT = graphql`
  fragment ApplianceModelDetails on ApplianceModel {
    handle
    names {
      locale
      text
    }
    descriptions {
      locale
      text
    }
    partNumbers
  }
`;

type Props = {
  applianceModelRef: ApplianceModelDetails$key;
};

const ApplianceModelDetails = ({ applianceModelRef }: Props) => {
  const applianceModel = useFragment(
    APPLIANCE_MODEL_DETAILS_FRAGMENT,
    applianceModelRef
  );
  const locales = useMemo(
    () =>
      _.union(
        applianceModel.names.map((name) => name.locale),
        applianceModel.descriptions.map((description) => description.locale)
      ),
    [applianceModel]
  );
  const localizedFieldSets = useMemo(
    () =>
      locales.map((locale) => ({
        locale,
        name:
          applianceModel.names.find((name) => name.locale === locale)?.text ||
          "",
        description:
          applianceModel.descriptions.find(
            (description) => description.locale === locale
          )?.text || "",
      })),
    [applianceModel, locales]
  );

  const [currentFieldSet, setCurrentFieldSet] = useState(localizedFieldSets[0]);

  return (
    <Row xs={1} lg={2} className="g-4">
      <Col>
        <SectionCard
          title={
            <FormattedMessage
              id="ApplianceModelDetails.namingSection.title"
              defaultMessage="Name & Description"
            />
          }
        >
          {currentFieldSet ? (
            <Row xs={1} className="g-3">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage
                      id="ApplianceModelDetails.localeLabel"
                      defaultMessage="Language"
                    />
                  </Form.Label>
                  <LocaleSelector
                    value={currentFieldSet.locale as Locale}
                    locales={locales as Locale[]}
                    onChange={(locale) => {
                      const fieldSet = localizedFieldSets.find(
                        (fieldSet) => fieldSet.locale === locale
                      );
                      fieldSet && setCurrentFieldSet(fieldSet);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="appliance-model-details-name">
                  <Form.Label>
                    <FormattedMessage
                      id="ApplianceModelDetails.nameLabel"
                      defaultMessage="Model Name"
                    />
                  </Form.Label>
                  <Form.Control
                    value={currentFieldSet.name}
                    readOnly
                    plaintext
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="appliance-model-details-description">
                  <Form.Label>
                    <FormattedMessage
                      id="ApplianceModelDetails.descriptionLabel"
                      defaultMessage="Description"
                    />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={currentFieldSet.description}
                    readOnly
                    plaintext
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <Row xs={1} className="g-3">
              <Col>
                <p>
                  <FormattedMessage
                    id="ApplianceModelDetails.emptyNamesPrompt"
                    defaultMessage="There are no available names."
                  />
                </p>
              </Col>
            </Row>
          )}
        </SectionCard>
      </Col>
      <Col>
        <SectionCard
          title={
            <FormattedMessage
              id="ApplianceModelDetails.identifiersSection.title"
              defaultMessage="Identifiers"
            />
          }
        >
          <Form.Group controlId="appliance-model-details-handle">
            <Form.Label>
              <FormattedMessage
                id="ApplianceModelDetails.handleLabel"
                defaultMessage="Handle"
              />
            </Form.Label>
            <Form.Control value={applianceModel.handle} readOnly plaintext />
          </Form.Group>
          <Form.Group controlId="appliance-model-details-part-numbers">
            <Form.Label>
              <FormattedMessage
                id="ApplianceModelDetails.partNumbersLabel"
                defaultMessage="Part Numbers"
              />
            </Form.Label>
            <Stack gap={3}>
              {applianceModel.partNumbers.map((partNumber) => (
                <Form.Control
                  key={partNumber}
                  value={partNumber}
                  readOnly
                  plaintext
                />
              ))}
            </Stack>
          </Form.Group>
        </SectionCard>
      </Col>
    </Row>
  );
};

export default ApplianceModelDetails;
