import Cookies from "js-cookie";
import type { CookieAttributes } from "js-cookie";
import { Capacitor } from "@capacitor/core";
import { Http } from "@capacitor-community/http";

const MILLISECONDS_IN_A_DAY = 864e5;

const getCookie = async (name: string): Promise<string | null> => {
  if (!Capacitor.isNativePlatform()) {
    return Cookies.get(name) || null;
  }
  const cookie = await Http.getCookie({ key: name, url: window.location.host });
  return cookie.value || null;
};

const setCookie = async (
  name: string,
  value: string,
  options: CookieAttributes
) => {
  if (!Capacitor.isNativePlatform()) {
    return Cookies.set(name, value, options);
  }
  let expires: string | undefined;
  if (typeof options.expires === "number") {
    expires = new Date(
      Date.now() + options.expires * MILLISECONDS_IN_A_DAY
    ).toUTCString();
  }
  await Http.setCookie({
    key: name,
    value,
    url: window.location.host,
    expires,
  });
};

const removeCookie = async (name: string, options: CookieAttributes) => {
  if (!Capacitor.isNativePlatform()) {
    return Cookies.remove(name, options);
  }
  await Http.deleteCookie({
    key: name,
    url: window.location.host,
  });
};

export type { CookieAttributes };

export { getCookie, setCookie, removeCookie };
