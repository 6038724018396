/**
 * @generated SignedSource<<4a2b4fbff8ece792df1d5631f5ad88e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "CAN_ADD_CLIENTS" | "CAN_ASSIGN_APPLIANCES" | "CAN_CREATE_APPLIANCES" | "CAN_CREATE_APPLIANCE_MODELS" | "CAN_CREATE_ROLES" | "CAN_DELETE_APPLIANCES" | "CAN_DELETE_APPLIANCE_MODELS" | "CAN_DELETE_ROLES" | "CAN_DELETE_USERS" | "CAN_EDIT_APPLIANCES" | "CAN_EDIT_APPLIANCE_MODELS" | "CAN_EDIT_ROLES" | "CAN_GET_APPLIANCES" | "CAN_GET_APPLIANCE_MODELS" | "CAN_GET_ROLES" | "CAN_GET_USERS" | "CAN_INVITE_USERS" | "CAN_LIST_APPLIANCES" | "CAN_LIST_APPLIANCE_MODELS" | "CAN_LIST_CLIENTS" | "CAN_LIST_ROLES" | "CAN_LIST_USERS" | "CAN_MANAGE_USER_ROLES" | "CAN_REMOVE_CLIENTS" | "%future added value";
export type Viewer_getViewer_Query$variables = {};
export type Viewer_getViewer_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly organization: {
      readonly thin: boolean;
    };
    readonly permissions: ReadonlyArray<Permission>;
  } | null;
};
export type Viewer_getViewer_Query = {
  response: Viewer_getViewer_Query$data;
  variables: Viewer_getViewer_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Viewer_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Viewer_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d13843bc2375f9428b1d2370a26f132",
    "id": null,
    "metadata": {},
    "name": "Viewer_getViewer_Query",
    "operationKind": "query",
    "text": "query Viewer_getViewer_Query {\n  viewer {\n    id\n    permissions\n    organization {\n      thin\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddd17c2b0a77577b2b4f4544d8460810";

export default node;
