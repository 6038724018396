import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLanguage } from "i18n";
import { FormattedMessage } from "react-intl";
import semverGte from "semver/functions/gte";
import semverLte from "semver/functions/lte";
import semverValid from "semver/functions/valid";

import Button from "components/Button";
import Spinner from "components/Spinner";
import { useTenantConfig, getThemeUrl } from "contexts/TenantConfig";

const LEAST_RECENT_SUPPORTED_PROTOCOL = "0.1.0";
const MOST_RECENT_SUPPORTED_PROTOCOL = "0.1.0";

const isSupportedApp = <App extends { protocol: string }>({
  protocol,
}: App) => {
  return (
    semverValid(protocol) != null &&
    semverGte(protocol, LEAST_RECENT_SUPPORTED_PROTOCOL) &&
    semverLte(protocol, MOST_RECENT_SUPPORTED_PROTOCOL)
  );
};

type ExtenalApp<AppProps> = {
  mount: (div: ShadowRoot, props: AppProps, settings: Settings) => void;
  unmount: (div: ShadowRoot) => void;
};

type UserPreferences = {
  language: string;
};

type Settings = {
  themeUrl: string;
  userPreferences: UserPreferences;
};

type AppWrapperProps<AppProps> = {
  app: ExtenalApp<AppProps>;
  appProps: AppProps;
  onError: () => void;
};

const AppWrapper = <AppProps extends object>({
  app,
  appProps,
  onError,
}: AppWrapperProps<AppProps>) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const language = useLanguage();
  const tenantConfig = useTenantConfig();
  const themeUrl = getThemeUrl(tenantConfig.design.theme);
  const settings = useMemo(
    () => ({
      themeUrl,
      userPreferences: { language },
    }),
    [language, themeUrl]
  );

  useEffect(() => {
    if (rootRef.current && app) {
      let shadow = rootRef.current.shadowRoot;
      if (!shadow) {
        shadow = rootRef.current.attachShadow({ mode: "open" });
      }
      try {
        app.mount(shadow, appProps, settings);
      } catch {
        onError();
      }
      return () => {
        try {
          if (shadow) {
            app.unmount(shadow);
          }
        } catch {
          // This catch prevents the external app from
          // crashing the whole react tree
        }
      };
    }
  }, [app, appProps, settings, onError]);

  return <div ref={rootRef} />;
};

type AppError = "loading-error" | "run-time-error";

type RemoteAppProps<AppProps extends object> = {
  appId: string;
  appUrl: URL;
  appProps: AppProps;
};

const RemoteApp = <AppProps extends object>({
  appUrl,
  appProps,
}: RemoteAppProps<AppProps>) => {
  const [app, setApp] = useState<ExtenalApp<AppProps> | null>(null);
  const [error, setError] = useState<AppError | null>(null);

  const getApp = useCallback(() => {
    setError(null);
    import(/* webpackIgnore: true */ appUrl.toString())
      .then((module) => {
        if (module?.default) {
          setApp(module.default);
        } else {
          setError("run-time-error");
        }
      })
      .catch(() => {
        setError("loading-error");
        setApp(null);
      });
  }, [appUrl]);

  useEffect(() => {
    getApp();
  }, [getApp]);

  if (error === "loading-error") {
    return (
      <div className="p-4">
        <p>
          <FormattedMessage
            id="components.RemoteApp.loadFailed"
            defaultMessage="Couldn't load the App"
            description="Message shown when the external app download failed"
          />
        </p>
        <Button onClick={getApp}>
          <FormattedMessage
            id="components.RemoteApp.tryAgain"
            defaultMessage="Try again"
            description="Label for the button that tries to reload the external app"
          />
        </Button>
      </div>
    );
  }

  if (error === "run-time-error") {
    return (
      <div className="p-4">
        <p>
          <FormattedMessage
            id="components.RemoteApp.appError"
            defaultMessage="Something went wrong while running the App"
            description="Message shown when the external app crashes during execution"
          />
        </p>
        <Button onClick={() => setError(null)}>
          <FormattedMessage
            id="components.RemoteApp.tryAgain"
            defaultMessage="Try again"
            description="Label for the button that tries to reload the external app"
          />
        </Button>
      </div>
    );
  }

  if (!app) {
    return <Spinner />;
  }

  return (
    <AppWrapper
      app={app}
      appProps={appProps}
      onError={() => setError("run-time-error")}
    />
  );
};

export type { RemoteAppProps };

export { isSupportedApp };

export default React.memo(RemoteApp);
