import React from "react";

import Icon, { IconName } from "components/Icon";
import Spinner from "components/Spinner";
import "./Button.scss";

type ButtonVariant =
  | "text"
  | "text-primary"
  | "solid-primary"
  | "solid-danger"
  | "outline-primary"
  | "outline-danger"
  | "shadow-primary"
  | "shadow-secondary"
  | "shadow-danger"
  | "link";

const variantToClassName: Record<ButtonVariant, string> = {
  text: "",
  "text-primary": "text-primary",
  "solid-primary": "btn-primary",
  "solid-danger": "btn-danger",
  "outline-primary": "btn-outline-primary",
  "outline-danger": "btn-outline-danger",
  "shadow-primary": "bg-white shadow-sm text-primary",
  "shadow-secondary": "bg-white shadow-sm text-secondary",
  "shadow-danger": "bg-white shadow-sm text-danger",
  link: "btn-link p-0 align-baseline text-primary",
};

type ButtonBaseProps = {
  children?: React.ReactNode;
  className?: string;
  variant?: ButtonVariant;
  icon?: IconName;
  loading?: boolean;
  onClick?: () => void;
};

type ButtonProps<AS extends React.ElementType = "button"> = ButtonBaseProps & {
  as?: AS;
} & Omit<React.ComponentPropsWithoutRef<AS>, keyof ButtonBaseProps>;

function Button<AS extends React.ElementType = "button">({
  as,
  children,
  className = "",
  variant = "solid-primary",
  icon,
  loading = false,
  ...restProps
}: ButtonProps<AS>) {
  const buttonClassName = [
    "btn d-inline-flex align-items-center justify-content-center",
    variant ? variantToClassName[variant] : "",
    className,
  ].join(" ");

  const figure = loading ? (
    <Spinner size="sm" />
  ) : icon ? (
    <Icon icon={icon} />
  ) : null;

  const Component = as || "button";

  return (
    <Component type="button" className={buttonClassName} {...restProps}>
      {figure && <span className={children ? "me-2" : ""}>{figure}</span>}
      {children}
    </Component>
  );
}

export type { ButtonProps, ButtonVariant, IconName };

export default Button;
