/**
 * @generated SignedSource<<8d10aa71f5470994453dacf153086673>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Roles_getRoles_Query$variables = {};
export type Roles_getRoles_Query$data = {
  readonly roles: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type Roles_getRoles_Query = {
  response: Roles_getRoles_Query$data;
  variables: Roles_getRoles_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Role",
    "kind": "LinkedField",
    "name": "roles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Roles_getRoles_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Roles_getRoles_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bffb2b1ac75ae53e539d59f1a5c7018a",
    "id": null,
    "metadata": {},
    "name": "Roles_getRoles_Query",
    "operationKind": "query",
    "text": "query Roles_getRoles_Query {\n  roles {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "2039cbd5a97ebcaef2e60b1d0c44e7fb";

export default node;
