/**
 * @generated SignedSource<<1570c162e3d6e00a59fb3006d44aca0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Appliance_getTags_Query$variables = {};
export type Appliance_getTags_Query$data = {
  readonly existingApplianceTags: ReadonlyArray<string>;
};
export type Appliance_getTags_Query = {
  response: Appliance_getTags_Query$data;
  variables: Appliance_getTags_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "existingApplianceTags",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_getTags_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Appliance_getTags_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "861bbde1683aa110cebee7804692e3aa",
    "id": null,
    "metadata": {},
    "name": "Appliance_getTags_Query",
    "operationKind": "query",
    "text": "query Appliance_getTags_Query {\n  existingApplianceTags\n}\n"
  }
};
})();

(node as any).hash = "3bfe97b78e14bc9f0a1b4db35145fdd8";

export default node;
