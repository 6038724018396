import { useCallback } from "react";
import Select, { components } from "react-select";
import type { InputProps, OptionProps, SingleValueProps } from "react-select";
import _ from "lodash";

import Icon from "components/Icon";
import "./LocaleSelector.scss";

const RFC_5646 = {
  "af-ZA": "Afrikaans (South Africa)",
  "ar-AE": "Arabic (U.A.E.)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-DZ": "Arabic (Algeria)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (Libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SY": "Arabic (Syria)",
  "ar-TN": "Arabic (Tunisia)",
  "ar-YE": "Arabic (Yemen)",
  "az-AZ": "Azeri (Latin) (Azerbaijan)",
  "az-Cyrl-AZ": "Azeri (Cyrillic) (Azerbaijan)",
  "be-BY": "Belarusian (Belarus)",
  "bg-BG": "Bulgarian (Bulgaria)",
  "bs-BA": "Bosnian (Bosnia and Herzegovina)",
  "ca-ES": "Catalan (Spain)",
  "cs-CZ": "Czech (Czech Republic)",
  "cy-GB": "Welsh (United Kingdom)",
  "da-DK": "Danish (Denmark)",
  "de-AT": "German (Austria)",
  "de-CH": "German (Switzerland)",
  "de-DE": "German (Germany)",
  "de-LI": "German (Liechtenstein)",
  "de-LU": "German (Luxembourg)",
  "dv-MV": "Divehi (Maldives)",
  "el-GR": "Greek (Greece)",
  "en-AU": "English (Australia)",
  "en-BZ": "English (Belize)",
  "en-CA": "English (Canada)",
  "en-CB": "English (Caribbean)",
  "en-GB": "English (United Kingdom)",
  "en-IE": "English (Ireland)",
  "en-JM": "English (Jamaica)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Republic of the Philippines)",
  "en-TT": "English (Trinidad and Tobago)",
  "en-US": "English (United States)",
  "en-ZA": "English (South Africa)",
  "en-ZW": "English (Zimbabwe)",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-ES": "Spanish (Spain)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-PY": "Spanish (Paraguay)",
  "es-SV": "Spanish (El Salvador)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  "et-EE": "Estonian (Estonia)",
  "eu-ES": "Basque (Spain)",
  "fa-IR": "Farsi (Iran)",
  "fi-FI": "Finnish (Finland)",
  "fo-FO": "Faroese (Faroe Islands)",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-CH": "French (Switzerland)",
  "fr-FR": "French (France)",
  "fr-LU": "French (Luxembourg)",
  "fr-MC": "French (Principality of Monaco)",
  "gl-ES": "Galician (Spain)",
  "gu-IN": "Gujarati (India)",
  "he-IL": "Hebrew (Israel)",
  "hi-IN": "Hindi (India)",
  "hr-BA": "Croatian (Bosnia and Herzegovina)",
  "hr-HR": "Croatian (Croatia)",
  "hu-HU": "Hungarian (Hungary)",
  "hy-AM": "Armenian (Armenia)",
  "id-ID": "Indonesian (Indonesia)",
  "is-IS": "Icelandic (Iceland)",
  "it-CH": "Italian (Switzerland)",
  "it-IT": "Italian (Italy)",
  "ja-JP": "Japanese (Japan)",
  "ka-GE": "Georgian (Georgia)",
  "kk-KZ": "Kazakh (Kazakhstan)",
  "kn-IN": "Kannada (India)",
  "ko-KR": "Korean (Korea)",
  "kok-IN": "Konkani (India)",
  "ky-KG": "Kyrgyz (Kyrgyzstan)",
  "lt-LT": "Lithuanian (Lithuania)",
  "lv-LV": "Latvian (Latvia)",
  "mi-NZ": "Maori (New Zealand)",
  "mk-MK": "FYRO Macedonian (Former Yugoslav Republic of Macedonia)",
  "mn-MN": "Mongolian (Mongolia)",
  "mr-IN": "Marathi (India)",
  "ms-BN": "Malay (Brunei Darussalam)",
  "ms-MY": "Malay (Malaysia)",
  "mt-MT": "Maltese (Malta)",
  "nb-NO": "Norwegian (Bokm?l) (Norway)",
  "nl-BE": "Dutch (Belgium)",
  "nl-NL": "Dutch (Netherlands)",
  "nn-NO": "Norwegian (Nynorsk) (Norway)",
  "ns-ZA": "Northern Sotho (South Africa)",
  "pa-IN": "Punjabi (India)",
  "pl-PL": "Polish (Poland)",
  "ps-AR": "Pashto (Afghanistan)",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "qu-BO": "Quechua (Bolivia)",
  "qu-EC": "Quechua (Ecuador)",
  "qu-PE": "Quechua (Peru)",
  "ro-RO": "Romanian (Romania)",
  "ru-RU": "Russian (Russia)",
  "sa-IN": "Sanskrit (India)",
  "se-FI": "Sami (Finland)",
  "se-NO": "Sami (Norway)",
  "se-SE": "Sami (Sweden)",
  "sk-SK": "Slovak (Slovakia)",
  "sl-SI": "Slovenian (Slovenia)",
  "sq-AL": "Albanian (Albania)",
  "sr-BA": "Serbian (Latin) (Bosnia and Herzegovina)",
  "sr-Cyrl-BA": "Serbian (Cyrillic) (Bosnia and Herzegovina)",
  "sr-SP": "Serbian (Latin) (Serbia and Montenegro)",
  "sr-Cyrl-SP": "Serbian (Cyrillic) (Serbia and Montenegro)",
  "sv-FI": "Swedish (Finland)",
  "sv-SE": "Swedish (Sweden)",
  "sw-KE": "Swahili (Kenya)",
  "syr-SY": "Syriac (Syria)",
  "ta-IN": "Tamil (India)",
  "te-IN": "Telugu (India)",
  "th-TH": "Thai (Thailand)",
  "tl-PH": "Tagalog (Philippines)",
  "tn-ZA": "Tswana (South Africa)",
  "tr-TR": "Turkish (Turkey)",
  "tt-RU": "Tatar (Russia)",
  "uk-UA": "Ukrainian (Ukraine)",
  "ur-PK": "Urdu (Islamic Republic of Pakistan)",
  "uz-UZ": "Uzbek (Latin) (Uzbekistan)",
  "uz-Cyrl-UZ": "Uzbek (Cyrillic) (Uzbekistan)",
  "vi-VN": "Vietnamese (Viet Nam)",
  "xh-ZA": "Xhosa (South Africa)",
  "zh-CN": "Chinese (S)",
  "zh-HK": "Chinese (Hong Kong)",
  "zh-MO": "Chinese (Macau)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (T)",
  "zu-ZA": "Zulu (South Africa)",
};

type Locale = keyof typeof RFC_5646;

const LOCALES = Object.keys(RFC_5646) as Locale[];

type Option = {
  label: string;
  value: Locale;
};

const toOption = (locale: Locale) => ({
  label: locale in RFC_5646 ? RFC_5646[locale] : locale,
  value: locale,
});

const toValue = (option: Option) => option.value;

const options = LOCALES.map(toOption);

type LocaleOptionProps = {
  className?: string;
  option: Option;
};

const LocaleOption = ({
  className,
  option,
  ...restProps
}: LocaleOptionProps) => {
  const locale = option.value;
  const label = option.label;
  let localeImageSrc: string | undefined;
  if (locale in RFC_5646) {
    const iso639_1 = locale.slice(0, 2);
    localeImageSrc = `https://unpkg.com/language-icons/icons/${iso639_1}.svg`;
  }
  return (
    <div role="button" className="d-flex align-items-center" {...restProps}>
      <span className="mx-1">
        {localeImageSrc ? (
          <img alt={label} src={localeImageSrc} width={16} height={16} />
        ) : (
          <Icon icon="language" />
        )}
      </span>
      <span>{label}</span>
    </div>
  );
};

const CustomInput = (props: InputProps) => {
  const { selectProps } = props as InputProps & {
    selectProps: LocaleSelectorProps;
  };
  const forwardProps = _.pick(selectProps, ["data-testid"]);
  return <components.Input {...props} {...forwardProps} />;
};

const CustomOption = (props: OptionProps<Option, false>) => {
  const { className, data, innerProps } = props;
  return <LocaleOption className={className} option={data} {...innerProps} />;
};

const CustomSingleValue = (props: SingleValueProps<Option>) => {
  const { className, data, innerProps } = props;
  return <LocaleOption className={className} option={data} {...innerProps} />;
};

const customComponents = {
  Input: CustomInput,
  Option: CustomOption,
  SingleValue: CustomSingleValue,
};

type LocaleSelectorProps = {
  id?: string;
  name?: string;
  value?: Locale;
  onChange: (value: Locale) => void;
  locales?: Locale[];
  disabled?: boolean;
  isInvalid?: boolean;
  loading?: boolean;
  required?: boolean;
  placeholder?: string;
};

const LocaleSelector = ({
  id,
  name,
  value,
  onChange,
  locales,
  disabled = false,
  isInvalid = false,
  loading = false,
  required = false,
  placeholder,
  ...restProps
}: LocaleSelectorProps) => {
  const handleChange = useCallback(
    (option: Option | null) => {
      if (onChange && option) {
        onChange(toValue(option));
      }
    },
    [onChange]
  );

  const invalid = isInvalid || (required && value == null);
  const selectClassNamePrefix = "locale-selector";
  let selectClassName = selectClassNamePrefix;
  if (invalid) {
    selectClassName += " invalid";
  }

  const option = value && toOption(value);

  const props = {
    name,
    inputId: id,
    components: customComponents,
    placeholder,
    onChange: handleChange,
    value: option,
    options: locales ? locales.map(toOption) : options,
    isDisabled: disabled,
    isLoading: loading,
    classNamePrefix: selectClassNamePrefix,
    className: selectClassName,
    ...restProps,
    // use React Portals to fixed overlapping issues with other React component. See:
    // https://github.com/JedWatson/react-select/issues/1085
    // https://github.com/JedWatson/react-select/issues/1537
    menuPortalTarget: document.body,
    styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) },
  } as const;

  if (disabled) {
    return (
      <div id={id} {...restProps}>
        {option && <LocaleOption option={option} />}
      </div>
    );
  }

  return <Select {...props} />;
};

export type { Locale };

export { LOCALES };

export default LocaleSelector;
