import { Suspense, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";

import * as images from "assets/images";
import type { ApplianceModels_getApplianceModels_Query } from "api/__generated__/ApplianceModels_getApplianceModels_Query.graphql";
import ApplianceModelsTable from "components/ApplianceModelsTable";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import ResourceCounter from "components/ResourceCounter";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

const GET_APPLIANCE_MODELS_QUERY = graphql`
  query ApplianceModels_getApplianceModels_Query {
    applianceModels {
      ...ApplianceModelsTable_ApplianceModels
    }
  }
`;

type ApplianceModelsSidebarProps = {
  applianceModels?: ApplianceModels_getApplianceModels_Query["response"]["applianceModels"];
};

const ApplianceModelsSidebar = ({
  applianceModels = [],
}: ApplianceModelsSidebarProps) => {
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <Image src={images.devices} />
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.ApplianceModels.applianceModelsCounter.resource"
            defaultMessage="{count, plural, =0 {Appliance Models} one {Appliance Model} other {Appliance Models}}"
            values={{ count: applianceModels.length }}
          />
        }
        count={applianceModels.length}
      />
    </Stack>
  );
};

interface ApplianceModelsContentProps {
  getApplianceModelsQuery: PreloadedQuery<ApplianceModels_getApplianceModels_Query>;
}

const ApplianceModelsContent = ({
  getApplianceModelsQuery,
}: ApplianceModelsContentProps) => {
  const [searchText, setSearchText] = useState("");
  const { applianceModels } = usePreloadedQuery(
    GET_APPLIANCE_MODELS_QUERY,
    getApplianceModelsQuery
  );

  if (applianceModels.length === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.ApplianceModels.noApplianceModels.title"
              defaultMessage="There are no appliance models yet."
            />
          }
        >
          <Link route={Route.applianceModelsNew}>
            <FormattedMessage
              id="pages.ApplianceModels.noApplianceModels.message"
              defaultMessage="Add an appliance model"
            />
          </Link>
        </Result.Empty>
        <SidebarContent>
          <ApplianceModelsSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <ApplianceModelsTable
        applianceModelsRef={applianceModels}
        searchText={searchText}
      />
      <SidebarContent>
        <ApplianceModelsSidebar applianceModels={applianceModels} />
      </SidebarContent>
    </>
  );
};

const ApplianceModels = () => {
  const [
    getApplianceModelsQuery,
    getApplianceModels,
  ] = useQueryLoader<ApplianceModels_getApplianceModels_Query>(
    GET_APPLIANCE_MODELS_QUERY
  );

  const getData = useCallback(() => {
    getApplianceModels({});
  }, [getApplianceModels]);

  useEffect(getData, [getData]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ApplianceModels.title"
          defaultMessage="Model List"
          description="Title for the ApplianceModels page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplianceModelsSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplianceModelsSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getApplianceModelsQuery && (
            <ApplianceModelsContent
              getApplianceModelsQuery={getApplianceModelsQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default ApplianceModels;
