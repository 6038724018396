/**
 * @generated SignedSource<<6940124d12e178be4212bfec71f33419>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Appliance_applianceUpdated_Subscription$variables = {
  ids: ReadonlyArray<string>;
};
export type Appliance_applianceUpdated_Subscription$data = {
  readonly applianceUpdated: {
    readonly assignee: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly device: {
      readonly online: boolean;
    };
    readonly id: string;
    readonly name: string;
    readonly serial: string;
    readonly tags: ReadonlyArray<string>;
  };
};
export type Appliance_applianceUpdated_Subscription = {
  response: Appliance_applianceUpdated_Subscription$data;
  variables: Appliance_applianceUpdated_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_applianceUpdated_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "applianceUpdated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliance_applianceUpdated_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "applianceUpdated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04334a2295577bef5e62cdd455a430aa",
    "id": null,
    "metadata": {},
    "name": "Appliance_applianceUpdated_Subscription",
    "operationKind": "subscription",
    "text": "subscription Appliance_applianceUpdated_Subscription(\n  $ids: [ID!]!\n) {\n  applianceUpdated(ids: $ids) {\n    id\n    name\n    serial\n    tags\n    assignee {\n      id\n      name\n    }\n    device {\n      online\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e35ef52d332e970d7b97dc2723cdb10";

export default node;
