import React, { useCallback, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import _ from "lodash";

import Icon from "components/Icon";
import "./CheckboxTree.scss";

type TreeIdOrValue<Value extends string = string> =
  | { id: string; value?: Value }
  | { id?: string; value: Value };

type Tree<Value extends string = string, Props extends {} = {}> = {
  children?: Tree<Value, Props>[];
  label?: JSX.Element;
} & Props &
  TreeIdOrValue<Value>;

const linearizeTree = (tree: Tree): Omit<Tree, "children">[] => {
  if (!tree.children) {
    return [tree];
  }
  return [tree, ...tree.children.map(linearizeTree).flat()];
};

const getTreeValues = <Value extends string>(tree: Tree<Value>): Value[] => {
  return linearizeTree(tree)
    .map(({ value }) => value)
    .filter((value) => !!value) as Value[];
};

interface CheckboxTreeProps<
  Value extends string = string,
  TreeProps extends {} = {}
> {
  alwaysExpanded?: boolean;
  checked: readonly Value[];
  onChange?: (checked: Value[]) => void;
  readOnly?: boolean;
  renderLabel?: (tree: Tree<Value, TreeProps>) => JSX.Element;
  tree: Tree<Value, TreeProps>;
}

const CheckboxTree = <Value extends string, TreeProps extends {} = {}>({
  alwaysExpanded = false,
  checked,
  onChange,
  readOnly = false,
  renderLabel,
  tree,
}: CheckboxTreeProps<Value, TreeProps>) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const treeValues = getTreeValues(tree);
  const treeCheckedValues = _.intersection(checked, treeValues);
  const isTreeChecked = treeValues.length === treeCheckedValues.length;

  const toggleIsExpanded = useCallback(() => setIsExpanded((v) => !v), []);

  const handleCheck: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (readOnly) {
        return;
      }
      const isTreeRootChecked = event.target.checked;
      const treeValues = getTreeValues(tree);
      onChange &&
        onChange(
          isTreeRootChecked
            ? _.union(checked, treeValues)
            : _.difference(checked, treeValues)
        );
    },
    [readOnly, onChange, tree, checked]
  );

  const handleChangeChecked = useCallback(
    (nextChecked: Value[]) => {
      if (readOnly) {
        return;
      }
      if (!tree.value) {
        return onChange && onChange(nextChecked);
      }
      const treeValues = getTreeValues(tree);
      const descendantsValues = _.difference(treeValues, [tree.value]);
      const descendantsValuesChecked = _.intersection(
        nextChecked,
        descendantsValues
      );
      const descendantsValuesUnchecked = _.difference(
        descendantsValues,
        descendantsValuesChecked
      );
      onChange &&
        onChange(
          descendantsValuesUnchecked.length > 0
            ? _.difference(nextChecked, [tree.value])
            : _.union(nextChecked, [tree.value])
        );
    },
    [readOnly, onChange, tree]
  );

  const hasToggle = !_.isEmpty(tree.children) && !alwaysExpanded;

  return (
    <Accordion className="checkbox-tree">
      <div className="d-flex">
        {hasToggle && (
          <Accordion.Button
            className="px-1 py-0 w-auto"
            onClick={toggleIsExpanded}
          >
            <Icon icon={isExpanded ? "caretUp" : "caretDown"} />
          </Accordion.Button>
        )}
        <Form.Check
          id={tree.id || tree.value}
          type="checkbox"
          className={hasToggle ? "ms-2" : ""}
          label={renderLabel ? renderLabel(tree) : tree.label}
          checked={isTreeChecked}
          onChange={handleCheck}
          readOnly={readOnly}
          data-testid={`checkbox-tree-${tree.id || tree.value}`}
        />
      </div>
      <Accordion.Collapse eventKey="checkbox-tree-accordion" in={isExpanded}>
        <>
          {tree.children && (
            <div className={hasToggle ? "ms-5 ps-3" : "ms-5"}>
              {tree.children.map((child) => (
                <CheckboxTree
                  key={child.value}
                  tree={child}
                  checked={checked}
                  onChange={handleChangeChecked}
                  readOnly={readOnly}
                  renderLabel={renderLabel}
                />
              ))}
            </div>
          )}
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

interface Props<Value extends string = string, TreeProps extends {} = {}> {
  alwaysExpanded?: boolean;
  checked: readonly Value[];
  onChange?: (checked: Value[]) => void;
  readOnly?: boolean;
  renderLabel?: (tree: Tree<Value, TreeProps>) => JSX.Element;
  trees: Tree<Value, TreeProps>[];
}

const CheckboxTrees = <Value extends string, TreeProps extends {} = {}>({
  alwaysExpanded = false,
  checked,
  onChange,
  readOnly = false,
  renderLabel,
  trees,
}: Props<Value, TreeProps>) => (
  <div>
    {trees.map((tree) => (
      <CheckboxTree
        key={tree.id || tree.value}
        tree={tree}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        renderLabel={renderLabel}
        alwaysExpanded={alwaysExpanded}
      />
    ))}
  </div>
);

const addTreeProps = <
  TreeProps extends {} = {},
  Value extends string = string,
  OldProps extends {} = {}
>(
  getTreeProps: (tree: Tree<Value, OldProps>) => TreeProps
) => (tree: Tree<Value, OldProps>): Tree<Value, OldProps & TreeProps> => {
  const mappedChildren =
    tree.children && tree.children.map(addTreeProps(getTreeProps));
  const treeProps = getTreeProps({ ...tree, children: mappedChildren });
  const mappedTree = { ...tree, ...treeProps, children: mappedChildren };
  return mappedTree;
};

export type { Tree };

export { addTreeProps };

export default CheckboxTrees;
