/**
 * @generated SignedSource<<bc459902ce3712fadc3b1c2f27158571>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Topbar_getViewer_Query$variables = {};
export type Topbar_getViewer_Query$data = {
  readonly viewer: {
    readonly email: string;
    readonly id: string;
    readonly name: string;
  } | null;
};
export type Topbar_getViewer_Query = {
  response: Topbar_getViewer_Query$data;
  variables: Topbar_getViewer_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Topbar_getViewer_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Topbar_getViewer_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "062f15027e69a0ad1b1d7cb3336a5950",
    "id": null,
    "metadata": {},
    "name": "Topbar_getViewer_Query",
    "operationKind": "query",
    "text": "query Topbar_getViewer_Query {\n  viewer {\n    id\n    name\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5de5b801077a5c0995f1232f2db8899";

export default node;
