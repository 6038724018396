import RemoteApp, { isSupportedApp } from "components/RemoteApp";
import type { RemoteAppProps } from "components/RemoteApp";

type ApplianceAppProps = {
  astarteUrl: URL;
  realm: string;
  token: string;
  deviceId: string;
};

const ApplianceApp = ({
  appId,
  appUrl,
  appProps,
}: RemoteAppProps<ApplianceAppProps>) => {
  return <RemoteApp appId={appId} appUrl={appUrl} appProps={appProps} />;
};

export { isSupportedApp };

export default ApplianceApp;
