/**
 * @generated SignedSource<<6ed63b1081fc111ee38fdfa989da7489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "CAN_ADD_CLIENTS" | "CAN_ASSIGN_APPLIANCES" | "CAN_CREATE_APPLIANCES" | "CAN_CREATE_APPLIANCE_MODELS" | "CAN_CREATE_ROLES" | "CAN_DELETE_APPLIANCES" | "CAN_DELETE_APPLIANCE_MODELS" | "CAN_DELETE_ROLES" | "CAN_DELETE_USERS" | "CAN_EDIT_APPLIANCES" | "CAN_EDIT_APPLIANCE_MODELS" | "CAN_EDIT_ROLES" | "CAN_GET_APPLIANCES" | "CAN_GET_APPLIANCE_MODELS" | "CAN_GET_ROLES" | "CAN_GET_USERS" | "CAN_INVITE_USERS" | "CAN_LIST_APPLIANCES" | "CAN_LIST_APPLIANCE_MODELS" | "CAN_LIST_CLIENTS" | "CAN_LIST_ROLES" | "CAN_LIST_USERS" | "CAN_MANAGE_USER_ROLES" | "CAN_REMOVE_CLIENTS" | "%future added value";
export type Role_getRole_Query$variables = {
  id: string;
};
export type Role_getRole_Query$data = {
  readonly role: {
    readonly id: string;
    readonly name: string;
    readonly permissions: ReadonlyArray<Permission>;
  } | null;
};
export type Role_getRole_Query = {
  response: Role_getRole_Query$data;
  variables: Role_getRole_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Role",
    "kind": "LinkedField",
    "name": "role",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Role_getRole_Query",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Role_getRole_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44b156ec4f37c9c67bd2092a0891df8c",
    "id": null,
    "metadata": {},
    "name": "Role_getRole_Query",
    "operationKind": "query",
    "text": "query Role_getRole_Query(\n  $id: ID!\n) {\n  role(id: $id) {\n    id\n    name\n    permissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf936eec4b68ea39a89184f9e2b4c22d";

export default node;
