import React, {
  createContext,
  Suspense,
  useMemo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import graphql from "babel-plugin-relay/macro";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { ErrorBoundary } from "react-error-boundary";

import type {
  GlobalApps_getApplications_Query,
  GlobalApps_getApplications_Query$data,
} from "api/__generated__/GlobalApps_getApplications_Query.graphql";
import { useViewer } from "contexts/Viewer";
import { isSupportedApp } from "components/GlobalApp";

const GET_GLOBAL_APPS_QUERY = graphql`
  query GlobalApps_getApplications_Query {
    applications {
      id
      slug
      displayName
      protocol
      sourceUrl
      authToken
      baseApiUrl
      realmName
    }
  }
`;

type GlobalApp = GlobalApps_getApplications_Query$data["applications"][0];

const QueryError = ({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
}) => {
  useEffect(() => {
    const timeoutID = setTimeout(resetErrorBoundary, 60000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [resetErrorBoundary]);
  return <React.Fragment />;
};

type GlobalAppsQueryProps = {
  getGlobalAppsQuery?: PreloadedQuery<GlobalApps_getApplications_Query> | null;
  onQueryFinished: (applications: readonly GlobalApp[] | null) => void;
  onQueryError: () => void;
};

const GlobalAppsQuery = ({
  getGlobalAppsQuery,
  onQueryFinished,
  onQueryError,
}: GlobalAppsQueryProps) => {
  if (!getGlobalAppsQuery) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={QueryError} onReset={onQueryError}>
      <Suspense
        fallback={
          <div data-testid="global-apps-context-loading" className="d-none" />
        }
      >
        <GlobalApps
          getGlobalAppsQuery={getGlobalAppsQuery}
          onQueryFinished={onQueryFinished}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

type GlobalAppsProps = {
  getGlobalAppsQuery: PreloadedQuery<GlobalApps_getApplications_Query>;
  onQueryFinished: (applications: readonly GlobalApp[]) => void;
};

const GlobalApps = ({
  getGlobalAppsQuery,
  onQueryFinished,
}: GlobalAppsProps) => {
  const globalAppsData = usePreloadedQuery<GlobalApps_getApplications_Query>(
    GET_GLOBAL_APPS_QUERY,
    getGlobalAppsQuery
  );

  useEffect(() => {
    onQueryFinished(globalAppsData.applications);
  }, [onQueryFinished, globalAppsData]);

  return null;
};

type GlobalAppsContextValue = {
  readonly applications: readonly GlobalApp[] | null;
};

const GlobalAppsContext = createContext<GlobalAppsContextValue | null>(null);

interface GlobalAppsProviderProps {
  children: React.ReactNode;
}

const GlobalAppsProvider = ({ children }: GlobalAppsProviderProps) => {
  const { isAuthenticated } = useViewer();
  const [
    getGlobalAppsQuery,
    getGlobalApps,
  ] = useQueryLoader<GlobalApps_getApplications_Query>(GET_GLOBAL_APPS_QUERY);

  const [globalApps, setGlobalApps] = useState<readonly GlobalApp[] | null>(
    null
  );

  useEffect(() => {
    setGlobalApps(null);
    if (isAuthenticated) {
      getGlobalApps({}, { fetchPolicy: "network-only" });
    }
  }, [isAuthenticated, getGlobalApps]);

  const contextValue: GlobalAppsContextValue = useMemo(
    () => ({
      applications: globalApps,
    }),
    [globalApps]
  );

  const handleQueryFinished = useCallback(
    (apps: readonly GlobalApp[] | null) => {
      if (Array.isArray(apps)) {
        return setGlobalApps(apps.filter(isSupportedApp));
      }
      setGlobalApps(apps);
    },
    [setGlobalApps]
  );

  const refreshGlobalApps = useCallback(() => {
    getGlobalApps({}, { fetchPolicy: "network-only" });
  }, [getGlobalApps]);

  return (
    <GlobalAppsContext.Provider value={contextValue}>
      <GlobalAppsQuery
        getGlobalAppsQuery={getGlobalAppsQuery}
        onQueryFinished={handleQueryFinished}
        onQueryError={refreshGlobalApps}
      />
      {children}
    </GlobalAppsContext.Provider>
  );
};

const useGlobalApps = (): GlobalAppsContextValue => {
  const contextValue = useContext(GlobalAppsContext);
  if (contextValue == null) {
    throw new Error("GlobalAppsContext has not been provided");
  }
  return contextValue;
};

export type { GlobalApp };

export { useGlobalApps, GlobalAppsContext };

export default GlobalAppsProvider;
