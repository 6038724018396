/**
 * @generated SignedSource<<c07229753fe73ad44b5773246ad2628e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Profile_latestPrivacyPolicy_Query$variables = {};
export type Profile_latestPrivacyPolicy_Query$data = {
  readonly latestPrivacyPolicy: {
    readonly id: string;
    readonly text: string;
  };
};
export type Profile_latestPrivacyPolicy_Query = {
  response: Profile_latestPrivacyPolicy_Query$data;
  variables: Profile_latestPrivacyPolicy_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TenantPrivacyPolicy",
    "kind": "LinkedField",
    "name": "latestPrivacyPolicy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_latestPrivacyPolicy_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Profile_latestPrivacyPolicy_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "31edd1d7668487f6cb13b4a29dd5b0cb",
    "id": null,
    "metadata": {},
    "name": "Profile_latestPrivacyPolicy_Query",
    "operationKind": "query",
    "text": "query Profile_latestPrivacyPolicy_Query {\n  latestPrivacyPolicy {\n    id\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "36b3c134b9a84ef000b9e279db2e0fe1";

export default node;
