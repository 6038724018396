import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { defineMessages, FormattedMessage } from "react-intl";
import type { MessageDescriptor } from "react-intl";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Stack from "react-bootstrap/Stack";

import type { Topbar_getViewer_Query } from "api/__generated__/Topbar_getViewer_Query.graphql";

import * as images from "assets/images";
import Button from "components/Button";
import type { IconName } from "components/Button";
import { useCan } from "components/Can";
import Icon from "components/Icon";
import SegmentedControl from "components/SegmentedControl";
import { isSupportedApp } from "components/GlobalApp";
import { useGlobalApps } from "contexts/GlobalApps";
import { useTenantConfig } from "contexts/TenantConfig";

import {
  Link,
  matchingRoute,
  ParametricRoute,
  Route,
  matchingParametricRoute,
} from "Navigation";
import "./Topbar.scss";
import Permission from "api/Permission";

const GET_VIEWER_QUERY = graphql`
  query Topbar_getViewer_Query {
    viewer {
      id
      name
      email
    }
  }
`;

type MenuGroupID =
  | "Appliances"
  | "Clients"
  | "Users"
  | "Profile"
  | "Applications";

const menuGroupTranslations: Record<
  MenuGroupID,
  MessageDescriptor
> = defineMessages({
  Appliances: {
    id: "menu.group.Appliances",
    defaultMessage: "Appliances",
  },
  Clients: {
    id: "menu.group.Clients",
    defaultMessage: "Clients",
  },
  Users: {
    id: "menu.group.Users",
    defaultMessage: "Users",
  },
  Profile: {
    id: "menu.group.Profile",
    defaultMessage: "Profile",
  },
  Applications: {
    id: "menu.group.Applications",
    defaultMessage: "Applications",
  },
});

const menuItemIDs = [
  "ApplianceList",
  "RegisterAppliance",
  "ClaimAppliance",
  "ApplianceModelList",
  "AddApplianceModel",
  "ClientList",
  "AddClient",
  "UserList",
  "InviteUser",
  "RoleList",
  "AddRole",
  "Profile",
  "LegalTerms",
  "Logout",
] as const;

type MenuItemID = typeof menuItemIDs[number];

const isMenuItemID = (id: string): id is MenuItemID => {
  return menuItemIDs.includes(id as MenuItemID);
};

const menuItemTranslations: Record<
  MenuItemID,
  MessageDescriptor
> = defineMessages({
  ApplianceList: {
    id: "menu.item.ApplianceList",
    defaultMessage: "Appliance List",
  },
  RegisterAppliance: {
    id: "menu.item.RegisterAppliance",
    defaultMessage: "Register Appliance",
  },
  ClaimAppliance: {
    id: "menu.item.ClaimAppliance",
    defaultMessage: "Claim Appliance",
  },
  ApplianceModelList: {
    id: "menu.item.ApplianceModelList",
    defaultMessage: "Model List",
  },
  AddApplianceModel: {
    id: "menu.item.AddApplianceModel",
    defaultMessage: "Create Model",
  },
  ClientList: {
    id: "menu.item.ClientList",
    defaultMessage: "Client List",
  },
  AddClient: {
    id: "menu.item.AddClient",
    defaultMessage: "Add Client",
  },
  UserList: {
    id: "menu.item.UserList",
    defaultMessage: "User List",
  },
  InviteUser: {
    id: "menu.item.InviteUser",
    defaultMessage: "Invite Users",
  },
  RoleList: {
    id: "menu.item.RoleList",
    defaultMessage: "Role List",
  },
  AddRole: {
    id: "menu.item.AddRole",
    defaultMessage: "Create Role",
  },
  Profile: {
    id: "menu.item.Profile",
    defaultMessage: "Profile Details",
  },
  LegalTerms: {
    id: "menu.item.LegalTerms",
    defaultMessage: "Legal Terms",
  },
  Logout: {
    id: "menu.item.Logout",
    defaultMessage: "Logout",
  },
});

const routeToGroup = (route: Route): MenuGroupID | null => {
  switch (route) {
    case Route.appliances:
    case Route.appliancesEdit:
    case Route.appliancesNew:
    case Route.devicesClaim:
    case Route.applianceModels:
    case Route.applianceModelsEdit:
    case Route.applianceModelsNew:
      return "Appliances";

    case Route.clients:
    case Route.clientsEdit:
    case Route.clientsNew:
      return "Clients";

    case Route.users:
    case Route.usersEdit:
    case Route.usersInvite:
    case Route.roles:
    case Route.rolesEdit:
    case Route.rolesNew:
      return "Users";

    case Route.profile:
    case Route.confirmEmail:
    case Route.forgotPassword:
    case Route.resetPassword:
    case Route.legalTerms:
    case Route.logout:
      return "Profile";

    case Route.application:
      return "Applications";

    case Route.login:
    case Route.register:
    case Route.registerWithInvite:
      return null;
  }
};

const parametricRouteToItem = (
  parametricRoute: ParametricRoute
): MenuItemID | string | null => {
  const { route } = parametricRoute;
  switch (route) {
    case Route.appliances:
    case Route.appliancesEdit:
      return "ApplianceList";

    case Route.appliancesNew:
      return "RegisterAppliance";

    case Route.devicesClaim:
      return "ClaimAppliance";

    case Route.applianceModels:
    case Route.applianceModelsEdit:
      return "ApplianceModelList";

    case Route.applianceModelsNew:
      return "AddApplianceModel";

    case Route.clients:
    case Route.clientsEdit:
      return "ClientList";

    case Route.clientsNew:
      return "AddClient";

    case Route.users:
    case Route.usersEdit:
      return "UserList";

    case Route.usersInvite:
      return "InviteUser";

    case Route.roles:
    case Route.rolesEdit:
      return "RoleList";

    case Route.rolesNew:
      return "AddRole";

    case Route.profile:
    case Route.confirmEmail:
    case Route.forgotPassword:
    case Route.resetPassword:
      return "Profile";

    case Route.legalTerms:
      return "LegalTerms";

    case Route.logout:
      return "Logout";

    case Route.application:
      return buildApplicationItemId(parametricRoute.params.applicationSlug);

    case Route.login:
    case Route.register:
    case Route.registerWithInvite:
      return null;
  }
};
type MenuItem = {
  icon?: IconName;
  link: ParametricRoute;
  permissions?: Permission[];
} & (
  | {
      id: MenuItemID;
      translation?: never;
    }
  | {
      id: string;
      translation: string;
    }
);

type MenuGroup = {
  id: MenuGroupID;
  icon: IconName;
  items: MenuItem[];
};

type TopbarMenuGroupProps = {
  className?: string;
  isActive: boolean;
  group: MenuGroup;
};

const TopbarMenuGroup = ({
  className = "",
  isActive,
  group,
}: TopbarMenuGroupProps) => {
  const link = group.items[0].link;
  const buttonClassName = [
    "topbar-group",
    isActive ? "active fw-bold" : "text-muted",
    className,
  ].join(" ");

  return (
    <Button
      as={Link}
      {...link}
      variant={isActive ? "shadow-primary" : "text"}
      className={buttonClassName}
      icon={group.icon}
    >
      <FormattedMessage id={menuGroupTranslations[group.id].id} />
    </Button>
  );
};

type TopbarItemProps = {
  isActive: boolean;
  item: MenuItem;
};

const TopbarItem = ({ isActive, item }: TopbarItemProps) => {
  return (
    <Button
      as={Link}
      {...item.link}
      variant={isActive ? "shadow-primary" : "text"}
      className={isActive ? "fw-bold" : "text-muted"}
    >
      {isMenuItemID(item.id) ? (
        <FormattedMessage id={menuItemTranslations[item.id].id} />
      ) : (
        item.translation
      )}
    </Button>
  );
};

type UserProfileNavLinkProps = {
  getViewerQuery: PreloadedQuery<Topbar_getViewer_Query>;
};

const UserProfileNavLink = ({ getViewerQuery }: UserProfileNavLinkProps) => {
  const viewerData = usePreloadedQuery(GET_VIEWER_QUERY, getViewerQuery);
  const viewer = viewerData.viewer;

  const nameGroups = viewer?.name.split(" ");
  const initials =
    nameGroups && nameGroups.length > 1
      ? nameGroups[0][0] + nameGroups[nameGroups.length - 1][0]
      : viewer?.name[0];

  return (
    <Nav.Link as={Link} className="profile-link d-grid" route={Route.profile}>
      <div className="viewer-icon bg-secondary text-white text-center">
        {initials}
      </div>
      <span className="viewer-name h4">{viewer?.name}</span>
      <span className="viewer-email text-muted">{viewer?.email}</span>
    </Nav.Link>
  );
};

type CollapsibleNavGroupProps = {
  group: MenuGroup;
  activeMenuItemId: MenuItem["id"] | null;
};

const CollapsibleNavGroup = ({
  group,
  activeMenuItemId,
}: CollapsibleNavGroupProps) => {
  const { canOneOf } = useCan();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div>
      <Button
        variant="text-primary"
        icon={group.icon}
        className="w-100 mb-2"
        aria-expanded={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <FormattedMessage id={menuGroupTranslations[group.id].id} />
        <Icon icon={collapsed ? "caretDown" : "caretUp"} className="ms-auto" />
      </Button>
      <Collapse in={!collapsed}>
        <Stack gap={2} className="ps-4 mb-2">
          {group.items
            .filter((item) => canOneOf(item.permissions || []))
            .map((item) => (
              <CollapsibleNavItem
                key={item.id}
                item={item}
                isActive={item.id === activeMenuItemId}
              />
            ))}
        </Stack>
      </Collapse>
    </div>
  );
};

type CollapsibleNavItemProps = {
  isActive: boolean;
  item: MenuItem;
};

const CollapsibleNavItem = ({ isActive, item }: CollapsibleNavItemProps) => {
  return (
    <Button
      as={Link}
      {...item.link}
      variant={isActive ? "solid-primary" : "text"}
      className={isActive ? "" : "text-muted"}
      icon={item.icon}
    >
      <span className="me-auto">
        {isMenuItemID(item.id) ? (
          <FormattedMessage id={menuItemTranslations[item.id].id} />
        ) : (
          item.translation
        )}
      </span>
    </Button>
  );
};

type TopbarWithSideNavigationProps = {
  activeMenuItemId: MenuItem["id"] | null;
  brandImg: string;
  className?: string;
  menu: MenuGroup[];
};

const TopbarWithSideNavigation = ({
  activeMenuItemId,
  brandImg,
  className,
  menu,
}: TopbarWithSideNavigationProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [getViewerQuery, getViewer] = useQueryLoader<Topbar_getViewer_Query>(
    GET_VIEWER_QUERY
  );

  useEffect(() => {
    setShowMenu(false);
  }, [activeMenuItemId]);

  useEffect(() => {
    getViewer({});
  }, [getViewer]);

  return (
    <Card
      className={`topbar border-0 rounded-0 rounded-bottom bg-white shadow ${className}`}
    >
      <Navbar expand="md">
        <Container fluid className="position-relative">
          <Navbar.Toggle
            className="position-absolute"
            aria-controls="offcanvas-navigation-menu"
            onClick={() => setShowMenu(true)}
          />
          <Navbar.Brand href="#" className="mx-auto">
            <img className="topbar-brand" alt="Logo" src={brandImg} />
          </Navbar.Brand>
          <Offcanvas
            id="offcanvas-navigation-menu"
            placement="start"
            show={showMenu}
            onHide={() => setShowMenu(false)}
          >
            <Offcanvas.Body>
              <Nav as={Stack} gap={1} className="h-100">
                {getViewerQuery && (
                  <UserProfileNavLink getViewerQuery={getViewerQuery} />
                )}
                <hr />
                {menu.map((group) => (
                  <CollapsibleNavGroup
                    key={group.id}
                    group={group}
                    activeMenuItemId={activeMenuItemId}
                  />
                ))}
                <hr className="mt-auto" />
                <CollapsibleNavItem
                  item={menuItems.LegalTerms}
                  isActive={menuItems.LegalTerms.id === activeMenuItemId}
                />
                <CollapsibleNavItem
                  item={menuItems.Logout}
                  isActive={menuItems.Logout.id === activeMenuItemId}
                />
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    </Card>
  );
};

type RibbonTopbarProps = {
  activeMenuGroupId: MenuGroupID | null;
  activeMenuItemId: MenuItem["id"] | null;
  className?: string;
  menu: MenuGroup[];
};

const RibbonTopbar = ({
  activeMenuGroupId,
  activeMenuItemId,
  className,
  menu,
}: RibbonTopbarProps) => {
  const { canOneOf } = useCan();

  const profileGroup: MenuGroup = {
    id: "Profile",
    icon: "profile",
    items: [menuItems.Profile, menuItems.Logout],
  };

  const visibleMenuItemIds =
    menu
      .concat(profileGroup)
      .find((group) => group.id === activeMenuGroupId)
      ?.items?.filter((item) => canOneOf(item.permissions || [])) || [];

  return (
    <Card className={`topbar border-0 shadow ${className}`}>
      <Nav className="mx-3">
        {menu.map((group) => (
          <TopbarMenuGroup
            key={group.id}
            isActive={activeMenuGroupId === group.id}
            group={group}
          />
        ))}
        <TopbarMenuGroup
          className="ms-auto"
          isActive={activeMenuGroupId === "Profile"}
          group={profileGroup}
        />
      </Nav>
      <SegmentedControl
        items={visibleMenuItemIds}
        activeId={activeMenuItemId}
        className="bg-white shadow-sm"
        getItemId={(item) => item.id}
      >
        {(item, isActive) => <TopbarItem item={item} isActive={isActive} />}
      </SegmentedControl>
    </Card>
  );
};

const menuItems: Record<MenuItemID, MenuItem> = {
  ApplianceList: {
    id: "ApplianceList",
    link: { route: Route.appliances },
    permissions: ["CAN_LIST_APPLIANCES"],
  },
  RegisterAppliance: {
    id: "RegisterAppliance",
    link: { route: Route.appliancesNew },
    permissions: ["CAN_CREATE_APPLIANCES"],
  },
  ClaimAppliance: {
    id: "ClaimAppliance",
    link: { route: Route.devicesClaim },
    permissions: ["CAN_CREATE_APPLIANCES"],
  },
  ApplianceModelList: {
    id: "ApplianceModelList",
    link: { route: Route.applianceModels },
    permissions: ["CAN_LIST_APPLIANCE_MODELS"],
  },
  AddApplianceModel: {
    id: "AddApplianceModel",
    link: { route: Route.applianceModelsNew },
    permissions: ["CAN_CREATE_APPLIANCE_MODELS"],
  },
  ClientList: {
    id: "ClientList",
    link: { route: Route.clients },
    permissions: ["CAN_LIST_CLIENTS"],
  },
  AddClient: {
    id: "AddClient",
    link: { route: Route.clientsNew },
    permissions: ["CAN_ADD_CLIENTS"],
  },
  UserList: {
    id: "UserList",
    link: { route: Route.users },
    permissions: ["CAN_LIST_USERS"],
  },
  InviteUser: {
    id: "InviteUser",
    link: { route: Route.usersInvite },
    permissions: ["CAN_INVITE_USERS"],
  },
  RoleList: {
    id: "RoleList",
    link: { route: Route.roles },
    permissions: ["CAN_LIST_ROLES"],
  },
  AddRole: {
    id: "AddRole",
    link: { route: Route.rolesNew },
    permissions: ["CAN_CREATE_ROLES"],
  },
  Profile: {
    id: "Profile",
    link: { route: Route.profile },
  },
  LegalTerms: {
    id: "LegalTerms",
    icon: "documents",
    link: { route: Route.legalTerms },
  },
  Logout: {
    id: "Logout",
    icon: "logout",
    link: { route: Route.logout },
  },
};

const buildApplicationItemId = (slug: string) => `applications-${slug}`;

const Topbar = () => {
  const location = useLocation();
  const {
    deviceClaimInsteadOfApplianceRegistration,
    design,
  } = useTenantConfig();
  const { canOneOf } = useCan();
  const { applications } = useGlobalApps();
  const brandImg = design.logo || images.brand;

  const contentMenu: MenuGroup[] = [
    {
      id: "Appliances",
      icon: "appliances",
      items: [
        menuItems.ApplianceList,
        deviceClaimInsteadOfApplianceRegistration
          ? menuItems.ClaimAppliance
          : menuItems.RegisterAppliance,
        menuItems.ApplianceModelList,
        menuItems.AddApplianceModel,
      ],
    },
    {
      id: "Clients",
      icon: "clients",
      items: [menuItems.ClientList, menuItems.AddClient],
    },
    {
      id: "Users",
      icon: "users",
      items: [
        menuItems.UserList,
        menuItems.InviteUser,
        menuItems.RoleList,
        menuItems.AddRole,
      ],
    },
  ];
  const supportedApps = applications && applications.filter(isSupportedApp);
  if (supportedApps && supportedApps.length) {
    contentMenu.push({
      id: "Applications",
      icon: "applications",
      items: supportedApps.map(({ slug, displayName }) => ({
        id: buildApplicationItemId(slug),
        link: { route: Route.application, params: { applicationSlug: slug } },
        translation: displayName,
      })),
    });
  }

  const currentRoute = matchingRoute(location.pathname);
  const parametricRoute = matchingParametricRoute(location.pathname);
  const activeMenuGroupId = currentRoute ? routeToGroup(currentRoute) : null;
  const activeMenuItemId = parametricRoute
    ? parametricRouteToItem(parametricRoute)
    : null;

  const visibleMenuGroups = contentMenu
    .map((group) => ({
      ...group,
      permissions: group.items.flatMap((item) => item.permissions || []),
    }))
    .filter((group) => canOneOf(group.permissions));

  return (
    <>
      <TopbarWithSideNavigation
        activeMenuItemId={activeMenuItemId}
        brandImg={brandImg}
        className="d-md-none"
        menu={visibleMenuGroups}
      />
      <RibbonTopbar
        activeMenuGroupId={activeMenuGroupId}
        activeMenuItemId={activeMenuItemId}
        className="d-none d-md-block"
        menu={visibleMenuGroups}
      />
    </>
  );
};

export default Topbar;
