/**
 * @generated SignedSource<<b5d79303b494f48c0bee3c78766c486c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplianceModels_getApplianceModels_Query$variables = {};
export type ApplianceModels_getApplianceModels_Query$data = {
  readonly applianceModels: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ApplianceModelsTable_ApplianceModels">;
  }>;
};
export type ApplianceModels_getApplianceModels_Query = {
  response: ApplianceModels_getApplianceModels_Query$data;
  variables: ApplianceModels_getApplianceModels_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplianceModels_getApplianceModels_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplianceModel",
        "kind": "LinkedField",
        "name": "applianceModels",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ApplianceModelsTable_ApplianceModels"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ApplianceModels_getApplianceModels_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplianceModel",
        "kind": "LinkedField",
        "name": "applianceModels",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizedText",
            "kind": "LinkedField",
            "name": "names",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partNumbers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9cb5e7409c8c85c83db0e5360da6b66",
    "id": null,
    "metadata": {},
    "name": "ApplianceModels_getApplianceModels_Query",
    "operationKind": "query",
    "text": "query ApplianceModels_getApplianceModels_Query {\n  applianceModels {\n    ...ApplianceModelsTable_ApplianceModels\n    id\n  }\n}\n\nfragment ApplianceModelsTable_ApplianceModels on ApplianceModel {\n  id\n  handle\n  names {\n    text\n  }\n  partNumbers\n}\n"
  }
};

(node as any).hash = "880e4423ed39ec651f1aed793b51d496";

export default node;
