import React, { useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  useForm,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormRegister,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import _ from "lodash";

import Button from "components/Button";
import ConfirmModal from "components/ConfirmModal";
import Image from "components/Image";
import LocaleSelector, { Locale, LOCALES } from "components/LocaleSelector";
import SectionCard from "components/SectionCard";
import Stack from "components/Stack";
import { applianceModelHandleSchema, messages, yup } from "forms";

type TranslatedText = {
  locale: string;
  text: string;
};

type ApplianceModelData = {
  handle: string;
  names: TranslatedText[];
  descriptions: TranslatedText[];
  partNumbers: string[];
  pictureUrl: string | null;
};

type ApplianceModelChanges = {
  handle: string;
  names?: TranslatedText[];
  descriptions?: TranslatedText[];
  partNumbers: string[];
  pictureFile?: File;
  pictureUrl?: string | null;
};

type PartNumber = { value: string };

type LocalizedFieldSet = {
  locale: string;
  name: string;
  description: string;
};

type FormData = {
  handle: string;
  localizedFieldSets: LocalizedFieldSet[];
  partNumbers: PartNumber[];
  pictureFile?: FileList | null;
};

const applianceModelSchema = yup
  .object({
    handle: applianceModelHandleSchema.required(),
    localizedFieldSets: yup
      .array()
      .required()
      .of(
        yup
          .object({
            locale: yup.string().required(),
            name: yup.string().required(),
            description: yup.string().required(),
          })
          .required()
          .test("unique", messages.unique.id, (fieldSet, context) => {
            const itemIndex = context.parent.indexOf(fieldSet);
            return !context.parent.find(
              (f: LocalizedFieldSet, index: number) =>
                f.locale === fieldSet.locale && index < itemIndex
            );
          })
      ),
    partNumbers: yup
      .array()
      .required()
      .min(1)
      .of(
        yup
          .object({ value: yup.string().required() })
          .required()
          .test("unique", messages.unique.id, (partNumber, context) => {
            const itemIndex = context.parent.indexOf(partNumber);
            return !context.parent.find(
              (pn: PartNumber, index: number) =>
                pn.value === partNumber.value && index < itemIndex
            );
          })
      ),
  })
  .required();

// TODO: compute the default locale from the user or tenant
const defaultLocale = "en-US";

const defaultFormData: FormData = {
  handle: "",
  localizedFieldSets: [],
  partNumbers: [{ value: "" }],
};

const transformInputData = (data: ApplianceModelData): FormData => {
  const locales = _.union(
    data.names.map((name) => name.locale),
    data.descriptions.map((description) => description.locale)
  );
  const localizedFieldSets = locales.map((locale) => ({
    locale,
    name: data.names.find((name) => name.locale === locale)?.text || "",
    description:
      data.descriptions.find((description) => description.locale === locale)
        ?.text || "",
  }));
  const partNumbers =
    data.partNumbers.length > 0
      ? data.partNumbers.map((pn) => ({ value: pn }))
      : [{ value: "" }];

  return {
    handle: data.handle,
    localizedFieldSets,
    partNumbers,
  };
};

const transformOutputData = (data: FormData): ApplianceModelChanges => {
  let applianceModel: ApplianceModelChanges = {
    handle: data.handle,
    partNumbers: data.partNumbers.map((pn) => pn.value),
  };

  applianceModel.names = data.localizedFieldSets
    .filter((fieldSet) => fieldSet.name !== "")
    .map((fieldSet) => ({
      locale: fieldSet.locale,
      text: fieldSet.name,
    }));

  applianceModel.descriptions = data.localizedFieldSets
    .filter((fieldSet) => fieldSet.description !== "")
    .map((fieldSet) => ({
      locale: fieldSet.locale,
      text: fieldSet.description,
    }));

  if (data.pictureFile) {
    applianceModel.pictureFile = data.pictureFile[0];
  } else if (data.pictureFile === null) {
    applianceModel.pictureUrl = null;
  }

  return applianceModel;
};

type AddLocaleModalProps = {
  locales: Locale[];
  onCancel: () => void;
  onConfirm: (locale: string) => void;
};

const AddLocaleModal = ({
  locales,
  onCancel,
  onConfirm,
}: AddLocaleModalProps) => {
  const [selectedLocale, setSelectedLocale] = useState(locales[0]);

  return (
    <ConfirmModal
      title={
        <FormattedMessage
          id="forms.ApplianceModel.addLocaleModal.title"
          defaultMessage="Add Supported Language"
        />
      }
      confirmLabel={
        <FormattedMessage
          id="forms.ApplianceModel.addLocaleModal.confirmButton"
          defaultMessage="Add Supported Language"
        />
      }
      onCancel={onCancel}
      onConfirm={() => selectedLocale && onConfirm(selectedLocale)}
      disabled={selectedLocale == null}
    >
      <Form.Group>
        <Form.Label>
          <FormattedMessage
            id="forms.ApplianceModel.localeLabel"
            defaultMessage="Language"
          />
        </Form.Label>
        <LocaleSelector
          value={selectedLocale}
          locales={locales}
          onChange={setSelectedLocale}
        />
      </Form.Group>
    </ConfirmModal>
  );
};

type NamingSectionMobileProps = {
  className?: string;
  handleAddLocale: (locale: string) => void;
  handleDeleteLocale: (index: number) => void;
  handleChangeFieldSet: (
    index: number,
    fieldSet: Partial<LocalizedFieldSet>
  ) => void;
  errors: FieldErrors<FormData>;
  localizedFieldSets: UseFieldArrayReturn<FormData, "localizedFieldSets", "id">;
  readOnly: boolean;
};

const NamingSectionMobile = ({
  className,
  handleAddLocale,
  handleDeleteLocale,
  handleChangeFieldSet,
  errors,
  localizedFieldSets,
  readOnly,
}: NamingSectionMobileProps) => {
  const [showAddLocaleModal, setShowAddLocaleModal] = useState(false);
  const [currentFieldSetIndex, setCurrentFieldSetIndex] = useState(0);
  const currentFieldSet = localizedFieldSets.fields[currentFieldSetIndex];
  const locales = localizedFieldSets.fields.map((fieldSet) => fieldSet.locale);

  useEffect(() => {
    setCurrentFieldSetIndex(Math.max(0, localizedFieldSets.fields.length - 1));
  }, [localizedFieldSets.fields.length]);

  return (
    <>
      <SectionCard
        className={className}
        title={
          <FormattedMessage
            id="forms.ApplianceModel.namingSection.title"
            defaultMessage="Name & Description"
          />
        }
      >
        {currentFieldSet ? (
          <Row xs={1} xl={3} className="g-3">
            <Col>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage
                    id="forms.ApplianceModel.localeLabel"
                    defaultMessage="Language"
                  />
                </Form.Label>
                <LocaleSelector
                  value={currentFieldSet.locale as Locale}
                  locales={locales as Locale[]}
                  onChange={(locale) => {
                    const index = localizedFieldSets.fields.findIndex(
                      (fieldSet) => fieldSet.locale === locale
                    );
                    if (index !== -1) {
                      setCurrentFieldSetIndex(index);
                    }
                  }}
                  required
                  isInvalid={
                    !!(errors.localizedFieldSets?.[currentFieldSetIndex] as any)
                      ?.message
                  }
                />
                <Form.Control
                  className="d-none"
                  isInvalid={
                    !!(errors.localizedFieldSets?.[currentFieldSetIndex] as any)
                      ?.message
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {(errors.localizedFieldSets?.[currentFieldSetIndex] as any)
                    ?.message && (
                    <FormattedMessage
                      id={
                        (errors.localizedFieldSets?.[
                          currentFieldSetIndex
                        ] as any)?.message
                      }
                    />
                  )}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="appliance-model-form-name">
                <Form.Label>
                  <FormattedMessage
                    id="forms.ApplianceModel.nameLabel"
                    defaultMessage="Model Name"
                  />
                </Form.Label>
                <Form.Control
                  value={currentFieldSet.name}
                  onChange={(event) => {
                    handleChangeFieldSet(currentFieldSetIndex, {
                      name: event.target.value,
                    });
                  }}
                  isInvalid={
                    !!errors.localizedFieldSets?.[currentFieldSetIndex]?.name
                  }
                  readOnly={readOnly}
                  plaintext={readOnly}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.localizedFieldSets?.[currentFieldSetIndex]?.name
                    ?.message && (
                    <FormattedMessage
                      id={
                        errors.localizedFieldSets?.[currentFieldSetIndex]?.name
                          ?.message
                      }
                    />
                  )}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="appliance-model-form-description">
                <Form.Label>
                  <FormattedMessage
                    id="forms.ApplianceModel.descriptionLabel"
                    defaultMessage="Description"
                  />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={currentFieldSet.description}
                  onChange={(event) => {
                    handleChangeFieldSet(currentFieldSetIndex, {
                      description: event.target.value,
                    });
                  }}
                  isInvalid={
                    !!errors.localizedFieldSets?.[currentFieldSetIndex]
                      ?.description
                  }
                  readOnly={readOnly}
                  plaintext={readOnly}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.localizedFieldSets?.[currentFieldSetIndex]
                    ?.description?.message && (
                    <FormattedMessage
                      id={
                        errors.localizedFieldSets?.[currentFieldSetIndex]
                          ?.description?.message
                      }
                    />
                  )}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {!readOnly && (
              <Col>
                <div className="mt-3 d-flex justify-content-end flex-column flex-md-row">
                  <Button
                    variant="outline-danger"
                    onClick={() => handleDeleteLocale(currentFieldSetIndex)}
                  >
                    <FormattedMessage
                      id="forms.ApplianceModel.namingSection.deleteLocaleButton"
                      defaultMessage="Delete Language"
                    />
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <Row xs={1} xl={3} className="g-3">
            <Col>
              <p>
                <FormattedMessage
                  id="forms.ApplianceModel.noLocalePrompt"
                  defaultMessage="There are no supported languages."
                />
              </p>
            </Col>
          </Row>
        )}
        {!readOnly && (
          <div className="mt-3 d-flex justify-content-start flex-column flex-md-row">
            <Button
              variant="outline-primary"
              onClick={() => setShowAddLocaleModal(true)}
            >
              <FormattedMessage
                id="forms.ApplianceModel.namingSection.addLocaleButton"
                defaultMessage="Add Supported Language"
              />
            </Button>
          </div>
        )}
      </SectionCard>
      {showAddLocaleModal && (
        <AddLocaleModal
          locales={_.difference(LOCALES, locales as Locale[])}
          onCancel={() => setShowAddLocaleModal(false)}
          onConfirm={(locale) => {
            handleAddLocale(locale);
            setCurrentFieldSetIndex(localizedFieldSets.fields.length);
            setShowAddLocaleModal(false);
          }}
        />
      )}
    </>
  );
};

type NamingSectionDesktoprops = {
  className?: string;
  handleAddLocale: (locale: string) => void;
  handleChangeLocale: (index: number, locale: string) => void;
  handleDeleteLocale: (index: number) => void;
  errors: FieldErrors<FormData>;
  localizedFieldSets: UseFieldArrayReturn<FormData, "localizedFieldSets", "id">;
  readOnly: boolean;
  register: UseFormRegister<FormData>;
};

const NamingSectionDesktop = ({
  className,
  handleAddLocale,
  handleChangeLocale,
  handleDeleteLocale,
  errors,
  localizedFieldSets,
  readOnly,
  register,
}: NamingSectionDesktoprops) => {
  return (
    <SectionCard
      className={className}
      title={
        <FormattedMessage
          id="forms.ApplianceModel.namingSection.title"
          defaultMessage="Name & Description"
        />
      }
    >
      <Table responsive hover>
        <thead className="border-top">
          <tr>
            <th>
              <FormattedMessage
                id="forms.ApplianceModel.localeLabel"
                defaultMessage="Language"
              />
            </th>
            <th>
              <FormattedMessage
                id="forms.ApplianceModel.nameLabel"
                defaultMessage="Model Name"
              />
            </th>
            <th>
              <FormattedMessage
                id="forms.ApplianceModel.descriptionLabel"
                defaultMessage="Description"
              />
            </th>
            {!readOnly && <th></th>}
          </tr>
        </thead>
        <tbody className="border-top-0">
          {localizedFieldSets.fields.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <FormattedMessage
                  id="forms.ApplianceModel.noLocalePrompt"
                  defaultMessage="There are no supported languages."
                />
              </td>
            </tr>
          ) : (
            localizedFieldSets.fields.map((fieldSet, index) => (
              <tr key={index}>
                <td>
                  <Form.Group>
                    <LocaleSelector
                      value={fieldSet.locale as Locale}
                      onChange={(locale) => handleChangeLocale(index, locale)}
                      disabled={readOnly}
                      required
                      isInvalid={
                        !!(errors.localizedFieldSets?.[index] as any)?.message
                      }
                    />
                    <Form.Control
                      className="d-none"
                      isInvalid={
                        !!(errors.localizedFieldSets?.[index] as any)?.message
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {(errors.localizedFieldSets?.[index] as any)?.message && (
                        <FormattedMessage
                          id={
                            (errors.localizedFieldSets?.[index] as any)?.message
                          }
                        />
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="appliance-model-form-name">
                    <Form.Control
                      {...register(`localizedFieldSets.${index}.name`)}
                      isInvalid={!!errors.localizedFieldSets?.[index]?.name}
                      readOnly={readOnly}
                      plaintext={readOnly}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.localizedFieldSets?.[index]?.name?.message && (
                        <FormattedMessage
                          id={errors.localizedFieldSets?.[index]?.name?.message}
                        />
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="appliance-model-form-description">
                    <Form.Control
                      as="textarea"
                      {...register(`localizedFieldSets.${index}.description`)}
                      isInvalid={
                        !!errors.localizedFieldSets?.[index]?.description
                      }
                      readOnly={readOnly}
                      plaintext={readOnly}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.localizedFieldSets?.[index]?.description
                        ?.message && (
                        <FormattedMessage
                          id={
                            errors.localizedFieldSets?.[index]?.description
                              ?.message
                          }
                        />
                      )}
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
                {!readOnly && (
                  <td>
                    <Button
                      variant="shadow-danger"
                      icon="close"
                      onClick={() => handleDeleteLocale(index)}
                    />
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {!readOnly && (
        <div className="mt-3 d-flex justify-content-end">
          <Button
            variant="outline-primary"
            onClick={() => handleAddLocale(defaultLocale)}
          >
            <FormattedMessage
              id="forms.ApplianceModel.namingSection.addLocaleButton"
              defaultMessage="Add Supported Language"
            />
          </Button>
        </div>
      )}
    </SectionCard>
  );
};

type Props = {
  initialData?: ApplianceModelData;
  isLoading?: boolean;
  onSubmit: (data: ApplianceModelChanges) => void;
  readOnly?: boolean;
  submitLabel: JSX.Element;
};

const ApplianceModelForm = ({
  initialData,
  isLoading = false,
  onSubmit,
  readOnly = false,
  submitLabel,
}: Props) => {
  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: initialData
      ? transformInputData(initialData)
      : defaultFormData,
    resolver: yupResolver(applianceModelSchema),
  });

  const localizedFieldSets = useFieldArray({
    control,
    name: "localizedFieldSets",
  });

  const partNumbers = useFieldArray({
    control,
    name: "partNumbers",
  });

  const onFormSubmit = (data: FormData) => onSubmit(transformOutputData(data));

  const handleAddLocale = useCallback(
    (locale: string) => {
      const localizedFieldSet: LocalizedFieldSet = {
        locale,
        name: "",
        description: "",
      };
      localizedFieldSets.append(localizedFieldSet);
    },
    [localizedFieldSets]
  );

  const handleChangeLocale = useCallback(
    (index: number, locale: string) => {
      const updatedFieldSet = {
        ...localizedFieldSets.fields[index],
        locale,
      };
      localizedFieldSets.update(index, updatedFieldSet);
    },
    [localizedFieldSets]
  );

  const handleChangeFieldSet = useCallback(
    (index: number, fieldSet: Partial<LocalizedFieldSet>) => {
      const updatedFieldSet = {
        ...localizedFieldSets.fields[index],
        ...fieldSet,
      };
      localizedFieldSets.update(index, updatedFieldSet);
    },
    [localizedFieldSets]
  );

  const handleDeleteLocale = useCallback(
    (index: number) => {
      localizedFieldSets.remove(index);
    },
    [localizedFieldSets]
  );

  const handleAddPartNumber = useCallback(() => {
    partNumbers.append({ value: "" });
  }, [partNumbers]);

  const handleDeletePartNumber = useCallback(
    (index: number) => {
      if (partNumbers.fields.length > 1) {
        partNumbers.remove(index);
      } else {
        partNumbers.update(index, { value: "" });
      }
    },
    [partNumbers]
  );

  const pictureFile = watch("pictureFile");
  const picture =
    pictureFile instanceof FileList && pictureFile.length > 0
      ? URL.createObjectURL(pictureFile[0]) // picture is the new file
      : pictureFile === null
      ? null // picture is removed
      : initialData?.pictureUrl; // picture is unchanged
  const isPictureChanged = picture !== initialData?.pictureUrl;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack gap={4}>
        <Row xs={1} className="g-4">
          <Col>
            <NamingSectionMobile
              className="d-md-none"
              handleAddLocale={handleAddLocale}
              handleDeleteLocale={handleDeleteLocale}
              handleChangeFieldSet={handleChangeFieldSet}
              errors={errors}
              localizedFieldSets={localizedFieldSets}
              readOnly={readOnly}
            />
            <NamingSectionDesktop
              className="d-none d-md-block"
              handleAddLocale={handleAddLocale}
              handleChangeLocale={handleChangeLocale}
              handleDeleteLocale={handleDeleteLocale}
              errors={errors}
              localizedFieldSets={localizedFieldSets}
              readOnly={readOnly}
              register={register}
            />
          </Col>
        </Row>
        <Row xs={1} lg={2} className="g-4">
          <Col>
            <SectionCard
              title={
                <FormattedMessage
                  id="forms.ApplianceModel.pictureSection.title"
                  defaultMessage="Picture"
                />
              }
            >
              <Stack gap={3}>
                <Form.Group controlId="appliance-model-form-picture">
                  <Form.Label>
                    <FormattedMessage
                      id="forms.ApplianceModel.pictureLabel"
                      defaultMessage="Model Image"
                    />
                  </Form.Label>
                  {!readOnly && (
                    <div className="d-flex gap-3 flex-column flex-md-row">
                      <Form.Control
                        type="file"
                        accept=".jpg,.jpeg,.gif,.png,.svg"
                        {...register("pictureFile")}
                      />
                      {picture && (
                        <Button
                          variant="outline-danger"
                          onClick={() => setValue("pictureFile", null)}
                        >
                          <FormattedMessage
                            id="forms.ApplianceModel.removePictureButton"
                            defaultMessage="Remove"
                          />
                        </Button>
                      )}
                    </div>
                  )}
                </Form.Group>
                <Image
                  alt="Appliance Model"
                  src={picture || undefined}
                  style={{
                    maxHeight: "20em",
                    objectFit: picture ? "contain" : "cover",
                  }}
                />
              </Stack>
            </SectionCard>
          </Col>
          <Col>
            <SectionCard
              title={
                <FormattedMessage
                  id="forms.ApplianceModel.identifiersSection.title"
                  defaultMessage="Identifiers"
                />
              }
            >
              <Form.Group controlId="appliance-model-form-handle">
                <Form.Label>
                  <FormattedMessage
                    id="forms.ApplianceModel.handleLabel"
                    defaultMessage="Handle"
                  />
                </Form.Label>
                <Form.Control
                  {...register("handle")}
                  isInvalid={!!errors.handle}
                  readOnly={readOnly}
                  plaintext={readOnly}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.handle?.message && (
                    <FormattedMessage id={errors.handle?.message} />
                  )}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="appliance-model-form-part-numbers">
                <Form.Label>
                  <FormattedMessage
                    id="forms.ApplianceModel.partNumbersLabel"
                    defaultMessage="Part Numbers"
                  />
                </Form.Label>
                <Stack gap={3}>
                  {partNumbers.fields.map((partNumber, index) => (
                    <Stack direction="horizontal" gap={3} key={partNumber.id}>
                      <Stack>
                        <Form.Control
                          {...register(`partNumbers.${index}.value`)}
                          isInvalid={!!errors.partNumbers?.[index]}
                          readOnly={readOnly}
                          plaintext={readOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.partNumbers?.[index]?.value?.message && (
                            <FormattedMessage
                              id={errors.partNumbers?.[index]?.value?.message}
                            />
                          )}
                        </Form.Control.Feedback>
                      </Stack>
                      {!readOnly && (
                        <Button
                          variant="shadow-danger"
                          className="mb-auto"
                          onClick={() => handleDeletePartNumber(index)}
                          icon="close"
                        />
                      )}
                    </Stack>
                  ))}
                  {!readOnly && (
                    <div className="d-flex flex-column flex-md-row">
                      <Button
                        variant="outline-primary"
                        onClick={handleAddPartNumber}
                      >
                        <FormattedMessage
                          id="forms.ApplianceModel.addPartNumberButton"
                          defaultMessage="Add Part Number"
                        />
                      </Button>
                    </div>
                  )}
                </Stack>
              </Form.Group>
            </SectionCard>
          </Col>
        </Row>
        {!readOnly && (
          <div className="d-flex gap-3 justify-content-end flex-column flex-md-row">
            <Button
              className="order-md-last"
              type="submit"
              disabled={!isDirty || isLoading}
              loading={isLoading}
            >
              {submitLabel}
            </Button>
            <Button
              variant="outline-primary"
              disabled={!isDirty && !isPictureChanged}
              onClick={() => reset()}
            >
              <FormattedMessage
                id="forms.ApplianceModel.resetButton"
                defaultMessage="Reset"
              />
            </Button>
          </div>
        )}
      </Stack>
    </form>
  );
};

export type { ApplianceModelChanges, ApplianceModelData };

export default ApplianceModelForm;
