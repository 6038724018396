import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import { useLocation } from "react-router";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import type { ResetPassword_ResetPassword_Mutation } from "api/__generated__/ResetPassword_ResetPassword_Mutation.graphql";
import { Link, Route } from "Navigation";
import { messages, yup } from "forms";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import FormFeedback from "forms/FormFeedback";
import Stack from "components/Stack";

const RESET_PASSWORD_MUTATION = graphql`
  mutation ResetPassword_ResetPassword_Mutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      email
    }
  }
`;

interface FormData {
  newPassword: string;
  newPasswordConfirm: string;
}

const fromDataSchema = yup.object({
  newPassword: yup.string().required().min(3),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], messages.passwordConfirm.id),
});

const ResetPassword = () => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [successFeedback, setSuccessFeedback] = useState<React.ReactNode>(null);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
    resolver: yupResolver(fromDataSchema),
  });
  const [
    resetPassword,
    isResettingPassword,
  ] = useMutation<ResetPassword_ResetPassword_Mutation>(
    RESET_PASSWORD_MUTATION
  );
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const email = urlSearchParams.get("email") || "";
  const token = urlSearchParams.get("token") || "";

  const handleFormSubmit = handleSubmit((formData: FormData) => {
    const input = { email, token, newPassword: formData.newPassword };
    resetPassword({
      variables: { input },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
        } else {
          reset();
          setSuccessFeedback(
            <FormattedMessage
              id="pages.ResetPassword.successfulPasswordResetFeedback"
              defaultMessage="Your password has been successfully reset!"
              description="Feedback for a successful password reset"
            />
          );
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.ResetPassword.loadingErrorFeedback"
            defaultMessage="Could not request a password reset, please try again."
            description="Feedback for unknown loading error in the reset password page"
          />
        );
      },
    });
  });

  return (
    <AuthPage>
      <Alert
        show={!!successFeedback}
        variant="success"
        onClose={() => setSuccessFeedback(null)}
        dismissible
      >
        {successFeedback}
      </Alert>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Form onSubmit={handleFormSubmit}>
        <Stack gap={3}>
          <Form.Group controlId="new-password">
            <Form.Label>
              <FormattedMessage
                id="pages.ResetPassword.newPasswordLabel"
                defaultMessage="New password"
              />
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={!!errors.newPassword}
              {...register("newPassword")}
            />
            <FormFeedback feedback={errors.newPassword?.message} />
          </Form.Group>
          <Form.Group controlId="new-password-confirm">
            <Form.Label>
              <FormattedMessage
                id="pages.ResetPassword.newPasswordConfirmLabel"
                defaultMessage="Confirm new password"
              />
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={!!errors.newPasswordConfirm}
              {...register("newPasswordConfirm")}
            />
            <FormFeedback feedback={errors.newPasswordConfirm?.message} />
          </Form.Group>
          <Button
            type="submit"
            className="w-100 mt-3"
            disabled={isResettingPassword}
            loading={isResettingPassword}
          >
            <FormattedMessage
              id="pages.ResetPassword.ResetPasswordButton"
              defaultMessage="Change Password"
              description="Title for the button to request a password reset"
            />
          </Button>
        </Stack>
        <div className="mt-5 text-center">
          <FormattedMessage
            id="pages.ResetPassword.loginLink"
            defaultMessage="Back to <loginLink>login</loginLink>"
            description="Title for the link to the Login Page"
            values={{
              loginLink: (chunks: React.ReactNode) => (
                <Link route={Route.login}>{chunks}</Link>
              ),
            }}
          />
        </div>
      </Form>
    </AuthPage>
  );
};

export default ResetPassword;
