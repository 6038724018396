import { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import { FormattedMessage } from "react-intl";
import type { ApplianceOperations_applianceId$key } from "api/__generated__/ApplianceOperations_applianceId.graphql";
import type { ApplianceOperations_deleteAppliance_Mutation } from "api/__generated__/ApplianceOperations_deleteAppliance_Mutation.graphql";
import Alert from "react-bootstrap/Alert";

import { Route, useNavigate } from "Navigation";
import Button from "components/Button";
import Can from "components/Can";
import ConfirmModal from "components/ConfirmModal";
import SectionCard from "components/SectionCard";

const APPLIANCE_ID_FRAGMENT = graphql`
  fragment ApplianceOperations_applianceId on Appliance {
    id
  }
`;

const DELETE_APPLIANCE_MUTATION = graphql`
  mutation ApplianceOperations_deleteAppliance_Mutation(
    $input: DeleteApplianceInput!
  ) {
    deleteAppliance(input: $input) {
      appliance {
        id
      }
    }
  }
`;

type ApplianceOperationsProps = {
  applianceRef: ApplianceOperations_applianceId$key;
};

const ApplianceOperations = ({ applianceRef }: ApplianceOperationsProps) => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [showDeleteApplianceModal, setShowDeleteApplianceModal] = useState(
    false
  );
  const [
    deleteAppliance,
    isDeletingAppliance,
  ] = useMutation<ApplianceOperations_deleteAppliance_Mutation>(
    DELETE_APPLIANCE_MUTATION
  );
  const applianceIdData = useFragment(APPLIANCE_ID_FRAGMENT, applianceRef);
  const navigate = useNavigate();

  const handleDeleteAppliance = () => {
    deleteAppliance({
      variables: { input: { applianceId: applianceIdData.id } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
          setShowDeleteApplianceModal(false);
        } else {
          navigate({ route: Route.appliances });
        }
      },
      updater(store) {
        const applianceId = store
          .getRootField("deleteAppliance")
          .getLinkedRecord("appliance")
          .getDataID();
        const root = store.getRoot();
        const appliances = root.getLinkedRecords("appliances");
        if (appliances) {
          root.setLinkedRecords(
            appliances.filter(
              (appliance) => appliance.getDataID() !== applianceId
            ),
            "appliances"
          );
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Appliance.deleteApplianceErrorFeedback"
            defaultMessage="Could not delete the appliance, please try again in a few minutes."
          />
        );
        setShowDeleteApplianceModal(false);
      },
    });
  };

  return (
    <Can oneOf={["CAN_DELETE_APPLIANCES"]}>
      <SectionCard
        title={
          <FormattedMessage
            id="pages.Appliance.applianceOperations"
            defaultMessage="Appliance Operations"
          />
        }
      >
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <div className="d-flex flex-column flex-md-row">
          <Button
            variant="solid-danger"
            onClick={() => setShowDeleteApplianceModal(true)}
          >
            <FormattedMessage
              id="pages.Appliance.deleteApplianceButton"
              defaultMessage="Delete Appliance"
            />
          </Button>
        </div>
        {showDeleteApplianceModal && (
          <ConfirmModal
            title={
              <FormattedMessage
                id="pages.Appliance.deleteApplianceModal.title"
                defaultMessage="Appliance Deletion"
              />
            }
            confirmLabel={
              <FormattedMessage
                id="pages.Appliance.deleteAppliance.confirmButton"
                defaultMessage="Delete Appliance"
              />
            }
            confirmVariant="solid-danger"
            onCancel={() => setShowDeleteApplianceModal(false)}
            onConfirm={handleDeleteAppliance}
            isConfirming={isDeletingAppliance}
          >
            <FormattedMessage
              id="pages.Appliance.deleteApplianceModal.deleteNotice"
              defaultMessage="Deleting an appliance will delete it together with its assignation history for all users and organizations.{br}A new appliance with the same serial number and device ID may be later registered.{br}This operation cannot be undone, are you sure you want to delete this appliance?"
              values={{
                br: <br />,
              }}
            />
          </ConfirmModal>
        )}
      </SectionCard>
    </Can>
  );
};

export default ApplianceOperations;
