import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import type { Login_login_Mutation } from "api/__generated__/Login_login_Mutation.graphql";
import { useSession } from "contexts/Session";
import { Link, Route, useNavigate } from "Navigation";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import InputPassword from "components/InputPassword";
import Stack from "components/Stack";

const LOGIN_MUTATION = graphql`
  mutation Login_login_Mutation($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

interface FormData {
  email: string;
  password: string;
  keepMeLoggedIn: boolean;
}

const initialFormData: FormData = {
  email: "",
  password: "",
  keepMeLoggedIn: false,
};

const Login = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const { redirectTo, setAuthToken, setRedirectTo } = useSession();
  const [login, isLoggingIn] = useMutation<Login_login_Mutation>(
    LOGIN_MUTATION
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      login({
        variables: {
          input: { email: formData.email, password: formData.password },
        },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
          setAuthToken(data.login?.token || null, formData.keepMeLoggedIn);
          if (redirectTo) {
            setRedirectTo(null);
            navigate(redirectTo);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Login.form.loadingErrorFeedback"
              defaultMessage="Could not login, please try again."
              description="Feedback for unknown loading error in the Login page"
            />
          );
        },
      });
    },
    [formData, login, setAuthToken, redirectTo, setRedirectTo, navigate]
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      setFormData((data) => ({ ...data, [field]: value }));
    },
    []
  );

  return (
    <AuthPage>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Stack gap={3}>
          <p className="text-center">
            <FormattedMessage
              id="pages.Login.form.headingText"
              defaultMessage="Enter your email and password to login"
            />
          </p>
          <Form.Group controlId="email">
            <Form.Label>
              <FormattedMessage
                id="pages.Login.form.emailLabel"
                defaultMessage="Email"
              />
            </Form.Label>
            <Form.Control
              value={formData.email}
              onChange={handleInputChange}
              required
              type="email"
              placeholder={intl.formatMessage({
                id: "pages.Login.form.emailPlaceholder",
                defaultMessage: "Type in the email",
                description:
                  "Placeholder for the email field in the Login page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Login.form.missingEmailFeedback"
                defaultMessage="Please provide a valid email."
                description="Feedback for empty email field in the Login page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>
              <FormattedMessage
                id="pages.Login.form.passwordLabel"
                defaultMessage="Password"
              />
            </Form.Label>
            <InputPassword
              value={formData.password}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.Login.form.passwordPlaceholder",
                defaultMessage: "Type in the password",
                description:
                  "Placeholder for the password field in the Login page",
              })}
            >
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Login.form.missingPasswordFeedback"
                  defaultMessage="Please provide a password."
                  description="Feedback for empty password field in the Login page"
                />
              </Form.Control.Feedback>
            </InputPassword>
          </Form.Group>
          <Form.Group controlId="keepMeLoggedIn">
            <Form.Check
              checked={formData.keepMeLoggedIn}
              onChange={handleInputChange}
              type="checkbox"
              label={
                <FormattedMessage
                  id="pages.Login.keepMeLoggedInLabel"
                  defaultMessage="Keep me logged in"
                />
              }
            />
          </Form.Group>
          <Button
            type="submit"
            className="w-100 mt-3"
            disabled={isLoggingIn}
            loading={isLoggingIn}
          >
            <FormattedMessage
              id="pages.Login.form.passwordLoginButton"
              defaultMessage="Login"
              description="Title for the button to login with password in the Login Page"
            />
          </Button>
          <div className="text-center">
            <Link route={Route.forgotPassword}>
              <FormattedMessage
                id="pages.Login.form.forgotPasswordLink"
                defaultMessage="Forgot your password?"
                description="Title for the link to the forgot password page"
              />
            </Link>
          </div>
          <div className="text-center">
            <FormattedMessage
              id="pages.Login.form.registerLink"
              defaultMessage="No account yet? <registerLink>Register</registerLink>"
              description="Title for the link to Register in the Login Page"
              values={{
                registerLink: (chunks: React.ReactNode) => (
                  <Link route={Route.register}>{chunks}</Link>
                ),
              }}
            />
          </div>
        </Stack>
      </Form>
    </AuthPage>
  );
};

export default Login;
