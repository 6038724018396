import { FormattedMessage } from "react-intl";

import type { AcceptedCookies } from "contexts/CookiePreferences";
import Button from "components/Button";

import "./CookiePreferences.scss";

type CookiePreferencesProps = {
  onSelect: (acceptedCookies: AcceptedCookies) => void;
  onShowCookiePolicy: () => void;
};

const CookiePreferences = ({
  onSelect,
  onShowCookiePolicy,
}: CookiePreferencesProps) => {
  const handleCookieLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onShowCookiePolicy();
  };
  return (
    <aside className="cookie-preferences bg-dark text-light position-fixed">
      <div className="p-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
        <span className="me-auto">
          <FormattedMessage
            id="cookiePreferences.description"
            defaultMessage="This site uses cookies to offer you a better browsing experience. Find out more on how we use <a>cookies</a>."
            values={{
              a: (chunks: React.ReactNode) => (
                <a
                  href="/cookiePolicy"
                  onClick={handleCookieLinkClick}
                  className="text-light"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </span>
        <Button
          onClick={() => onSelect("All")}
          className="flex-shrink-0 order-md-last"
        >
          <FormattedMessage
            id="cookiePreferences.acceptAll"
            defaultMessage="Accept all cookies"
          />
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => onSelect("StrictlyNecessary")}
          className="flex-shrink-0"
        >
          <FormattedMessage
            id="cookiePreferences.acceptStrictlyNecessary"
            defaultMessage="Accept only essential cookies"
          />
        </Button>
      </div>
    </aside>
  );
};

export default CookiePreferences;
