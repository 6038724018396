/**
 * @generated SignedSource<<294e2a285df936c7cda6affd8313bd1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Profile_latestTermsAndConditions_Query$variables = {};
export type Profile_latestTermsAndConditions_Query$data = {
  readonly latestTermsAndConditions: {
    readonly id: string;
    readonly text: string;
  };
};
export type Profile_latestTermsAndConditions_Query = {
  response: Profile_latestTermsAndConditions_Query$data;
  variables: Profile_latestTermsAndConditions_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TenantTermsAndConditions",
    "kind": "LinkedField",
    "name": "latestTermsAndConditions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_latestTermsAndConditions_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Profile_latestTermsAndConditions_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4afb171c9f26188dc6a6e869319a5718",
    "id": null,
    "metadata": {},
    "name": "Profile_latestTermsAndConditions_Query",
    "operationKind": "query",
    "text": "query Profile_latestTermsAndConditions_Query {\n  latestTermsAndConditions {\n    id\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2a43951fbfdc29a5bf4f28f11c4aedc";

export default node;
