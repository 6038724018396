import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as RouterProvider } from "react-router-dom";

import I18nProvider from "i18n";
import { SidebarProvider } from "components/Sidebar";
import CookiePreferencesProvider from "contexts/CookiePreferences";
import RelayProvider from "contexts/Relay";
import ViewerProvider from "contexts/Viewer";
import SessionProvider from "contexts/Session";
import TenantConfigProvider from "contexts/TenantConfig";
import GlobalAppsProvider from "contexts/GlobalApps";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <SessionProvider>
      <RelayProvider>
        <ViewerProvider>
          <TenantConfigProvider>
            <CookiePreferencesProvider>
              <GlobalAppsProvider>
                <RouterProvider>
                  <I18nProvider>
                    <SidebarProvider>
                      <App />
                    </SidebarProvider>
                  </I18nProvider>
                </RouterProvider>
              </GlobalAppsProvider>
            </CookiePreferencesProvider>
          </TenantConfigProvider>
        </ViewerProvider>
      </RelayProvider>
    </SessionProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
