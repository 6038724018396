import { FormattedMessage } from "react-intl";

import ConfirmModal from "components/ConfirmModal";
import PreformattedText from "components/PreformattedText";
import { useTenantConfig } from "contexts/TenantConfig";

type Props = {
  onClose: () => void;
};

const PrivacyPolicyModal = ({ onClose }: Props): React.ReactElement => {
  const { latestPrivacyPolicy } = useTenantConfig();

  return (
    <ConfirmModal
      title={
        <FormattedMessage
          id="privacyPolicy.modal.title"
          defaultMessage="Privacy Policy"
        />
      }
      confirmLabel={
        <FormattedMessage
          id="privacyPolicy.modal.confirmButton"
          defaultMessage="OK"
        />
      }
      onConfirm={onClose}
    >
      <PreformattedText>{latestPrivacyPolicy.text}</PreformattedText>
    </ConfirmModal>
  );
};

export default PrivacyPolicyModal;
