import React, { useCallback, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import type { FormControlProps } from "react-bootstrap/FormControl";

import Icon from "components/Icon";
import "./InputPassword.scss";

type Props = Omit<FormControlProps & React.HTMLProps<HTMLInputElement>, "type">;

const InputPassword = ({ children, className, ...restProps }: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((v) => !v);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={`password-control position-relative ${className}`}>
      <Form.Control
        ref={inputRef}
        type={isPasswordVisible ? "text" : "password"}
        {...restProps}
      />
      <div
        className="visibility-icon px-1 py-2 position-absolute"
        role="button"
        onClick={togglePasswordVisibility}
      >
        <Icon icon={isPasswordVisible ? "hide" : "show"} />
      </div>
      {children}
    </div>
  );
};

export default InputPassword;
