import React, { useCallback, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import type {
  RoleInput,
  Permission,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import CheckboxTree, { addTreeProps, Tree } from "components/CheckboxTree";
import SectionCard from "components/SectionCard";
import Stack from "components/Stack";

const appliancesPermissionTrees: Tree<Permission>[] = [
  {
    id: "appliances",
    label: (
      <FormattedMessage
        id="components.RoleForm.allAppliancesPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_CREATE_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canCreateAppliancesPermission"
            defaultMessage="Can add Appliances"
            description="Label for the permission to create appliances"
          />
        ),
      },
      {
        value: "CAN_LIST_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListAppliancesPermission"
            defaultMessage="Can see Appliance List"
            description="Label for the permission to list appliances"
          />
        ),
      },
      {
        value: "CAN_GET_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetAppliancesPermission"
            defaultMessage="Can see specific Appliances"
            description="Label for the permission to see specific appliances"
          />
        ),
      },
      {
        value: "CAN_EDIT_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canEditAppliancesPermission"
            defaultMessage="Can edit info for Appliances"
            description="Label for the permission to edit appliances"
          />
        ),
      },
    ],
  },
];

const applianceModelsPermissionTrees: Tree<Permission>[] = [
  {
    id: "applianceModels",
    label: (
      <FormattedMessage
        id="components.RoleForm.allApplianceModelsPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_CREATE_APPLIANCE_MODELS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canCreateApplianceModelsPermission"
            defaultMessage="Can create Appliance Models"
            description="Label for the permission to create appliance models"
          />
        ),
      },
      {
        value: "CAN_LIST_APPLIANCE_MODELS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListApplianceModelsPermission"
            defaultMessage="Can see Appliance Model List"
            description="Label for the permission to list appliance models"
          />
        ),
      },
      {
        value: "CAN_GET_APPLIANCE_MODELS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetApplianceModelsPermission"
            defaultMessage="Can see specific Appliance Models"
            description="Label for the permission to see specific appliance models"
          />
        ),
      },
      {
        value: "CAN_EDIT_APPLIANCE_MODELS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canEditApplianceModelsPermission"
            defaultMessage="Can edit info for Appliance Models"
            description="Label for the permission to edit appliance models"
          />
        ),
      },
      {
        value: "CAN_DELETE_APPLIANCE_MODELS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteApplianceModelsPermission"
            defaultMessage="Can delete Appliance Models"
            description="Label for the permission to delete appliance models"
          />
        ),
      },
    ],
  },
];

const applianceManagementPermissionTrees: Tree<Permission>[] = [
  {
    id: "applianceManagement",
    label: (
      <FormattedMessage
        id="components.RoleForm.allApplianceManagementPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_DELETE_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteAppliancesPermission"
            defaultMessage="Can delete Appliances"
            description="Label for the permission to delete appliances"
          />
        ),
      },
    ],
  },
];

const clientsPermissionTrees: Tree<Permission>[] = [
  {
    id: "clients",
    label: (
      <FormattedMessage
        id="components.RoleForm.allClientsPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListClientsPermission"
            defaultMessage="Can see Client List"
            description="Label for the permission to list clients"
          />
        ),
      },
      {
        value: "CAN_ADD_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canAddClientsPermission"
            defaultMessage="Can add Clients"
            description="Label for the permission to add clients"
          />
        ),
      },
      {
        value: "CAN_REMOVE_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canRemoveClientsPermission"
            defaultMessage="Can remove Clients"
            description="Label for the permission to remove clients"
          />
        ),
      },
      {
        value: "CAN_ASSIGN_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canAssignAppliancesPermission"
            defaultMessage="Can assign Appliances to Clients"
            description="Label for the permission to assign appliances to clients"
          />
        ),
      },
    ],
  },
];

const rolesPermissionTrees: Tree<Permission>[] = [
  {
    id: "roles",
    label: (
      <FormattedMessage
        id="components.RoleForm.allRolesPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListRolesPermission"
            defaultMessage="Can see Role List"
            description="Label for the permission to list roles"
          />
        ),
      },
      {
        value: "CAN_GET_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetRolesPermission"
            defaultMessage="Can see specific Roles"
            description="Label for the permission to see specific roles"
          />
        ),
      },
      {
        value: "CAN_CREATE_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canCreateRolesPermission"
            defaultMessage="Can create Roles"
            description="Label for the permission to create roles"
          />
        ),
      },
      {
        value: "CAN_EDIT_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canEditRolesPermission"
            defaultMessage="Can edit Roles"
            description="Label for the permission to edit roles"
          />
        ),
      },
      {
        value: "CAN_DELETE_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteRolesPermission"
            defaultMessage="Can delete Roles"
            description="Label for the permission to delete roles"
          />
        ),
      },
    ],
  },
];

const usersPermissionTrees: Tree<Permission>[] = [
  {
    id: "users",
    label: (
      <FormattedMessage
        id="components.RoleForm.allUsersPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListUsersPermission"
            defaultMessage="Can see User List"
            description="Label for the permission to list users"
          />
        ),
      },
      {
        value: "CAN_GET_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetUsersPermission"
            defaultMessage="Can see specific Users"
            description="Label for the permission to see specific users"
          />
        ),
      },
      {
        value: "CAN_MANAGE_USER_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canManageUsersRolesPermission"
            defaultMessage="Can manage Users Roles"
            description="Label for the permission to manage users roles"
          />
        ),
      },
      {
        value: "CAN_INVITE_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canInviteUsersPermission"
            defaultMessage="Can invite Users"
            description="Label for the permission to invite users"
          />
        ),
      },
      {
        value: "CAN_DELETE_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteUsersPermission"
            defaultMessage="Can delete Users"
            description="Label for the permission to delete users"
          />
        ),
      },
    ],
  },
];

type TreeProps = {
  visible: boolean;
  hasNotApplicablePermission: boolean;
};

type PermissionCardProps = {
  title: React.ReactNode;
  trees: Tree<Permission, TreeProps>[];
  checked: readonly Permission[];
  onChange: (permissions: Permission[]) => void;
  readOnly: boolean;
};

const PermissionsCard = ({
  title,
  trees,
  checked,
  onChange,
  readOnly,
}: PermissionCardProps) => {
  const hasNotApplicablePermission = trees.some(
    (tree) => tree.hasNotApplicablePermission
  );
  return (
    <SectionCard title={title}>
      <CheckboxTree
        trees={trees}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        alwaysExpanded
        renderLabel={(tree) => (
          <span>
            {tree.label}
            {tree.hasNotApplicablePermission && <span>&nbsp;*</span>}
          </span>
        )}
      />
      {hasNotApplicablePermission && (
        <p className="text-muted">
          *&nbsp;
          <FormattedMessage
            id="components.RoleForm.permissionsNotApplicable"
            defaultMessage="Permissions currently not applicable."
          />
        </p>
      )}
    </SectionCard>
  );
};

interface Props {
  className?: string;
  readOnlyName?: boolean;
  readOnlyPermissions?: boolean;
  value: RoleInput;
  onChange?: (data: RoleInput, isValid: boolean) => void;
  allowedPermissions: Permission[];
  visiblePermissions: Permission[];
}

const RoleForm = ({
  className = "",
  readOnlyName = false,
  readOnlyPermissions = false,
  value,
  onChange,
  allowedPermissions,
  visiblePermissions,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const sections = useMemo(() => {
    const getTreeProps = (tree: Tree<Permission>): TreeProps => {
      let visible =
        tree.value != null && visiblePermissions.includes(tree.value);
      let hasNotApplicablePermission =
        tree.value != null && !allowedPermissions.includes(tree.value);
      const childrenProps = tree.children && tree.children.map(getTreeProps);
      if (childrenProps && childrenProps.some((c) => c.visible)) {
        visible = true;
      }
      if (
        childrenProps &&
        childrenProps.some((c) => c.hasNotApplicablePermission)
      ) {
        hasNotApplicablePermission = true;
      }
      return { visible, hasNotApplicablePermission };
    };

    return [
      {
        trees: appliancesPermissionTrees.map(addTreeProps(getTreeProps)),
        title: (
          <FormattedMessage
            id="components.RoleForm.appliancesPermissionsTitle"
            defaultMessage="Appliances"
          />
        ),
      },
      {
        trees: applianceManagementPermissionTrees.map(
          addTreeProps(getTreeProps)
        ),
        title: (
          <FormattedMessage
            id="components.RoleForm.applianceManagementPermissionsTitle"
            defaultMessage="Appliance Management"
          />
        ),
      },
      {
        trees: applianceModelsPermissionTrees.map(addTreeProps(getTreeProps)),
        title: (
          <FormattedMessage
            id="components.RoleForm.applianceModelsPermissionsTitle"
            defaultMessage="Appliance Models"
          />
        ),
      },
      {
        trees: clientsPermissionTrees.map(addTreeProps(getTreeProps)),
        title: (
          <FormattedMessage
            id="components.RoleForm.clientsPermissionsTitle"
            defaultMessage="Clients"
          />
        ),
      },
      {
        trees: rolesPermissionTrees.map(addTreeProps(getTreeProps)),
        title: (
          <FormattedMessage
            id="components.RoleForm.rolesPermissionsTitle"
            defaultMessage="Roles"
          />
        ),
      },
      {
        trees: usersPermissionTrees.map(addTreeProps(getTreeProps)),
        title: (
          <FormattedMessage
            id="components.RoleForm.usersPermissionsTitle"
            defaultMessage="Users"
          />
        ),
      },
    ];
  }, [allowedPermissions, visiblePermissions]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue =
        target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  const handlePermissionsChange = useCallback(
    (permissions: Permission[]) => {
      const newValue = { ...value, permissions };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  return (
    <Form ref={formRef} className={className} data-testid="role-form">
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.RoleForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the role form"
            />
          </Form.Label>
          <Form.Control
            value={value.name}
            onChange={handleInputChange}
            readOnly={readOnlyName}
            plaintext={readOnlyName}
            required
            placeholder={intl.formatMessage({
              id: "components.RoleForm.namePlaceholder",
              defaultMessage: "Type in the role name",
              description: "Placeholder for the name field in the role form",
            })}
            name="name"
            data-testid="role-form-name"
          />
        </Form.Group>
        <Form.Group controlId="permissions" data-testid="role-form-permissions">
          <Form.Label>
            <FormattedMessage
              id="components.RoleForm.permissionsLabel"
              defaultMessage="Permissions"
              description="Label for the permissions field in the role form"
            />
          </Form.Label>
          <Row xs={1} lg={2} xl={3} xxl={4} className="g-4 pt-1">
            {sections
              .filter((section) => section.trees.some((t) => t.visible))
              .map((section, index) => (
                <Col key={index}>
                  <PermissionsCard
                    title={section.title}
                    trees={section.trees}
                    checked={value.permissions}
                    onChange={handlePermissionsChange}
                    readOnly={readOnlyPermissions}
                  />
                </Col>
              ))}
          </Row>
        </Form.Group>
      </Stack>
    </Form>
  );
};

export type { Permission };

export default RoleForm;
