import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";

import type { ApplianceModelCreate_createApplianceModel_Mutation } from "api/__generated__/ApplianceModelCreate_createApplianceModel_Mutation.graphql";
import * as images from "assets/images";
import { Route, useNavigate } from "Navigation";
import Image from "components/Image";
import Page from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import ApplianceModelForm, {
  ApplianceModelChanges,
} from "forms/ApplianceModel";

const CREATE_APPLIANCE_MODEL_MUTATION = graphql`
  mutation ApplianceModelCreate_createApplianceModel_Mutation(
    $input: CreateApplianceModelInput!
  ) {
    createApplianceModel(input: $input) {
      applianceModel {
        id
        handle
        names {
          locale
          text
        }
        descriptions {
          locale
          text
        }
        partNumbers
        pictureUrl
      }
    }
  }
`;

const ApplianceModelCreateSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <Image src={images.devices} />
    </Stack>
  );
};

const ApplianceModelCreate = () => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const [
    createApplianceModel,
    isCreatingApplianceModel,
  ] = useMutation<ApplianceModelCreate_createApplianceModel_Mutation>(
    CREATE_APPLIANCE_MODEL_MUTATION
  );

  const handleCreateApplianceModel = useCallback(
    (applianceModel: ApplianceModelChanges) => {
      createApplianceModel({
        variables: { input: applianceModel },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          const applianceModelId = data.createApplianceModel?.applianceModel.id;
          if (applianceModelId) {
            navigate({
              route: Route.applianceModelsEdit,
              params: { applianceModelId },
            });
          } else {
            navigate({ route: Route.applianceModels });
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.ApplianceModelCreate.saveErrorFeedback"
              defaultMessage="Could not create the appliance model, please try again."
              description="Feedback for unknown creation error in the ApplianceModelCreate page"
            />
          );
        },
        updater(store, data) {
          const applianceModelId =
            data.createApplianceModel?.applianceModel?.id;
          if (applianceModelId) {
            const applianceModel = store.get(applianceModelId);
            const root = store.getRoot();
            const applianceModels = root.getLinkedRecords("applianceModels");
            if (applianceModel && applianceModels) {
              root.setLinkedRecords(
                [applianceModel, ...applianceModels],
                "applianceModels"
              );
            }
          }
        },
      });
    },
    [createApplianceModel, navigate]
  );

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ApplianceModelCreate.title"
          defaultMessage="Create Model"
          description="Title for the ApplianceModelCreate page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <ApplianceModelForm
          onSubmit={handleCreateApplianceModel}
          isLoading={isCreatingApplianceModel}
          submitLabel={
            <FormattedMessage
              id="pages.ApplianceModelCreate.createApplianceModelButton"
              defaultMessage="Create Appliance Model"
            />
          }
        />
      </Stack>
      <SidebarContent>
        <ApplianceModelCreateSidebar />
      </SidebarContent>
    </Page>
  );
};

export default ApplianceModelCreate;
