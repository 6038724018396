/**
 * @generated SignedSource<<d89340d5e80248abc10affbc1906155a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplianceModelsTable_ApplianceModels$data = ReadonlyArray<{
  readonly handle: string;
  readonly id: string;
  readonly names: ReadonlyArray<{
    readonly text: string;
  }>;
  readonly partNumbers: ReadonlyArray<string>;
  readonly " $fragmentType": "ApplianceModelsTable_ApplianceModels";
}>;
export type ApplianceModelsTable_ApplianceModels$key = ReadonlyArray<{
  readonly " $data"?: ApplianceModelsTable_ApplianceModels$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplianceModelsTable_ApplianceModels">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ApplianceModelsTable_ApplianceModels",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalizedText",
      "kind": "LinkedField",
      "name": "names",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partNumbers",
      "storageKey": null
    }
  ],
  "type": "ApplianceModel",
  "abstractKey": null
};

(node as any).hash = "1d5f5a1084b3b9f895a075a372e00cc6";

export default node;
