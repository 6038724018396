/**
 * @generated SignedSource<<61e03e8e86605284c99dcbbbe29dcf4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalApps_getApplications_Query$variables = {};
export type GlobalApps_getApplications_Query$data = {
  readonly applications: ReadonlyArray<{
    readonly authToken: string;
    readonly baseApiUrl: string;
    readonly displayName: string;
    readonly id: string;
    readonly protocol: string;
    readonly realmName: string;
    readonly slug: string;
    readonly sourceUrl: string;
  }>;
};
export type GlobalApps_getApplications_Query = {
  response: GlobalApps_getApplications_Query$data;
  variables: GlobalApps_getApplications_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Application",
    "kind": "LinkedField",
    "name": "applications",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "protocol",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sourceUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseApiUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "realmName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalApps_getApplications_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GlobalApps_getApplications_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b47eefe6b08b81c89ececeff0f8ac9a8",
    "id": null,
    "metadata": {},
    "name": "GlobalApps_getApplications_Query",
    "operationKind": "query",
    "text": "query GlobalApps_getApplications_Query {\n  applications {\n    id\n    slug\n    displayName\n    protocol\n    sourceUrl\n    authToken\n    baseApiUrl\n    realmName\n  }\n}\n"
  }
};
})();

(node as any).hash = "63769a6e12185f436ddadf929a3f4ca8";

export default node;
