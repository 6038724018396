import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { yup } from "forms";

import type { ForgotPassword_RequestResetPasswordEmail_Mutation } from "api/__generated__/ForgotPassword_RequestResetPasswordEmail_Mutation.graphql";
import { Link, Route } from "Navigation";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import FormFeedback from "forms/FormFeedback";
import Stack from "components/Stack";

const REQUEST_RESET_PASSWORD_EMAIL_MUTATION = graphql`
  mutation ForgotPassword_RequestResetPasswordEmail_Mutation(
    $input: RequestResetPasswordEmailInput!
  ) {
    requestResetPasswordEmail(input: $input) {
      email
    }
  }
`;

type FormData = {
  email: string;
};

const fromDataSchema = yup.object({
  email: yup.string().required().email(),
});

const ForgotPassword = () => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [successFeedback, setSuccessFeedback] = useState<React.ReactNode>(null);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(fromDataSchema),
  });
  const [
    requestResetPasswordEmail,
    isRequestingResetPasswordEmail,
  ] = useMutation<ForgotPassword_RequestResetPasswordEmail_Mutation>(
    REQUEST_RESET_PASSWORD_EMAIL_MUTATION
  );

  const handleFormSubmit = handleSubmit((input: FormData) => {
    requestResetPasswordEmail({
      variables: { input },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setErrorFeedback(errorFeedback);
        } else {
          reset();
          setSuccessFeedback(
            <FormattedMessage
              id="pages.ForgotPassword.emailSentFeedback"
              defaultMessage="An email with the password reset instructions will be shortly sent to the provided address. Please check your email inbox."
              description="Feedback for a successful password reset request"
            />
          );
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.ForgotPassword.loadingErrorFeedback"
            defaultMessage="Could not request a password reset, please try again."
            description="Feedback for unknown loading error in the forgot password page"
          />
        );
      },
    });
  });

  return (
    <AuthPage>
      <Alert
        show={!!successFeedback}
        variant="success"
        onClose={() => setSuccessFeedback(null)}
        dismissible
      >
        {successFeedback}
      </Alert>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Form onSubmit={handleFormSubmit}>
        <Stack gap={3}>
          <p className="text-center">
            <FormattedMessage
              id="pages.ForgotPassword.form.headingText"
              defaultMessage="Enter your email address to request a password reset email"
            />
          </p>
          <Form.Group controlId="user-email">
            <Form.Label>
              <FormattedMessage
                id="pages.ForgotPassword.emailLabel"
                defaultMessage="Email"
              />
            </Form.Label>
            <Form.Control {...register("email")} isInvalid={!!errors.email} />
            <FormFeedback feedback={errors.email?.message} />
          </Form.Group>
          <Button
            type="submit"
            className="w-100 mt-3"
            disabled={isRequestingResetPasswordEmail}
            loading={isRequestingResetPasswordEmail}
          >
            <FormattedMessage
              id="pages.ForgotPassword.requestResetPasswordButton"
              defaultMessage="Request Password Reset"
              description="Title for the button to request a password reset"
            />
          </Button>
        </Stack>
        <div className="mt-5 text-center">
          <FormattedMessage
            id="pages.ForgotPassword.loginLink"
            defaultMessage="Back to <loginLink>login</loginLink>"
            description="Title for the link to the Login Page"
            values={{
              loginLink: (chunks: React.ReactNode) => (
                <Link route={Route.login}>{chunks}</Link>
              ),
            }}
          />
        </div>
      </Form>
    </AuthPage>
  );
};

export default ForgotPassword;
