import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";

import Spinner from "components/Spinner";
import { useTenantConfig } from "contexts/TenantConfig";
import {
  CookieAttributes,
  getCookie,
  setCookie,
  removeCookie,
} from "services/cookies";

type AcceptedCookies = "All" | "StrictlyNecessary";

const isValidCookie = (val: string): val is AcceptedCookies =>
  val === "All" || val === "StrictlyNecessary";

const parseAcceptedCookies = (
  latestCookiePolicyId: string,
  encodedValue: string
): AcceptedCookies | null => {
  let preferences = null;
  try {
    preferences = JSON.parse(encodedValue);
  } catch {}

  if (!preferences) {
    return null;
  }

  if (
    !preferences?.cookiePolicyId ||
    preferences.cookiePolicyId !== latestCookiePolicyId
  ) {
    return null;
  }

  if (
    !preferences?.acceptedCookies ||
    !isValidCookie(preferences.acceptedCookies)
  ) {
    return null;
  }

  return preferences.acceptedCookies;
};

const loadCookiePreferences = async (
  latestCookiePolicyId: string
): Promise<AcceptedCookies | null> => {
  let acceptedCookies = null;
  const encodedValue = await getCookie("cookiePreferences");
  if (encodedValue) {
    acceptedCookies = parseAcceptedCookies(latestCookiePolicyId, encodedValue);
    if (!acceptedCookies) {
      saveCookiePreferences(latestCookiePolicyId, null);
    }
  }
  return acceptedCookies;
};

const saveCookiePreferences = async (
  cookiePolicyId: string,
  acceptedCookies: AcceptedCookies | null
) => {
  const cookieOptions: CookieAttributes = {
    secure: true,
    expires: 180, // about 6 months
    sameSite: "Strict",
  };

  if (acceptedCookies) {
    const cookiePreferences = JSON.stringify({
      acceptedCookies,
      cookiePolicyId,
    });
    await setCookie("cookiePreferences", cookiePreferences, cookieOptions);
  } else {
    await removeCookie("cookiePreferences", cookieOptions);
  }
};

type CookiePreferencesContextValue = {
  cookiePreferences: AcceptedCookies | null;
  setCookiePreferences: (cookiePreferences: AcceptedCookies | null) => void;
};

const CookiePreferencesContext = createContext<CookiePreferencesContextValue | null>(
  null
);

type CookiePreferencesProviderProps = {
  children: React.ReactNode;
};

const CookiePreferencesProvider = ({
  children,
}: CookiePreferencesProviderProps) => {
  const [isLoadingCookiePreferences, setIsLoadingCookiePreferences] = useState(
    true
  );
  const [
    cookiePreferences,
    setCookiePreferences,
  ] = useState<AcceptedCookies | null>(null);
  const { latestCookiePolicy } = useTenantConfig();

  const handleSetCookiePreferences = useCallback(
    (newCookiePreferences) => {
      saveCookiePreferences(latestCookiePolicy.id, newCookiePreferences);
      setCookiePreferences(newCookiePreferences);
    },
    [latestCookiePolicy.id]
  );

  const contextValue = useMemo(
    () => ({
      cookiePreferences,
      setCookiePreferences: handleSetCookiePreferences,
    }),
    [cookiePreferences, handleSetCookiePreferences]
  );

  useEffect(() => {
    loadCookiePreferences(latestCookiePolicy.id)
      .then(handleSetCookiePreferences)
      .catch(() => handleSetCookiePreferences(null))
      .finally(() => setIsLoadingCookiePreferences(false));
  }, [latestCookiePolicy.id, handleSetCookiePreferences]);

  if (isLoadingCookiePreferences) {
    return (
      <div
        className="vh-100 d-flex justify-content-center align-items-center"
        data-testid="cookie-preferences-loading"
      >
        <Spinner />
      </div>
    );
  }

  return (
    <CookiePreferencesContext.Provider value={contextValue}>
      {children}
    </CookiePreferencesContext.Provider>
  );
};

const useCookiePreferences = () => {
  const contextValue = useContext(CookiePreferencesContext);
  if (contextValue == null) {
    throw new Error("CookiePreferencesContext has not been Provided");
  }
  return contextValue;
};

export { CookiePreferencesContext, useCookiePreferences };
export type { AcceptedCookies, CookiePreferencesContextValue };

export default CookiePreferencesProvider;
