import Card from "react-bootstrap/Card";

import Stack from "components/Stack";

type Props = {
  children?: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
};

const SectionCard = ({ children, className = "", title }: Props) => {
  const cardClassName = ["h-100 border-0 shadow-sm p-3 p-md-4", className].join(
    " "
  );
  return (
    <Card className={cardClassName}>
      <Stack gap={3}>
        {title && <h6 className="text-primary">{title}</h6>}
        {children}
      </Stack>
    </Card>
  );
};

export default SectionCard;
