import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";

import ConfirmModal from "components/ConfirmModal";

interface DeleteModalProps {
  children?: React.ReactNode;
  confirmText: string;
  isDeleting?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: React.ReactNode;
}

const DeleteModal = ({
  children,
  confirmText,
  isDeleting,
  onCancel,
  onConfirm,
  title,
  ...restProps
}: DeleteModalProps) => {
  const [confirmString, setConfirmString] = useState("");

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setConfirmString(e.target.value),
    []
  );

  const canDelete = confirmString === confirmText;

  return (
    <ConfirmModal
      title={title}
      confirmLabel={
        <FormattedMessage
          id="components.DeleteModal.confirmButton"
          defaultMessage="Delete"
          description="Title for the button to confirm a deletion modal"
        />
      }
      confirmVariant="solid-danger"
      onCancel={onCancel}
      onConfirm={onConfirm}
      isConfirming={isDeleting}
      disabled={!canDelete}
      {...restProps}
    >
      {children}
      <p>
        <FormattedMessage
          id="components.DeleteModal.confirmPrompt"
          defaultMessage="Please type <bold>{confirmText}</bold> to confirm."
          description="Description of the action to perform to confirm the deletion modal"
          values={{
            confirmText,
            bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
          }}
        />
      </p>
      <Form.Group controlId="confirmResourceName">
        <Form.Control
          type="text"
          value={confirmString}
          placeholder={confirmText}
          onChange={handleInputChange}
        />
      </Form.Group>
    </ConfirmModal>
  );
};

export default DeleteModal;
