/**
 * @generated SignedSource<<23135d36c7dc51b4a62530f33f75a230>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordInput = {
  email: string;
  newPassword: string;
  token: string;
};
export type ResetPassword_ResetPassword_Mutation$variables = {
  input: ResetPasswordInput;
};
export type ResetPassword_ResetPassword_Mutation$data = {
  readonly resetPassword: {
    readonly email: string;
  } | null;
};
export type ResetPassword_ResetPassword_Mutation = {
  response: ResetPassword_ResetPassword_Mutation$data;
  variables: ResetPassword_ResetPassword_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResetPasswordPayload",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPassword_ResetPassword_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPassword_ResetPassword_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "18e81edb023f7824fb67e8d5a030c0e6",
    "id": null,
    "metadata": {},
    "name": "ResetPassword_ResetPassword_Mutation",
    "operationKind": "mutation",
    "text": "mutation ResetPassword_ResetPassword_Mutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input) {\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "f16d051fc29fedec9883c4f439be0e96";

export default node;
