/**
 * @generated SignedSource<<618125790dfb302c42df6def905ba644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Profile_deleteAccount_Mutation$variables = {};
export type Profile_deleteAccount_Mutation$data = {
  readonly deleteAccount: {
    readonly user: {
      readonly id: string;
    };
  } | null;
};
export type Profile_deleteAccount_Mutation = {
  response: Profile_deleteAccount_Mutation$data;
  variables: Profile_deleteAccount_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeleteAccountPayload",
    "kind": "LinkedField",
    "name": "deleteAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_deleteAccount_Mutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Profile_deleteAccount_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f703f7988e54e67d3d8b2f420aab6f17",
    "id": null,
    "metadata": {},
    "name": "Profile_deleteAccount_Mutation",
    "operationKind": "mutation",
    "text": "mutation Profile_deleteAccount_Mutation {\n  deleteAccount {\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3d94f2f0d4d528ad74d15c719de340a";

export default node;
