/**
 * @generated SignedSource<<a9ea8dd3f90a1ccdcfb858d721a23acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ApplianceModel_getApplianceModel_Query$variables = {
  id: string;
};
export type ApplianceModel_getApplianceModel_Query$data = {
  readonly applianceModel: {
    readonly descriptions: ReadonlyArray<{
      readonly locale: string;
      readonly text: string;
    }>;
    readonly handle: string;
    readonly id: string;
    readonly names: ReadonlyArray<{
      readonly locale: string;
      readonly text: string;
    }>;
    readonly partNumbers: ReadonlyArray<string>;
    readonly pictureUrl: string | null;
  } | null;
};
export type ApplianceModel_getApplianceModel_Query = {
  response: ApplianceModel_getApplianceModel_Query$data;
  variables: ApplianceModel_getApplianceModel_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ApplianceModel",
    "kind": "LinkedField",
    "name": "applianceModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "handle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalizedText",
        "kind": "LinkedField",
        "name": "names",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalizedText",
        "kind": "LinkedField",
        "name": "descriptions",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "partNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pictureUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplianceModel_getApplianceModel_Query",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplianceModel_getApplianceModel_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9925a515e3f96ad3b341632138ddf877",
    "id": null,
    "metadata": {},
    "name": "ApplianceModel_getApplianceModel_Query",
    "operationKind": "query",
    "text": "query ApplianceModel_getApplianceModel_Query(\n  $id: ID!\n) {\n  applianceModel(id: $id) {\n    id\n    handle\n    names {\n      locale\n      text\n    }\n    descriptions {\n      locale\n      text\n    }\n    partNumbers\n    pictureUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "245da195b00b01ac73bbc790b8ce84e0";

export default node;
