import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faBook,
  faCircle,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faGlobeAmericas,
  faLanguage,
  faPaste,
  faPlus,
  faPowerOff,
  faSearch,
  faTabletAlt,
  faTimes,
  faUser,
  faUserFriends,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  appliances: faTabletAlt,
  arrowDown: faArrowDown,
  arrowUp: faArrowUp,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  circle: faCircle,
  close: faTimes,
  clients: faGlobeAmericas,
  copyPaste: faPaste,
  documents: faBook,
  doubleCaretLeft: faAngleDoubleLeft,
  doubleCaretRight: faAngleDoubleRight,
  hide: faEyeSlash,
  plus: faPlus,
  language: faLanguage,
  logout: faPowerOff,
  moreOptionsMenu: faEllipsisV,
  profile: faUser,
  search: faSearch,
  show: faEye,
  users: faUserFriends,
  applications: faRocket,
} as const;

type FontAwesomeIconProps = React.ComponentProps<typeof FontAwesomeIcon>;

type IconName = keyof typeof icons;

type Props = Omit<FontAwesomeIconProps, "icon"> & {
  icon: IconName;
};

const Icon = ({ icon, ...restProps }: Props) => {
  return <FontAwesomeIcon {...restProps} icon={icons[icon]} />;
};

export type { IconName };

export default Icon;
