import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as images from "assets/images";
import Button from "components/Button";
import CookiePolicyModal from "components/CookiePolicyModal";
import Image from "components/Image";
import Page from "components/Page";
import PrivacyPolicyModal from "components/PrivacyPolicyModal";
import SectionCard from "components/SectionCard";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import TermsAndConditionsModal from "components/TermsAndConditionsModal";

const LegalTermsSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.legal} />
    </Stack>
  );
};

const LegalTerms = () => {
  const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(false);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.LegalTerms.title"
          defaultMessage="Legal Terms"
        />
      }
    >
      <Row xs={1} lg={2} xl={3} className="g-4">
        <Col>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.LegalTerms.cookiePolicySection.title"
                defaultMessage="Cookie Policy"
              />
            }
          >
            <Button
              variant="outline-primary"
              onClick={() => setShowCookiePolicyModal(true)}
            >
              <FormattedMessage
                id="pages.LegalTerms.cookiePolicySection.button"
                defaultMessage="Review Policy"
              />
            </Button>
          </SectionCard>
        </Col>
        <Col>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.LegalTerms.privacyPolicySection.title"
                defaultMessage="Privacy Policy"
              />
            }
          >
            <Button
              variant="outline-primary"
              onClick={() => setShowPrivacyPolicyModal(true)}
            >
              <FormattedMessage
                id="pages.LegalTerms.privacyPolicySection.button"
                defaultMessage="Review Policy"
              />
            </Button>
          </SectionCard>
        </Col>
        <Col>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.LegalTerms.termsAndConditionsSection.title"
                defaultMessage="Terms & Conditions"
              />
            }
          >
            <Button
              variant="outline-primary"
              onClick={() => setShowTermsAndConditionsModal(true)}
            >
              <FormattedMessage
                id="pages.LegalTerms.termsAndConditionsSection.button"
                defaultMessage="Review Terms"
              />
            </Button>
          </SectionCard>
        </Col>
      </Row>
      <SidebarContent>
        <LegalTermsSidebar />
      </SidebarContent>
      {showCookiePolicyModal && (
        <CookiePolicyModal onClose={() => setShowCookiePolicyModal(false)} />
      )}
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal onClose={() => setShowPrivacyPolicyModal(false)} />
      )}
      {showTermsAndConditionsModal && (
        <TermsAndConditionsModal
          onClose={() => setShowTermsAndConditionsModal(false)}
        />
      )}
    </Page>
  );
};

export default LegalTerms;
