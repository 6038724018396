import * as yup from "yup";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  arrayMin: {
    id: "validation.array.min",
    defaultMessage: "Must have at least {min} values.",
  },
  required: {
    id: "validation.required",
    defaultMessage: "Required.",
  },
  email: {
    id: "validation.email",
    defaultMessage: "Must be a valid email address.",
  },
  stringMin: {
    id: "validation.string.min",
    defaultMessage: "Must be at least {min} characters long.",
  },
  passwordConfirm: {
    id: "validation.passwordConfirm.notMatch",
    defaultMessage: "Passwords must match.",
  },
  unique: {
    id: "validation.unique",
    defaultMessage: "Duplicate value.",
  },
  handleFormat: {
    id: "validation.handle.format",
    defaultMessage:
      "The handle should only contain lower case ASCII letters (from a to z), digits and -, and should not start or end with the hyphen - symbol.",
  },
});

yup.setLocale({
  mixed: {
    required: messages.required.id,
  },
  array: {
    min: ({ min }) => ({
      templateId: messages.arrayMin.id,
      values: { min },
    }),
  },
  string: {
    email: messages.email.id,
    min: ({ min }) => ({
      templateId: messages.stringMin.id,
      values: { min },
    }),
  },
});

const applianceModelHandleSchema = yup
  .string()
  .matches(/^[a-z][a-z\d-]*$/, messages.handleFormat.id);

export { applianceModelHandleSchema, messages, yup };
