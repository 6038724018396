import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";

import * as images from "assets/images";
import type { ApplianceRegister_createAppliance_Mutation } from "api/__generated__/ApplianceRegister_createAppliance_Mutation.graphql";
import { Route, useNavigate } from "Navigation";
import ApplianceForm, { ApplianceDraft } from "components/ApplianceForm";
import Button from "components/Button";
import Image from "components/Image";
import Page from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const CREATE_APPLIANCE_MUTATION = graphql`
  mutation ApplianceRegister_createAppliance_Mutation(
    $input: CreateApplianceInput!
  ) {
    createAppliance(input: $input) {
      appliance {
        id
      }
    }
  }
`;

const ApplianceRegisterSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.devices} />
    </Stack>
  );
};

const initialDraft: ApplianceDraft = {
  serial: "",
  name: "",
  deviceId: "",
};

const ApplianceRegister = () => {
  const [draft, setDraft] = useState({
    appliance: initialDraft,
    isValid: false,
  });
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const [
    createAppliance,
    isCreatingAppliance,
  ] = useMutation<ApplianceRegister_createAppliance_Mutation>(
    CREATE_APPLIANCE_MUTATION
  );

  const handleRegisterAppliance = useCallback(() => {
    const appliance = {
      name: draft.appliance.name,
      serial: draft.appliance.serial,
    };
    const device = { deviceId: draft.appliance.deviceId };
    createAppliance({
      variables: { input: { appliance, device } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const applianceId = data.createAppliance?.appliance.id;
        if (applianceId) {
          navigate({ route: Route.appliancesEdit, params: { applianceId } });
        } else {
          navigate({ route: Route.appliances });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.ApplianceRegister.saveErrorFeedback"
            defaultMessage="Could not register the appliance, please try again."
            description="Feedback for unknown registration error in the ApplianceRegister page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [draft.appliance, createAppliance, navigate]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ApplianceRegister.title"
          defaultMessage="Register Appliance"
          description="Title for the ApplianceRegister page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <ApplianceForm
          value={draft.appliance}
          onChange={(appliance, isValid) => setDraft({ appliance, isValid })}
        />
        <div className="mt-3 d-flex justify-content-end flex-column flex-md-row">
          <Button
            disabled={!draft.isValid || isCreatingAppliance}
            loading={isCreatingAppliance}
            onClick={handleRegisterAppliance}
          >
            <FormattedMessage
              id="pages.ApplianceRegister.registerApplianceButton"
              defaultMessage="Register Appliance"
            />
          </Button>
        </div>
      </Stack>
      <SidebarContent>
        <ApplianceRegisterSidebar />
      </SidebarContent>
    </Page>
  );
};

export default ApplianceRegister;
