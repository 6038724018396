/**
 * @generated SignedSource<<0c69325e3f5ed124103ecf3be89f8747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateApplianceModelInput = {
  applianceModelId: string;
  descriptions?: ReadonlyArray<LocalizedTextInput> | null;
  handle?: string | null;
  names?: ReadonlyArray<LocalizedTextInput> | null;
  partNumbers?: ReadonlyArray<string> | null;
  pictureFile?: any | null;
  pictureUrl?: string | null;
};
export type LocalizedTextInput = {
  locale: string;
  text: string;
};
export type ApplianceModel_updateApplianceModel_Mutation$variables = {
  input: UpdateApplianceModelInput;
};
export type ApplianceModel_updateApplianceModel_Mutation$data = {
  readonly updateApplianceModel: {
    readonly applianceModel: {
      readonly descriptions: ReadonlyArray<{
        readonly locale: string;
        readonly text: string;
      }>;
      readonly handle: string;
      readonly id: string;
      readonly names: ReadonlyArray<{
        readonly locale: string;
        readonly text: string;
      }>;
      readonly partNumbers: ReadonlyArray<string>;
      readonly pictureUrl: string | null;
    };
  } | null;
};
export type ApplianceModel_updateApplianceModel_Mutation = {
  response: ApplianceModel_updateApplianceModel_Mutation$data;
  variables: ApplianceModel_updateApplianceModel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateApplianceModelPayload",
    "kind": "LinkedField",
    "name": "updateApplianceModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplianceModel",
        "kind": "LinkedField",
        "name": "applianceModel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizedText",
            "kind": "LinkedField",
            "name": "names",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizedText",
            "kind": "LinkedField",
            "name": "descriptions",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partNumbers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pictureUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplianceModel_updateApplianceModel_Mutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplianceModel_updateApplianceModel_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1c1acfe3f4484352ffd32276d79df99b",
    "id": null,
    "metadata": {},
    "name": "ApplianceModel_updateApplianceModel_Mutation",
    "operationKind": "mutation",
    "text": "mutation ApplianceModel_updateApplianceModel_Mutation(\n  $input: UpdateApplianceModelInput!\n) {\n  updateApplianceModel(input: $input) {\n    applianceModel {\n      id\n      handle\n      names {\n        locale\n        text\n      }\n      descriptions {\n        locale\n        text\n      }\n      partNumbers\n      pictureUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c906e7fc82161ed3e8fc15358c22208";

export default node;
