/**
 * @generated SignedSource<<0a7f55a33ffe20c3ea6b12a1d1542ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Application_application_Query$variables = {
  input: string;
};
export type Application_application_Query$data = {
  readonly application: {
    readonly id: string;
    readonly supportedAppliances: ReadonlyArray<{
      readonly deviceId: string;
      readonly name: string;
      readonly tags: ReadonlyArray<string>;
    }>;
  } | null;
};
export type Application_application_Query = {
  response: Application_application_Query$data;
  variables: Application_application_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "input"
      }
    ],
    "concreteType": "Application",
    "kind": "LinkedField",
    "name": "application",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplianceInfo",
        "kind": "LinkedField",
        "name": "supportedAppliances",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Application_application_Query",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Application_application_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3075af7c10ecd49045b1e13630b949d0",
    "id": null,
    "metadata": {},
    "name": "Application_application_Query",
    "operationKind": "query",
    "text": "query Application_application_Query(\n  $input: ID!\n) {\n  application(id: $input) {\n    id\n    supportedAppliances {\n      name\n      deviceId\n      tags\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b35cacc70a3c8bd022c81a293f73e4e";

export default node;
