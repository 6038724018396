import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import type { ModalProps } from "react-bootstrap";

import Button, { ButtonVariant } from "components/Button";

type ConfirmVariant = Extract<ButtonVariant, "solid-primary" | "solid-danger">;

interface Props {
  cancelLabel?: React.ReactNode;
  children: React.ReactNode;
  confirmVariant?: ConfirmVariant;
  confirmLabel: React.ReactNode;
  confirmOnEnter?: boolean;
  disabled?: boolean;
  isConfirming?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  size?: ModalProps["size"];
  title: React.ReactNode;
}

const ConfirmModal = ({
  cancelLabel,
  children,
  confirmVariant = "solid-primary",
  confirmLabel,
  confirmOnEnter = true,
  disabled = false,
  isConfirming = false,
  onCancel,
  onConfirm,
  size = "lg",
  title,
  ...restProps
}: Props): React.ReactElement => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === "Enter" &&
        confirmOnEnter &&
        !isConfirming &&
        !disabled
      ) {
        event.preventDefault();
        event.stopPropagation();
        onConfirm();
      }
    },
    [confirmOnEnter, isConfirming, disabled, onConfirm]
  );

  const handleHide = useCallback(() => {
    if (disabled) {
      return;
    }
    if (onCancel) {
      onCancel();
    } else if (!isConfirming) {
      onConfirm();
    }
  }, [onCancel, onConfirm, isConfirming, disabled]);

  return (
    <div onKeyDown={handleKeyDown} {...restProps}>
      <Modal show centered size={size} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {onCancel && (
            <Button
              variant="outline-primary"
              onClick={onCancel}
              data-testid="modal-cancel-button"
            >
              {cancelLabel || (
                <FormattedMessage
                  id="components.ConfirmModal.cancelButton"
                  defaultMessage="Cancel"
                  description="Title for the button to cancel and dismiss a confirmation modal"
                />
              )}
            </Button>
          )}
          <Button
            variant={confirmVariant}
            disabled={disabled || isConfirming}
            loading={isConfirming}
            onClick={onConfirm}
            data-testid="modal-confirm-button"
          >
            {confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export type { ConfirmVariant };

export default ConfirmModal;
