import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay";
import { useMutation, useRelayEnvironment } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import * as images from "assets/images";
import type { DeviceClaim_getDeviceClaimInfo_Query } from "api/__generated__/DeviceClaim_getDeviceClaimInfo_Query.graphql";
import type { DeviceClaim_claimDevice_Mutation } from "api/__generated__/DeviceClaim_claimDevice_Mutation.graphql";
import { Route, useNavigate } from "Navigation";
import Button from "components/Button";
import DeviceClaimForm, { DeviceClaimDraft } from "components/DeviceClaimForm";
import Image from "components/Image";
import Page from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import { useTenantConfig } from "contexts/TenantConfig";

const GET_DEVICE_CLAIM_INFO_QUERY = graphql`
  query DeviceClaim_getDeviceClaimInfo_Query(
    $deviceId: String
    $token: String
  ) {
    getDeviceClaimInfo(deviceId: $deviceId, token: $token) {
      deviceId
      applianceSerial
      appliancePartNumber
    }
  }
`;

const CLAIM_DEVICE_MUTATION = graphql`
  mutation DeviceClaim_claimDevice_Mutation($input: ClaimDeviceInput!) {
    claimDevice(input: $input) {
      appliance {
        id
      }
    }
  }
`;

const DeviceClaimSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.devices} />
    </Stack>
  );
};

const initialDraft: DeviceClaimDraft = {
  applianceName: "",
  applianceSerial: "",
  deviceId: "",
  token: "",
};

type DeviceClaimInfo = {
  deviceId: string;
  applianceSerial: string | null;
  appliancePartNumber: string | null;
};

const DeviceClaim = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const tokenParam = urlSearchParams.get("token");
  const [draft, setDraft] = useState({
    deviceClaim: { ...initialDraft, token: tokenParam },
    isValid: false,
  });
  // TODO: use usePreloadedQuery with Suspense and ErrorBoundary instead of
  // manually loading the deviceClaimInfo query and tracking its loading state.
  // Only the fields interested by the query should be wrapped in Suspense.
  // Thus, splitting the flow for Device Claiming with token and the flow for
  // Device Claiming with deviceId into two forms can be considered.
  const [
    deviceClaimInfo,
    setDeviceClaimInfo,
  ] = useState<DeviceClaimInfo | null>(null);
  const [isGettingDeviceClaimInfo, setIsGettingDeviceClaimInfo] = useState(
    false
  );
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const [
    claimDevice,
    isClaimingDevice,
  ] = useMutation<DeviceClaim_claimDevice_Mutation>(CLAIM_DEVICE_MUTATION);
  const tenantConfig = useTenantConfig();
  const relayEnvironment = useRelayEnvironment();

  const handleClaimDevice = useCallback(() => {
    claimDevice({
      variables: { input: draft.deviceClaim },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const applianceId = data.claimDevice?.appliance.id;
        if (applianceId) {
          navigate({ route: Route.appliancesEdit, params: { applianceId } });
        } else {
          navigate({ route: Route.appliances });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.DeviceClaim.claimErrorFeedback"
            defaultMessage="Could not claim the appliance, please try again."
            description="Feedback for unknown claim error in the DeviceClaim page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [claimDevice, navigate, draft.deviceClaim]);

  const handleGetDeviceClaimInfo = useMemo(
    () =>
      _.debounce(
        (deviceRef: Pick<DeviceClaimDraft, "token" | "deviceId">) => {
          const variables = tenantConfig.deviceClaimWithTokenChallenge
            ? { token: deviceRef.token, deviceId: null }
            : { token: null, deviceId: deviceRef.deviceId };
          fetchQuery<DeviceClaim_getDeviceClaimInfo_Query>(
            relayEnvironment,
            GET_DEVICE_CLAIM_INFO_QUERY,
            variables
          ).subscribe({
            start: () => {
              setIsGettingDeviceClaimInfo(true);
            },
            complete: () => {
              setIsGettingDeviceClaimInfo(false);
            },
            error: () => {
              setIsGettingDeviceClaimInfo(false);
            },
            next: (data) => {
              setDeviceClaimInfo(data.getDeviceClaimInfo);
            },
          });
        },
        500,
        { leading: true }
      ),
    [relayEnvironment, tenantConfig.deviceClaimWithTokenChallenge]
  );

  const handleFormChange = useCallback(
    (deviceClaim: DeviceClaimDraft, isValid: boolean) => {
      if (tenantConfig.deviceClaimWithImplicitSerial) {
        deviceClaim.applianceSerial = null;
      }
      if (tenantConfig.deviceClaimWithTokenChallenge) {
        deviceClaim.deviceId = null;
      }
      setDraft({ deviceClaim, isValid });
      const hasChangedToken = draft.deviceClaim.token !== deviceClaim.token;
      const hasChangedDeviceId =
        draft.deviceClaim.deviceId !== deviceClaim.deviceId;
      const hasChangedDeviceRef = tenantConfig.deviceClaimWithTokenChallenge
        ? hasChangedToken
        : hasChangedDeviceId;
      const shouldGetClaimInfo =
        (tenantConfig.deviceClaimWithTokenChallenge && hasChangedToken) ||
        (tenantConfig.deviceClaimWithImplicitSerial && hasChangedDeviceRef);
      if (shouldGetClaimInfo) {
        setErrorFeedback(null);
        setDeviceClaimInfo(null);
        handleGetDeviceClaimInfo(deviceClaim);
      }
    },
    [tenantConfig, draft, handleGetDeviceClaimInfo]
  );

  useEffect(() => {
    if (tenantConfig.deviceClaimWithTokenChallenge && tokenParam) {
      handleGetDeviceClaimInfo({ token: tokenParam, deviceId: null });
    }
  }, [
    tenantConfig.deviceClaimWithTokenChallenge,
    tokenParam,
    handleGetDeviceClaimInfo,
  ]);

  let formValue = {
    applianceName: draft.deviceClaim.applianceName,
    applianceSerial: draft.deviceClaim.applianceSerial,
    deviceId: draft.deviceClaim.deviceId,
    token: draft.deviceClaim.token,
  };
  if (deviceClaimInfo?.applianceSerial) {
    formValue.applianceSerial = deviceClaimInfo.applianceSerial;
  }

  const isValidDeviceRef = deviceClaimInfo != null && !isGettingDeviceClaimInfo;

  const canSubmitClaim = draft.isValid && isValidDeviceRef && !isClaimingDevice;

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.DeviceClaim.title"
          defaultMessage="Claim Appliance"
          description="Title for the DeviceClaim page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <DeviceClaimForm
          value={formValue}
          onChange={handleFormChange}
          appliancePartNumber={deviceClaimInfo?.appliancePartNumber}
          readonlyApplianceSerial={tenantConfig.deviceClaimWithImplicitSerial}
          showDeviceId={!tenantConfig.deviceClaimWithTokenChallenge}
          validDeviceId={
            !tenantConfig.deviceClaimWithTokenChallenge && isValidDeviceRef
          }
          showToken={tenantConfig.deviceClaimWithTokenChallenge}
          validToken={
            tenantConfig.deviceClaimWithTokenChallenge && isValidDeviceRef
          }
          hasLoadingDeviceRef={isGettingDeviceClaimInfo}
        />
        <div className="mt-3 d-flex justify-content-end flex-column flex-md-row">
          <Button
            disabled={!canSubmitClaim}
            loading={isClaimingDevice}
            onClick={handleClaimDevice}
          >
            <FormattedMessage
              id="pages.DeviceClaim.claimApplianceButton"
              defaultMessage="Claim Appliance"
            />
          </Button>
        </div>
      </Stack>
      <SidebarContent>
        <DeviceClaimSidebar />
      </SidebarContent>
    </Page>
  );
};

export default DeviceClaim;
