/**
 * @generated SignedSource<<89b73dbd86e2c441f18964456a94044b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClientAssignee_getClients_Query$variables = {};
export type ClientAssignee_getClients_Query$data = {
  readonly clients: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type ClientAssignee_getClients_Query = {
  response: ClientAssignee_getClients_Query$data;
  variables: ClientAssignee_getClients_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "clients",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAssignee_getClients_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientAssignee_getClients_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9d9e8ec7a519b96d7a436a20bad85b89",
    "id": null,
    "metadata": {},
    "name": "ClientAssignee_getClients_Query",
    "operationKind": "query",
    "text": "query ClientAssignee_getClients_Query {\n  clients {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "199516e9f639b9d2af3fdacc08e7b161";

export default node;
