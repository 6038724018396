/**
 * @generated SignedSource<<0b0f7381e8061f7c1a32133a54499f51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateApplianceModelInput = {
  descriptions?: ReadonlyArray<LocalizedTextInput> | null;
  handle: string;
  names?: ReadonlyArray<LocalizedTextInput> | null;
  partNumbers: ReadonlyArray<string>;
  pictureFile?: any | null;
  pictureUrl?: string | null;
};
export type LocalizedTextInput = {
  locale: string;
  text: string;
};
export type ApplianceModelCreate_createApplianceModel_Mutation$variables = {
  input: CreateApplianceModelInput;
};
export type ApplianceModelCreate_createApplianceModel_Mutation$data = {
  readonly createApplianceModel: {
    readonly applianceModel: {
      readonly descriptions: ReadonlyArray<{
        readonly locale: string;
        readonly text: string;
      }>;
      readonly handle: string;
      readonly id: string;
      readonly names: ReadonlyArray<{
        readonly locale: string;
        readonly text: string;
      }>;
      readonly partNumbers: ReadonlyArray<string>;
      readonly pictureUrl: string | null;
    };
  } | null;
};
export type ApplianceModelCreate_createApplianceModel_Mutation = {
  response: ApplianceModelCreate_createApplianceModel_Mutation$data;
  variables: ApplianceModelCreate_createApplianceModel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateApplianceModelPayload",
    "kind": "LinkedField",
    "name": "createApplianceModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplianceModel",
        "kind": "LinkedField",
        "name": "applianceModel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizedText",
            "kind": "LinkedField",
            "name": "names",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizedText",
            "kind": "LinkedField",
            "name": "descriptions",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partNumbers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pictureUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplianceModelCreate_createApplianceModel_Mutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplianceModelCreate_createApplianceModel_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "91ed97348133acad4bde1c12d7a09677",
    "id": null,
    "metadata": {},
    "name": "ApplianceModelCreate_createApplianceModel_Mutation",
    "operationKind": "mutation",
    "text": "mutation ApplianceModelCreate_createApplianceModel_Mutation(\n  $input: CreateApplianceModelInput!\n) {\n  createApplianceModel(input: $input) {\n    applianceModel {\n      id\n      handle\n      names {\n        locale\n        text\n      }\n      descriptions {\n        locale\n        text\n      }\n      partNumbers\n      pictureUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0f93599e0a9b81299ba83a11ebb9b3c";

export default node;
