/**
 * @generated SignedSource<<5c3ee4b0bc7e1954a2fd785feffeb294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplianceModelDetails$data = {
  readonly descriptions: ReadonlyArray<{
    readonly locale: string;
    readonly text: string;
  }>;
  readonly handle: string;
  readonly names: ReadonlyArray<{
    readonly locale: string;
    readonly text: string;
  }>;
  readonly partNumbers: ReadonlyArray<string>;
  readonly " $fragmentType": "ApplianceModelDetails";
};
export type ApplianceModelDetails$key = {
  readonly " $data"?: ApplianceModelDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplianceModelDetails">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplianceModelDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalizedText",
      "kind": "LinkedField",
      "name": "names",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalizedText",
      "kind": "LinkedField",
      "name": "descriptions",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partNumbers",
      "storageKey": null
    }
  ],
  "type": "ApplianceModel",
  "abstractKey": null
};
})();

(node as any).hash = "e79a7947ac7da866ea09876dd637d62e";

export default node;
