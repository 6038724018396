import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import axios from "axios";
import { Capacitor } from "@capacitor/core";

import Spinner from "components/Spinner";
import {
  CookieAttributes,
  getCookie,
  setCookie,
  removeCookie,
} from "services/cookies";

const getBaseBackendUrl = () => {
  const appMetatag: HTMLElement = document.head.querySelector(
    "[name=application-name]"
  )!;
  return new URL(appMetatag.dataset?.backendUrl || "http://localhost:3000");
};

const loadRedirectTo = async (): Promise<string | null> => {
  return await getCookie("redirectTo");
};

const saveRedirectTo = async (redirectTo: string | null) => {
  const cookieOptions: CookieAttributes = {
    secure: true,
    expires: 1, // 1 day
    sameSite: "Strict",
  };

  if (redirectTo) {
    await setCookie("redirectTo", redirectTo, cookieOptions);
  } else {
    await removeCookie("redirectTo", cookieOptions);
  }
};

const loadAuthToken = async (): Promise<string | null> => {
  return await getCookie("authToken");
};

const saveAuthToken = async (
  token: string | null,
  persistAuth: boolean = false
) => {
  // If expires is undefined, closing the browser/session will delete the cookie
  const cookieOptions: CookieAttributes = {
    secure: true,
    expires: persistAuth ? 365 : undefined,
    sameSite: "Strict",
  };

  if (token) {
    await setCookie("authToken", token, cookieOptions);
  } else {
    await removeCookie("authToken", cookieOptions);
  }
};

const fetchTenantSlug = async (): Promise<string | null> => {
  let hostName = document.location.hostname;
  if (Capacitor.isNativePlatform()) {
    hostName = process.env.REACT_APP_TENANT_DOMAIN_NAME || "localhost";
  }
  return axios
    .get(new URL(`/domains/${hostName}/info`, getBaseBackendUrl()).toString())
    .then((response) => response.data.data.tenant_slug)
    .catch((error) => {
      console.error(error);
      return null;
    });
};

type SessionContextValue = {
  authToken: string | null;
  baseBackendUrl: URL;
  redirectTo: string | null;
  tenantSlug: string | null;
  setAuthToken: (token: string | null, persistAuth?: boolean) => void;
  setRedirectTo: (redirectTo: string | null) => void;
};

const SessionContext = createContext<SessionContextValue | null>(null);

type SessionProviderProps = {
  children: React.ReactNode;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [tenantSlug, setTenantSlug] = useState<string | null>(null);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const baseBackendUrl = useMemo(() => getBaseBackendUrl(), []);

  useEffect(() => {
    const sessionPromises = [
      fetchTenantSlug()
        .then(setTenantSlug)
        .catch(() => setTenantSlug(null)),
      loadAuthToken()
        .then(setAuthToken)
        .catch(() => setAuthToken(null)),
      loadRedirectTo()
        .then(setRedirectTo)
        .catch(() => setRedirectTo(null)),
    ];
    Promise.all(sessionPromises).finally(() => setIsLoadingSession(false));
  }, []);

  const handleSetToken = useCallback((newAuthToken, persistAuth) => {
    saveAuthToken(newAuthToken, persistAuth);
    setAuthToken(newAuthToken);
  }, []);

  const handleSetRedirectTo = useCallback((newRedirectTo) => {
    saveRedirectTo(newRedirectTo);
    setRedirectTo(newRedirectTo);
  }, []);

  const contextValue = useMemo(
    () => ({
      authToken,
      baseBackendUrl,
      redirectTo,
      tenantSlug,
      setAuthToken: handleSetToken,
      setRedirectTo: handleSetRedirectTo,
    }),
    [
      authToken,
      baseBackendUrl,
      redirectTo,
      tenantSlug,
      handleSetToken,
      handleSetRedirectTo,
    ]
  );

  if (isLoadingSession) {
    return (
      <div
        className="vh-100 d-flex justify-content-center align-items-center"
        data-testid="session-loading"
      >
        <Spinner />
      </div>
    );
  }

  if (!tenantSlug) {
    // TODO move the language provider up in the context list
    return (
      <div>
        <h3>Configuration error.</h3>
        <p>Please verify your tenant configuration in the Clea Admin panel.</p>
      </div>
    );
  }

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => {
  const contextValue = useContext(SessionContext);
  if (contextValue == null) {
    throw new Error("SessionContext has not been Provided");
  }
  return contextValue;
};

export { SessionContext, useSession };
export type { SessionContextValue };

export default SessionProvider;
