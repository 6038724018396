import RemoteApp, { isSupportedApp } from "components/RemoteApp";
import type { RemoteAppProps } from "components/RemoteApp";

type ApplianceInfo = {
  name: string;
  deviceId: string;
  tags: string[];
};

type GlobalAppProps = {
  astarteUrl: URL;
  realm: string;
  token: string;
  appliances: ApplianceInfo[];
};

const GlobalApp = ({
  appId,
  appUrl,
  appProps,
}: RemoteAppProps<GlobalAppProps>) => {
  return <RemoteApp appId={appId} appUrl={appUrl} appProps={appProps} />;
};

export { isSupportedApp };

export default GlobalApp;
