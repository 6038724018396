import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";

import MultiSelect from "components/MultiSelect";
import Stack from "components/Stack";

type Role = {
  id: string;
  name: string;
};

interface Data {
  roles: Role[];
  email: string;
  name: string;
}

interface Props {
  className?: string;
  id?: string;
  roles?: Role[];
  value: Data;
  onChange?: (data: Data, isValid: boolean) => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  readOnly?: boolean;
}

const UserForm = ({
  className = "",
  id,
  roles = [],
  value,
  onChange,
  onSubmit,
  readOnly = false,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue =
        target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isValid =
        !!formRef.current?.checkValidity() && newValue.roles.length > 0;
      onChange && onChange(newValue, isValid);
    },
    [value, onChange]
  );

  const handleRolesChange = (newRoles: Role[]) => {
    const newValue = { ...value, roles: newRoles };
    const isValid = !!formRef.current?.checkValidity() && newRoles.length > 0;
    onChange && onChange(newValue, isValid);
  };

  return (
    <Form
      ref={formRef}
      id={id}
      onSubmit={onSubmit}
      className={className}
      data-testid="user-form"
    >
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.UserForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the user form"
            />
          </Form.Label>
          <Form.Control
            name="name"
            value={value.name}
            onChange={handleInputChange}
            readOnly
            plaintext
            required
            placeholder={intl.formatMessage({
              id: "components.UserForm.namePlaceholder",
              defaultMessage: "Type in the user name",
              description: "Placeholder for the name field in the user form",
            })}
            data-testid="user-form-name"
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>
            <FormattedMessage
              id="components.UserForm.emailLabel"
              defaultMessage="Email"
              description="Label for the email field in the user form"
            />
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={value.email}
            onChange={handleInputChange}
            readOnly
            plaintext
            required
            placeholder={intl.formatMessage({
              id: "components.UserForm.emailPlaceholder",
              defaultMessage: "Type in the user email",
              description: "Placeholder for the email field in the user form",
            })}
            data-testid="user-form-email"
          />
        </Form.Group>
        <Form.Group controlId="roles">
          <Form.Label>
            <FormattedMessage
              id="components.UserForm.rolesLabel"
              defaultMessage="Roles"
              description="Label for the roles field in the user form"
            />
          </Form.Label>
          <MultiSelect
            id="roles"
            name="roles"
            required
            selected={value.roles}
            values={roles}
            getValueId={(role) => role.id}
            getValueLabel={(role) => role.name}
            onChange={handleRolesChange}
            disabled={readOnly}
            data-testid="user-form-roles"
          />
        </Form.Group>
      </Stack>
    </Form>
  );
};

export default UserForm;
