import React from "react";
import { useIntl } from "react-intl";

import Icon from "components/Icon";
import "./ConnectionStatus.scss";

interface Props {
  connected: boolean;
  icon?: boolean;
}

const ConnectionStatus = ({ connected, icon = true }: Props) => {
  const intl = useIntl();
  let color = "color-connected";
  let label = intl.formatMessage({
    id: "components.ConnectionStatus.statusConnected",
    defaultMessage: "Connected",
  });
  if (!connected) {
    color = "color-disconnected";
    label = intl.formatMessage({
      id: "components.ConnectionStatus.statusDisconnected",
      defaultMessage: "Disconnected",
    });
  }
  return (
    <div className="d-flex align-items-center connection-status">
      {icon && <Icon icon="circle" className={`me-md-2 ${color}`} />}
      <span className="d-none d-md-inline">{label}</span>
    </div>
  );
};

export default ConnectionStatus;
