import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";

import type { ApplianceModel_getApplianceModel_Query } from "api/__generated__/ApplianceModel_getApplianceModel_Query.graphql";
import type { ApplianceModel_updateApplianceModel_Mutation } from "api/__generated__/ApplianceModel_updateApplianceModel_Mutation.graphql";
import type { ApplianceModel_deleteApplianceModel_Mutation } from "api/__generated__/ApplianceModel_deleteApplianceModel_Mutation.graphql";
import * as images from "assets/images";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import { Link, Route, useNavigate } from "Navigation";
import ApplianceModelForm, {
  ApplianceModelChanges,
} from "forms/ApplianceModel";

const GET_APPLIANCE_MODEL_QUERY = graphql`
  query ApplianceModel_getApplianceModel_Query($id: ID!) {
    applianceModel(id: $id) {
      id
      handle
      names {
        locale
        text
      }
      descriptions {
        locale
        text
      }
      partNumbers
      pictureUrl
    }
  }
`;

const UPDATE_APPLIANCE_MODEL_MUTATION = graphql`
  mutation ApplianceModel_updateApplianceModel_Mutation(
    $input: UpdateApplianceModelInput!
  ) {
    updateApplianceModel(input: $input) {
      applianceModel {
        id
        handle
        names {
          locale
          text
        }
        descriptions {
          locale
          text
        }
        partNumbers
        pictureUrl
      }
    }
  }
`;

const DELETE_APPLIANCE_MODEL_MUTATION = graphql`
  mutation ApplianceModel_deleteApplianceModel_Mutation(
    $input: DeleteApplianceModelInput!
  ) {
    deleteApplianceModel(input: $input) {
      applianceModel {
        id
      }
    }
  }
`;

type ApplianceModelSidebarProps = {
  applianceModel?: ApplianceModel_getApplianceModel_Query["response"]["applianceModel"];
  onDelete?: () => void;
};

const ApplianceModelSidebar = ({
  applianceModel,
  onDelete,
}: ApplianceModelSidebarProps) => {
  const canDeleteApplianceModels = useCanOneOf(["CAN_DELETE_APPLIANCE_MODELS"]);

  // TODO: use a query to directly get the name in the user's locale
  const handle = applianceModel?.handle;
  const name = applianceModel?.names[0]?.text;
  const image = applianceModel?.pictureUrl || images.devices;
  const title = name || handle;

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      {title && <h4>{title}</h4>}
      <Image src={image} />
      {canDeleteApplianceModels && (
        <>
          <hr />
          <Center>
            <Button variant="solid-danger" onClick={onDelete}>
              <FormattedMessage
                id="pages.ApplianceModel.deleteApplianceModelButton"
                defaultMessage="Delete Appliance Model"
              />
            </Button>
          </Center>
        </>
      )}
    </Stack>
  );
};

interface ApplianceModelContentProps {
  getApplianceModelQuery: PreloadedQuery<ApplianceModel_getApplianceModel_Query>;
}

const ApplianceModelContent = ({
  getApplianceModelQuery,
}: ApplianceModelContentProps) => {
  const { applianceModelId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const canUpdateApplianceModels = useCanOneOf(["CAN_EDIT_APPLIANCE_MODELS"]);

  const applianceModelData = usePreloadedQuery(
    GET_APPLIANCE_MODEL_QUERY,
    getApplianceModelQuery
  );

  // TODO: handle readonly type without mapping to mutable type
  const applianceModel = useMemo(
    () =>
      applianceModelData.applianceModel && {
        ...applianceModelData.applianceModel,
        names: [...applianceModelData.applianceModel.names],
        descriptions: [...applianceModelData.applianceModel.descriptions],
        partNumbers: [...applianceModelData.applianceModel.partNumbers],
      },
    [applianceModelData.applianceModel]
  );

  const [
    updateApplianceModel,
    isCreatingApplianceModel,
  ] = useMutation<ApplianceModel_updateApplianceModel_Mutation>(
    UPDATE_APPLIANCE_MODEL_MUTATION
  );

  const [
    deleteApplianceModel,
    isDeletingApplianceModel,
  ] = useMutation<ApplianceModel_deleteApplianceModel_Mutation>(
    DELETE_APPLIANCE_MODEL_MUTATION
  );

  const handleUpdateApplianceModel = useCallback(
    (applianceModel: ApplianceModelChanges) => {
      updateApplianceModel({
        variables: { input: { applianceModelId, ...applianceModel } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.ApplianceModel.saveErrorFeedback"
              defaultMessage="Could not update the appliance model, please try again."
              description="Feedback for unknown creation error in the ApplianceModel page"
            />
          );
        },
      });
    },
    [updateApplianceModel, applianceModelId]
  );

  const handleDeleteApplianceModel = useCallback(() => {
    deleteApplianceModel({
      variables: { input: { applianceModelId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.applianceModels });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.ApplianceModel.deleteErrorFeedback"
            defaultMessage="Could not delete the appliance model, please try again."
            description="Feedback for unknown deletion error in the ApplianceModel page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const applianceModelId = data.deleteApplianceModel?.applianceModel.id;
        if (applianceModelId) {
          const root = store.getRoot();
          const applianceModels = root.getLinkedRecords("applianceModels");
          if (applianceModels) {
            root.setLinkedRecords(
              applianceModels.filter(
                (applianceModel) =>
                  applianceModel.getDataID() !== applianceModelId
              ),
              "applianceModels"
            );
          }
          store.delete(applianceModelId);
        }
      },
    });
  }, [deleteApplianceModel, navigate, applianceModelId]);

  if (!applianceModel) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.ApplianceModel.applianceModelNotFound.title"
              defaultMessage="Appliance model not found."
              description="Page title for an appliance model not found"
            />
          }
        >
          <Link route={Route.applianceModels}>
            <FormattedMessage
              id="pages.ApplianceModel.applianceModelNotFound.message"
              defaultMessage="Return to the appliance model list"
              description="Page message for an appliance model not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <ApplianceModelSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <Stack gap={3}>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <ApplianceModelForm
        initialData={applianceModel}
        onSubmit={handleUpdateApplianceModel}
        isLoading={isCreatingApplianceModel}
        readOnly={!canUpdateApplianceModels}
        submitLabel={
          <FormattedMessage
            id="pages.ApplianceModel.updateApplianceModelButton"
            defaultMessage="Update Appliance Model"
          />
        }
      />
      <SidebarContent>
        <ApplianceModelSidebar
          applianceModel={applianceModel}
          onDelete={() => setShowDeleteModal(true)}
        />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={applianceModel.handle}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteApplianceModel}
          isDeleting={isDeletingApplianceModel}
          title={
            <FormattedMessage
              id="pages.ApplianceModel.deleteModal.title"
              defaultMessage="Delete Appliance Model"
              description="Title for the confirmation modal to delete an appliance model"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.ApplianceModel.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the appliance model <bold>{applianceModel}</bold>."
              description="Description for the confirmation modal to delete an appliance model"
              values={{
                applianceModel: applianceModel.handle,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </Stack>
  );
};

const ApplianceModel = () => {
  const { applianceModelId = "" } = useParams();

  const [
    getApplianceModelQuery,
    getApplianceModel,
  ] = useQueryLoader<ApplianceModel_getApplianceModel_Query>(
    GET_APPLIANCE_MODEL_QUERY
  );

  const getData = useCallback(() => {
    getApplianceModel({ id: applianceModelId });
  }, [getApplianceModel, applianceModelId]);

  useEffect(getData, [getData]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ApplianceModel.title"
          defaultMessage="Appliance Model Details"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplianceModelSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplianceModelSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getApplianceModelQuery && (
            <ApplianceModelContent
              getApplianceModelQuery={getApplianceModelQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default ApplianceModel;
