/**
 * @generated SignedSource<<e94085f8ab183f582ad4b4605bf91a1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddClientInput = {
  referralCode: string;
};
export type ClientAdd_addClient_Mutation$variables = {
  input: AddClientInput;
};
export type ClientAdd_addClient_Mutation$data = {
  readonly addClient: {
    readonly client: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type ClientAdd_addClient_Mutation = {
  response: ClientAdd_addClient_Mutation$data;
  variables: ClientAdd_addClient_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddClientPayload",
    "kind": "LinkedField",
    "name": "addClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAdd_addClient_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientAdd_addClient_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e3c33f352dd59e9aa311895cdef6c0c",
    "id": null,
    "metadata": {},
    "name": "ClientAdd_addClient_Mutation",
    "operationKind": "mutation",
    "text": "mutation ClientAdd_addClient_Mutation(\n  $input: AddClientInput!\n) {\n  addClient(input: $input) {\n    client {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfa1242c2c94f01198ce305b3993bca9";

export default node;
