import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import * as images from "assets/images";
import type { User_getRoles_Query } from "api/__generated__/User_getRoles_Query.graphql";
import type { User_getUser_Query } from "api/__generated__/User_getUser_Query.graphql";
import type { User_updateUserRoles_Mutation } from "api/__generated__/User_updateUserRoles_Mutation.graphql";
import type { User_deleteUser_Mutation } from "api/__generated__/User_deleteUser_Mutation.graphql";
import { Link, Route, useNavigate } from "Navigation";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tag from "components/Tag";
import UserForm from "components/UserForm";
import { useViewer } from "contexts/Viewer";

type UserDraft = {
  email: string;
  name: string;
  roles: {
    id: string;
    name: string;
  }[];
};

const GET_ROLES_QUERY = graphql`
  query User_getRoles_Query {
    roles {
      id
      name
    }
  }
`;

const GET_USER_QUERY = graphql`
  query User_getUser_Query($id: ID!) {
    user(id: $id) {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

const UPDATE_USER_ROLES_MUTATION = graphql`
  mutation User_updateUserRoles_Mutation($input: UpdateUserRolesInput!) {
    updateUserRoles(input: $input) {
      user {
        id
        roles {
          id
          name
        }
      }
    }
  }
`;

const DELETE_USER_MUTATION = graphql`
  mutation User_deleteUser_Mutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`;

type UserSidebarProps = {
  user?: User_getUser_Query["response"]["user"];
  onDelete?: () => void;
};

const UserSidebar = ({ user, onDelete }: UserSidebarProps) => {
  const { userId: viewerId } = useViewer();
  const canDeleteUsers = useCanOneOf(["CAN_DELETE_USERS"]);
  const canDeleteUser = canDeleteUsers && user != null && user.id !== viewerId;

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      {user && (
        <div>
          <h4>{user.name}</h4>
          <p className="text-muted">{user.email}</p>
        </div>
      )}
      <Image fallbackSrc={images.profile} />
      {user && (
        <div>
          {user.roles.map((role) => (
            <Tag className="mb-1 me-1" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </div>
      )}
      {canDeleteUser && (
        <>
          <hr />
          <Center>
            <Button variant="solid-danger" onClick={onDelete}>
              <FormattedMessage
                id="pages.User.deleteUserButton"
                defaultMessage="Delete User"
              />
            </Button>
          </Center>
        </>
      )}
    </Stack>
  );
};

const userToUserInput = (
  user: {
    email: string;
    name: string;
    roles: readonly {
      readonly id: string;
      readonly name: string;
    }[];
  } | null
) => {
  return {
    email: user?.email || "",
    name: user?.name || "",
    roles: user?.roles.map((role) => ({ id: role.id, name: role.name })) || [],
  };
};

interface UserContentProps {
  getRolesQuery: PreloadedQuery<User_getRoles_Query>;
  getUserQuery: PreloadedQuery<User_getUser_Query>;
}

const UserContent = ({ getRolesQuery, getUserQuery }: UserContentProps) => {
  const { userId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const viewer = useViewer();
  const rolesData = usePreloadedQuery(GET_ROLES_QUERY, getRolesQuery);
  const userData = usePreloadedQuery(GET_USER_QUERY, getUserQuery);
  const canManageUserRoles = useCanOneOf(["CAN_MANAGE_USER_ROLES"]);
  const [
    updateUserRoles,
    isUpdatingUserRoles,
  ] = useMutation<User_updateUserRoles_Mutation>(UPDATE_USER_ROLES_MUTATION);
  const [deleteUser, isDeletingUser] = useMutation<User_deleteUser_Mutation>(
    DELETE_USER_MUTATION
  );

  // TODO: handle readonly roles type without mapping to mutable type
  const roles = rolesData.roles.map((role) => _.pick(role, ["id", "name"]));
  const user = useMemo(() => userData.user && { ...userData.user }, [
    userData.user,
  ]);

  const [draft, setDraft] = useState({
    user: userToUserInput(user),
    isValid: true,
  });

  const isFormDirty = !_.isEqual(draft.user, userToUserInput(user));

  const handleFormChange = useCallback(
    (user: UserDraft, isValid: boolean) => setDraft({ user, isValid }),
    []
  );

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!draft.isValid) {
        return;
      }
      const roleIds = draft.user.roles.map((role) => role.id);
      updateUserRoles({
        variables: { input: { userId, roleIds } },
        onCompleted(data, errors) {
          if (errors) {
            const updateErrorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(updateErrorFeedback);
          }
          if (userId === viewer.userId) {
            viewer.refresh();
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.User.updateUserErrorFeedback"
              defaultMessage="Could not update the user, please try again."
              description="Feedback for unknown error while updating a user"
            />
          );
        },
      });
    },
    [updateUserRoles, userId, draft.user, draft.isValid, viewer]
  );

  const handleFormReset = useCallback(() => {
    setDraft({
      user: userToUserInput(user),
      isValid: true,
    });
  }, [user]);

  const handleDeleteUser = useCallback(() => {
    deleteUser({
      variables: { input: { userId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.users });
      },
      onError() {
        setErrorFeedback(
          <FormattedMessage
            id="pages.User.deleteErrorFeedback"
            defaultMessage="Could not delete the user, please try again."
            description="Feedback for unknown deletion error in the User page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const userId = data?.deleteUser?.user.id;
        if (userId) {
          const root = store.getRoot();
          const users = root.getLinkedRecords("users");
          if (users) {
            root.setLinkedRecords(
              users.filter((user) => user.getDataID() !== userId),
              "users"
            );
          }
        }
      },
    });
  }, [deleteUser, userId, navigate]);

  useEffect(() => {
    setDraft({
      user: userToUserInput(user),
      isValid: true,
    });
  }, [user]);

  if (!user) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.user.userNotFound.title"
              defaultMessage="User not found."
              description="Page title for a user not found"
            />
          }
        >
          <Link route={Route.users}>
            <FormattedMessage
              id="pages.user.userNotFound.message"
              defaultMessage="Return to the user list"
              description="Page message for a user not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <UserSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <UserForm
          id="user-form"
          roles={roles}
          value={draft.user}
          onChange={handleFormChange}
          onSubmit={handleFormSubmit}
          readOnly={!canManageUserRoles}
        />
        {canManageUserRoles && (
          <div className="mt-3 d-flex justify-content-end flex-column flex-md-row gap-2">
            <Button
              type="submit"
              form="user-form"
              className="order-md-last"
              disabled={!draft.isValid || !isFormDirty || isUpdatingUserRoles}
              loading={isUpdatingUserRoles}
            >
              <FormattedMessage
                id="components.UserForm.updateUserButton"
                defaultMessage="Update User"
              />
            </Button>
            <Button
              variant="outline-primary"
              disabled={!isFormDirty}
              onClick={handleFormReset}
            >
              <FormattedMessage
                id="components.UserForm.resetFormButton"
                defaultMessage="Reset"
              />
            </Button>
          </div>
        )}
      </Stack>
      <SidebarContent>
        <UserSidebar user={user} onDelete={() => setShowDeleteModal(true)} />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={user.email}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteUser}
          isDeleting={isDeletingUser}
          title={
            <FormattedMessage
              id="pages.User.deleteModal.title"
              defaultMessage="Delete User from Organization"
              description="Title for the confirmation modal to delete a user from an organization"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.User.deleteModal.description"
              defaultMessage="The user <bold>{user}</bold> will be deleted."
              description="Description for the confirmation modal to delete a user from an organization"
              values={{
                user: user.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </>
  );
};

const User = () => {
  const { userId = "" } = useParams();

  const [getRolesQuery, getRoles] = useQueryLoader<User_getRoles_Query>(
    GET_ROLES_QUERY
  );

  const [getUserQuery, getUser] = useQueryLoader<User_getUser_Query>(
    GET_USER_QUERY
  );

  useEffect(() => {
    getRoles({});
    getUser({ id: userId });
  }, [getRoles, getUser, userId]);

  return (
    <Page
      title={
        <FormattedMessage id="pages.User.title" defaultMessage="User Details" />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <UserSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <UserSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => getUser({ id: userId })}
        >
          {getRolesQuery && getUserQuery && (
            <UserContent
              getRolesQuery={getRolesQuery}
              getUserQuery={getUserQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default User;
