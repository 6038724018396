import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

import type { RegisterWithInvite_registerOrganizationUser_Mutation } from "api/__generated__/RegisterWithInvite_registerOrganizationUser_Mutation.graphql";
import type { RegisterWithInvite_login_Mutation } from "api/__generated__/RegisterWithInvite_login_Mutation.graphql";
import { useSession } from "contexts/Session";
import { Route, useNavigate } from "Navigation";
import { useTenantConfig } from "contexts/TenantConfig";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import InputPassword from "components/InputPassword";
import PrivacyPolicyModal from "components/PrivacyPolicyModal";
import Stack from "components/Stack";
import TermsAndConditionsModal from "components/TermsAndConditionsModal";

const LOGIN_MUTATION = graphql`
  mutation RegisterWithInvite_login_Mutation($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

const REGISTER_WITH_INVITE_MUTATION = graphql`
  mutation RegisterWithInvite_registerOrganizationUser_Mutation(
    $input: RegisterOrganizationUserInput!
  ) {
    registerOrganizationUser(input: $input) {
      user {
        name
        email
      }
    }
  }
`;

interface FormData {
  userName: string;
  userPassword: string;
  acceptPrivacyPolicy: boolean;
  acceptTermsAndConditions: boolean;
}

const initialFormData: FormData = {
  userName: "",
  userPassword: "",
  acceptPrivacyPolicy: false,
  acceptTermsAndConditions: false,
};

const RegisterWithInvite = () => {
  const tenantConfig = useTenantConfig();
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const { setAuthToken } = useSession();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const email = urlSearchParams.get("email") || "";
  const organizationName = urlSearchParams.get("organizationName") || "";
  const token = urlSearchParams.get("token") || "";

  const [
    registerOrganizationUser,
    isRegisteringOrganizationUser,
  ] = useMutation<RegisterWithInvite_registerOrganizationUser_Mutation>(
    REGISTER_WITH_INVITE_MUTATION
  );

  const [login, isLoggingIn] = useMutation<RegisterWithInvite_login_Mutation>(
    LOGIN_MUTATION
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const user = {
        name: formData.userName,
        credential: {
          email,
          password: formData.userPassword,
        },
        acceptPrivacyPolicy: formData.acceptPrivacyPolicy,
        acceptedPrivacyPolicyId: tenantConfig.latestPrivacyPolicy.id,
        acceptTermsAndConditions: formData.acceptTermsAndConditions,
        acceptedTermsAndConditionsId: tenantConfig.latestTermsAndConditions.id,
      };
      registerOrganizationUser({
        variables: { input: { token, user, recaptchaResponse } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          login({
            variables: {
              input: {
                email,
                password: formData.userPassword,
              },
            },
            onCompleted(data, errors) {
              if (errors) {
                const errorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                return setErrorFeedback(errorFeedback);
              }
              const authToken = data.login?.token || null;
              if (authToken) {
                // FIXME this ignores the "keep me logged in" feature
                setAuthToken(authToken);
              } else {
                navigate({ route: Route.login });
              }
            },
            onError(error) {
              navigate({ route: Route.login });
            },
          });
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.RegisterWithInvite.form.loadingErrorFeedback"
              defaultMessage="Could not register, please try again."
              description="Feedback for unknown loading error in the RegisterWithInvite page"
            />
          );
        },
      });
    },
    [
      formData,
      registerOrganizationUser,
      login,
      setAuthToken,
      navigate,
      email,
      token,
      recaptchaResponse,
      tenantConfig.latestPrivacyPolicy.id,
      tenantConfig.latestTermsAndConditions.id,
    ]
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      setFormData((data) => ({ ...data, [field]: value }));
    },
    []
  );

  const isRegistering = isRegisteringOrganizationUser || isLoggingIn;
  const showCaptcha = !!tenantConfig.authConfig.recaptchaSiteKey;
  const isMissingCaptcha = showCaptcha && !recaptchaResponse;
  const acceptedLegalDocs =
    formData.acceptPrivacyPolicy && formData.acceptTermsAndConditions;
  const canRegister = acceptedLegalDocs && !isMissingCaptcha && !isRegistering;

  return (
    <AuthPage>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <div>
        <p className="text-center">
          <FormattedMessage
            id="pages.RegisterWithInvite.inviteMessage"
            defaultMessage="You have been invited to join {organizationName}."
            description="Invitation message to join the organization in the RegisterWithInvite page"
            values={{ organizationName }}
          />
        </p>
      </div>
      <Form
        className="mt-4"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Stack gap={3}>
          <Form.Group controlId="userEmail">
            <Form.Control
              value={email}
              onChange={handleInputChange}
              required
              readOnly
              type="email"
              placeholder={intl.formatMessage({
                id: "pages.RegisterWithInvite.form.userEmailPlaceholder",
                defaultMessage: "Email",
                description:
                  "Placeholder for the user email field in the RegisterWithInvite page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.RegisterWithInvite.form.invalidUserEmailFeedback"
                defaultMessage="Please provide a valid user email."
                description="Feedback for invalid user email field in the RegisterWithInvite page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userName">
            <Form.Control
              value={formData.userName}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.RegisterWithInvite.form.userNamePlaceholder",
                defaultMessage: "Name",
                description:
                  "Placeholder for the user name field in the RegisterWithInvite page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.RegisterWithInvite.form.invalidUserNameFeedback"
                defaultMessage="Please provide a user name."
                description="Feedback for invalid user name field in the RegisterWithInvite page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userPassword">
            <InputPassword
              value={formData.userPassword}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.RegisterWithInvite.form.userPasswordPlaceholder",
                defaultMessage: "Password",
                description:
                  "Placeholder for the user password field in the RegisterWithInvite page",
              })}
            >
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.RegisterWithInvite.form.invalidUserPasswordFeedback"
                  defaultMessage="Please provide a password."
                  description="Feedback for invalid user password field in the RegisterWithInvite page"
                />
              </Form.Control.Feedback>
            </InputPassword>
          </Form.Group>
          <Form.Group controlId="acceptPrivacyPolicy">
            <Form.Check
              type="checkbox"
              checked={formData.acceptPrivacyPolicy}
              onChange={handleInputChange}
              label={intl.formatMessage(
                {
                  id: "pages.RegisterWithInvite.form.acceptPrivacyPolicyLabel",
                  defaultMessage: "Accept <link>Privacy Policy</link>",
                  description:
                    "Label for the Privacy Policy acceptance field in the RegisterWithInvite page",
                },
                {
                  link: (chunks: React.ReactNode) => (
                    <Button
                      variant="link"
                      onClick={() => setShowPrivacyPolicyModal(true)}
                    >
                      {chunks}
                    </Button>
                  ),
                }
              )}
            />
          </Form.Group>
          <Form.Group controlId="acceptTermsAndConditions">
            <Form.Check
              type="checkbox"
              checked={formData.acceptTermsAndConditions}
              onChange={handleInputChange}
              label={intl.formatMessage(
                {
                  id:
                    "pages.RegisterWithInvite.form.acceptTermsAndConditionsLabel",
                  defaultMessage: "Accept <link>Terms & Conditions</link>",
                  description:
                    "Label for the Terms and Conditions acceptance field in the Register page",
                },
                {
                  link: (chunks: React.ReactNode) => (
                    <Button
                      variant="link"
                      onClick={() => setShowTermsAndConditionsModal(true)}
                    >
                      {chunks}
                    </Button>
                  ),
                }
              )}
            />
          </Form.Group>
          {tenantConfig.authConfig.recaptchaSiteKey && (
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                sitekey={tenantConfig.authConfig.recaptchaSiteKey}
                onChange={setRecaptchaResponse}
              />
            </div>
          )}
          <Button
            type="submit"
            className="w-100 mt-3"
            disabled={!canRegister}
            loading={isRegistering}
          >
            <FormattedMessage
              id="pages.RegisterWithInvite.form.registerButton"
              defaultMessage="Accept invitation"
              description="Title for the Register button in the RegisterWithInvite Page"
            />
          </Button>
        </Stack>
        {showPrivacyPolicyModal && (
          <PrivacyPolicyModal
            onClose={() => setShowPrivacyPolicyModal(false)}
          />
        )}
        {showTermsAndConditionsModal && (
          <TermsAndConditionsModal
            onClose={() => setShowTermsAndConditionsModal(false)}
          />
        )}
      </Form>
    </AuthPage>
  );
};

export default RegisterWithInvite;
