/**
 * @generated SignedSource<<e3eb73003f6c432ff07d364514a5612e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateApplianceInput = {
  appliance: ApplianceInput;
  device: DeviceInput;
};
export type ApplianceInput = {
  name: string;
  serial: string;
};
export type DeviceInput = {
  deviceId: string;
};
export type ApplianceRegister_createAppliance_Mutation$variables = {
  input: CreateApplianceInput;
};
export type ApplianceRegister_createAppliance_Mutation$data = {
  readonly createAppliance: {
    readonly appliance: {
      readonly id: string;
    };
  } | null;
};
export type ApplianceRegister_createAppliance_Mutation = {
  response: ApplianceRegister_createAppliance_Mutation$data;
  variables: ApplianceRegister_createAppliance_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAppliancePayload",
    "kind": "LinkedField",
    "name": "createAppliance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplianceRegister_createAppliance_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplianceRegister_createAppliance_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a48b7b7cbff6a8eec3ea41fea93a5053",
    "id": null,
    "metadata": {},
    "name": "ApplianceRegister_createAppliance_Mutation",
    "operationKind": "mutation",
    "text": "mutation ApplianceRegister_createAppliance_Mutation(\n  $input: CreateApplianceInput!\n) {\n  createAppliance(input: $input) {\n    appliance {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "797a1282f2d43c0706fdb7761fc4c87e";

export default node;
