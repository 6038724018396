/**
 * @generated SignedSource<<bb9eb252ef29a4e490da1ef478687ca4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeviceClaim_getDeviceClaimInfo_Query$variables = {
  deviceId?: string | null;
  token?: string | null;
};
export type DeviceClaim_getDeviceClaimInfo_Query$data = {
  readonly getDeviceClaimInfo: {
    readonly appliancePartNumber: string | null;
    readonly applianceSerial: string | null;
    readonly deviceId: string;
  };
};
export type DeviceClaim_getDeviceClaimInfo_Query = {
  response: DeviceClaim_getDeviceClaimInfo_Query$data;
  variables: DeviceClaim_getDeviceClaimInfo_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "DeviceClaimInfo",
    "kind": "LinkedField",
    "name": "getDeviceClaimInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "applianceSerial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appliancePartNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceClaim_getDeviceClaimInfo_Query",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceClaim_getDeviceClaimInfo_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72a55fb7dbdae25b7b79e7141f18856d",
    "id": null,
    "metadata": {},
    "name": "DeviceClaim_getDeviceClaimInfo_Query",
    "operationKind": "query",
    "text": "query DeviceClaim_getDeviceClaimInfo_Query(\n  $deviceId: String\n  $token: String\n) {\n  getDeviceClaimInfo(deviceId: $deviceId, token: $token) {\n    deviceId\n    applianceSerial\n    appliancePartNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "9da9e0f7933f43b1e06730aff1e1b7f3";

export default node;
