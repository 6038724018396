import { Suspense, useEffect, useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { useGlobalApps } from "contexts/GlobalApps";
import type { GlobalApp } from "contexts/GlobalApps";

import type { Application_application_Query } from "api/__generated__/Application_application_Query.graphql";
import Result from "components/Result";
import ErrorBoundary from "components/ErrorBoundary";
import GlobalAppContainer from "components/GlobalApp";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_APPLICATION_APPLIANCES = graphql`
  query Application_application_Query($input: ID!) {
    application(id: $input) {
      id
      supportedAppliances {
        name
        deviceId
        tags
      }
    }
  }
`;

type ApplicationSidebarProps = {
  application: GlobalApp;
};

const ApplicationSidebar = ({ application }: ApplicationSidebarProps) => {
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <h4>{application.displayName}</h4>
    </Stack>
  );
};

type ApplicationContentProps = {
  application: GlobalApp;
  getApplicationAppliancesQuery: PreloadedQuery<Application_application_Query>;
};
const ApplicationContent = ({
  application,
  getApplicationAppliancesQuery,
}: ApplicationContentProps) => {
  const appUrl = useMemo(() => new URL(application.sourceUrl), [application]);
  const applicationAppliancesData = usePreloadedQuery(
    GET_APPLICATION_APPLIANCES,
    getApplicationAppliancesQuery
  );
  const appAppliances =
    applicationAppliancesData?.application?.supportedAppliances || [];
  const appProps = useMemo(() => {
    const readWriteAppliances = appAppliances.map((appliance) => ({
      name: appliance.name,
      deviceId: appliance.deviceId,
      tags: [...appliance.tags],
    }));

    return {
      token: application.authToken,
      astarteUrl: new URL(application.baseApiUrl),
      realm: application.realmName,
      appliances: readWriteAppliances,
    };
  }, [application, appAppliances]);

  return (
    <GlobalAppContainer
      appId={application.id}
      appUrl={appUrl}
      appProps={appProps}
    />
  );
};

const Application = () => {
  const { applicationSlug = "" } = useParams();
  const { applications } = useGlobalApps();
  const application = useMemo<GlobalApp | null>(() => {
    return (
      (applications &&
        applications.find(
          (application) => application.slug === applicationSlug
        )) ||
      null
    );
  }, [applications, applicationSlug]);
  const [
    getApplicationAppliancesQuery,
    getApplicationAppliances,
  ] = useQueryLoader<Application_application_Query>(GET_APPLICATION_APPLIANCES);

  useEffect(() => {
    if (application) {
      getApplicationAppliances({ input: application.id });
    }
  }, [application, getApplicationAppliances]);

  if (!application) {
    return (
      <Page>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.application.applicationNotFound.title"
              defaultMessage="Application not found."
              description="Page title for an application not found"
            />
          }
        ></Result.NotFound>
      </Page>
    );
  }

  return (
    <Page>
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplicationSidebar application={application} />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplicationSidebar application={application} />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getApplicationAppliances({ input: application.id });
          }}
        >
          {getApplicationAppliancesQuery && (
            <ApplicationContent
              application={application}
              getApplicationAppliancesQuery={getApplicationAppliancesQuery}
            />
          )}
          <SidebarContent>
            <ApplicationSidebar application={application} />
          </SidebarContent>
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Application;
