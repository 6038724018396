import { useCallback, useEffect, useState } from "react";
import RBFigure from "react-bootstrap/Figure";

import * as images from "assets/images";

interface Props {
  alt?: string;
  className?: string;
  fallbackSrc?: string;
  src?: string;
  style?: React.CSSProperties;
}

const Figure = ({
  alt,
  className = "",
  fallbackSrc = images.placeholder,
  src,
  style,
}: Props) => {
  const [imageSrc, setImageSrc] = useState(src || fallbackSrc);

  const handleError = useCallback(() => {
    setImageSrc(fallbackSrc);
  }, [fallbackSrc]);

  useEffect(() => {
    setImageSrc(src || fallbackSrc);
  }, [src, fallbackSrc]);

  return (
    <RBFigure className={className}>
      <RBFigure.Image
        alt={alt}
        className="rounded w-100"
        fluid
        src={imageSrc}
        onError={handleError}
        style={style}
      />
    </RBFigure>
  );
};

export default Figure;
