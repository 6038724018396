import "./PreformattedText.css";

type PreformattedTextProps = {
  children: React.ReactNode;
};

const PreformattedText = ({ children }: PreformattedTextProps) => {
  return <div className="preserve-white-spaces">{children}</div>;
};

export default PreformattedText;
