import { createContext, useContext, useMemo, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import {
  Environment,
  GraphQLResponse,
  GraphQLSingularResponse,
  Network,
  Observable,
  PayloadError,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";
// @ts-ignore TODO: remove this when fixed, see https://github.com/absinthe-graphql/absinthe-socket/issues/43
import { createSubscriber } from "@absinthe/socket-relay";
import * as AbsintheSocket from "@absinthe/socket";
import { Socket as PhoenixSocket } from "phoenix";
import _ from "lodash";

import { useSession } from "contexts/Session";

type GraphQLError = PayloadError & { code?: string };

type APIError = "privacyPolicyError" | "termsAndConditionsError";

type RelayContextValue = {
  apiErrors: Set<APIError>;
};

const RelayContext = createContext<RelayContextValue | null>(null);

const parseAPIError = (graphQLError: GraphQLError): APIError | null => {
  switch (graphQLError.code) {
    case "must_accept_latest_privacy_policy":
      return "privacyPolicyError";
    case "must_accept_latest_terms":
      return "termsAndConditionsError";
    default:
      return null;
  }
};

const parseAPIResponseErrors = (
  response: GraphQLSingularResponse
): APIError[] => {
  if ("errors" in response && Array.isArray(response.errors)) {
    return _.compact(_.get(response, "errors", []).map(parseAPIError));
  }
  return [];
};

const isGraphQLSingularResponse = (
  response: GraphQLResponse
): response is GraphQLSingularResponse => !Array.isArray(response);

const parseAPIResponsesErrors = (response: GraphQLResponse): APIError[] => {
  return isGraphQLSingularResponse(response)
    ? parseAPIResponseErrors(response)
    : response.flatMap(parseAPIResponseErrors);
};

// required to force a re-render of the provider then the environment changes
// FIXME this feels like a hack/workaround. we should better inspect this
// and ask the Relay community about the standard practices to update an environment
let environmentID = 0;

const extractUploadables = (
  initVariables: Record<string, any>
): { variables: Record<string, any>; uploadables: Record<string, File> } => {
  let variables: Record<string, any> = {};
  let uploadables: Record<string, any> = {};
  Object.entries(initVariables).forEach(([key, value]) => {
    if (value instanceof File) {
      variables[key] = key;
      uploadables = {
        ...uploadables,
        [key]: value,
      };
    } else if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null
    ) {
      const extracted = extractUploadables(value);
      variables[key] = extracted.variables;
      uploadables = {
        ...uploadables,
        ...extracted.uploadables,
      };
    } else {
      variables[key] = value;
    }
  });
  return { variables, uploadables };
};

const fetchGraphQL = async (
  query: string | null | undefined,
  variables: Record<string, unknown>,
  authToken: string | null,
  baseBackendUrl: URL,
  tenantSlug: string | null
) => {
  const graphqlUrl = new URL(`tenants/${tenantSlug}/api`, baseBackendUrl);

  const request: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ query, variables }),
  };

  const extracted = extractUploadables(variables);

  if (_.isEmpty(extracted.uploadables)) {
    request.headers = {
      ...(request.headers || {}),
      "Content-Type": "application/json",
    };
    request.body = JSON.stringify({ query, variables });
  } else {
    const formData = new FormData();
    Object.entries(extracted.uploadables).forEach(([key, file]) => {
      formData.append(key, file);
    });
    formData.append("query", query!);
    formData.append("variables", JSON.stringify(extracted.variables));
    request.body = formData;
  }

  const response = await fetch(graphqlUrl.toString(), request);
  return await response.json();
};

type RelayProviderProps = {
  children: React.ReactNode;
};

const RelayProvider = ({ children }: RelayProviderProps) => {
  const { authToken, baseBackendUrl, tenantSlug } = useSession();
  const [apiErrors, setAPIErrors] = useState(new Set<APIError>());

  const fetchRelay = useMemo(() => {
    return async (params: RequestParameters, variables: Variables) =>
      fetchGraphQL(
        params.text,
        variables,
        authToken,
        baseBackendUrl,
        tenantSlug
      ).then((response) => {
        setAPIErrors(new Set(parseAPIResponsesErrors(response)));
        return response;
      });
  }, [authToken, baseBackendUrl, tenantSlug]);

  const subscribe = useMemo(() => {
    if (!authToken) {
      return null;
    }

    const wsUrl = new URL("socket", baseBackendUrl);
    wsUrl.protocol = baseBackendUrl.protocol === "https:" ? "wss:" : "ws:";

    const params = {
      token: authToken,
      tenant_slug: tenantSlug,
    };
    const phoenixSocket = new PhoenixSocket(wsUrl.toString(), { params });
    const legacySubscribe = createSubscriber(
      AbsintheSocket.create(phoenixSocket)
    );

    // @absinthe/socket-relay is outdated so wrap it with a fix
    // see https://github.com/absinthe-graphql/absinthe-socket/issues/44#issuecomment-606349405
    const subscribeFunction = (
      request: any,
      variables: any,
      cacheConfig: any
    ): Observable<GraphQLResponse> => {
      return Observable.create((sink) => {
        const { dispose } = legacySubscribe(request, variables, cacheConfig, {
          onNext: (response: GraphQLResponse) => {
            setAPIErrors(new Set(parseAPIResponsesErrors(response)));
            return sink.next(response);
          },
          onError: sink.error,
          onCompleted: sink.complete,
        });
        return dispose;
      });
    };

    return subscribeFunction;
  }, [authToken, baseBackendUrl, tenantSlug]);

  const relayEnvironment = useMemo(() => {
    environmentID++;
    return new Environment({
      network: subscribe
        ? Network.create(fetchRelay, subscribe)
        : Network.create(fetchRelay),
      store: new Store(new RecordSource()),
    });
  }, [fetchRelay, subscribe]);

  const contextValue = useMemo(() => ({ apiErrors }), [apiErrors]);

  return (
    <RelayEnvironmentProvider
      key={environmentID}
      environment={relayEnvironment}
    >
      <RelayContext.Provider value={contextValue}>
        {children}
      </RelayContext.Provider>
    </RelayEnvironmentProvider>
  );
};

const useAPIErrors = (): Set<APIError> => {
  const contextValue = useContext(RelayContext);
  if (contextValue == null) {
    throw new Error("RelayContext has not been Provided");
  }
  return contextValue.apiErrors;
};

export { fetchGraphQL, RelayContext, useAPIErrors };

export type { RelayContextValue };

export default RelayProvider;
