import { FormattedMessage } from "react-intl";

import ConfirmModal from "components/ConfirmModal";
import PreformattedText from "components/PreformattedText";
import { useTenantConfig } from "contexts/TenantConfig";

type Props = {
  onClose: () => void;
};

const TermsAndConditionsModal = ({ onClose }: Props): React.ReactElement => {
  const { latestTermsAndConditions } = useTenantConfig();

  return (
    <ConfirmModal
      title={
        <FormattedMessage
          id="termsAndConditions.modal.title"
          defaultMessage="Terms & Conditions"
        />
      }
      confirmLabel={
        <FormattedMessage
          id="termsAndConditions.modal.confirmButton"
          defaultMessage="OK"
        />
      }
      onConfirm={onClose}
    >
      <PreformattedText>{latestTermsAndConditions.text}</PreformattedText>
    </ConfirmModal>
  );
};

export default TermsAndConditionsModal;
