import { useCallback } from "react";
import { generatePath as routerGeneratePath, matchPath } from "react-router";
import { useNavigate as useRouterNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import type { LinkProps as RouterLinkProps } from "react-router-dom";

enum Route {
  appliances = "/appliances",
  appliancesNew = "/appliances/new",
  appliancesEdit = "/appliances/:applianceId/edit",
  applianceModels = "/appliance-models",
  applianceModelsNew = "/appliance-models/new",
  applianceModelsEdit = "/appliance-models/:applianceModelId/edit",
  devicesClaim = "/appliances/claim",
  clients = "/clients",
  clientsNew = "/clients/add",
  clientsEdit = "/clients/:clientId/edit",
  users = "/users",
  usersEdit = "/users/:userId/edit",
  usersInvite = "/users/invite",
  roles = "/roles",
  rolesNew = "/roles/new",
  rolesEdit = "/roles/:roleId/edit",
  profile = "/profile",
  confirmEmail = "/confirm-email",
  legalTerms = "/legal-terms",
  login = "/login",
  logout = "/logout",
  register = "/register",
  registerWithInvite = "/register-with-invite",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
  application = "/applications/:applicationSlug",
}

const matchingRoute = (path: string) => {
  return Object.values(Route).find((route) => matchPath(route, path) != null);
};

type ParametricRoute =
  | { route: Route.appliances }
  | { route: Route.appliancesNew }
  | { route: Route.appliancesEdit; params: { applianceId: string } }
  | { route: Route.applianceModels }
  | { route: Route.applianceModelsNew }
  | { route: Route.applianceModelsEdit; params: { applianceModelId: string } }
  | { route: Route.devicesClaim }
  | { route: Route.clients }
  | { route: Route.clientsNew }
  | { route: Route.clientsEdit; params: { clientId: string } }
  | { route: Route.users }
  | { route: Route.usersEdit; params: { userId: string } }
  | { route: Route.usersInvite }
  | { route: Route.roles }
  | { route: Route.rolesNew }
  | { route: Route.rolesEdit; params: { roleId: string } }
  | { route: Route.profile }
  | { route: Route.confirmEmail; params?: { token?: string } }
  | { route: Route.legalTerms }
  | { route: Route.login }
  | { route: Route.logout }
  | { route: Route.register }
  | { route: Route.registerWithInvite }
  | { route: Route.forgotPassword }
  | { route: Route.resetPassword; params: { email: string; token: string } }
  | { route: Route.application; params: { applicationSlug: string } };

const matchingParametricRoute = (path: string): ParametricRoute | null => {
  const route = matchingRoute(path);
  if (!route) {
    return null;
  }

  const params = matchPath(route, path)?.params;
  switch (route) {
    case Route.appliances:
    case Route.appliancesNew:
    case Route.devicesClaim:
    case Route.clients:
    case Route.clientsNew:
    case Route.users:
    case Route.usersInvite:
    case Route.roles:
    case Route.rolesNew:
    case Route.profile:
    case Route.legalTerms:
    case Route.login:
    case Route.logout:
    case Route.register:
    case Route.registerWithInvite:
    case Route.forgotPassword:
    case Route.applianceModels:
    case Route.applianceModelsNew:
      return { route };

    case Route.application:
      return params && typeof params["applicationSlug"] === "string"
        ? {
            route,
            params: { applicationSlug: params.applicationSlug },
          }
        : null;

    case Route.appliancesEdit:
      return params && typeof params["applianceId"] === "string"
        ? {
            route,
            params: { applianceId: params.applianceId },
          }
        : null;

    case Route.applianceModelsEdit:
      return params && typeof params["applianceModelId"] === "string"
        ? {
            route,
            params: { applianceModelId: params.applianceModelId },
          }
        : null;

    case Route.clientsEdit:
      return params && typeof params["clientId"] === "string"
        ? {
            route,
            params: { clientId: params.clientId },
          }
        : null;

    case Route.usersEdit:
      return params && typeof params["userId"] === "string"
        ? {
            route,
            params: { userId: params.userId },
          }
        : null;

    case Route.rolesEdit:
      return params && typeof params["roleId"] === "string"
        ? {
            route,
            params: { roleId: params.roleId },
          }
        : null;

    case Route.confirmEmail:
      return params && typeof params["token"] === "string"
        ? {
            route,
            params: { token: params.token },
          }
        : { route };

    case Route.resetPassword:
      return params &&
        typeof params["email"] === "string" &&
        typeof params["token"] === "string"
        ? {
            route,
            params: { email: params.email, token: params.token },
          }
        : null;
  }
};

type LinkProps = Omit<RouterLinkProps, "to"> & ParametricRoute;

const generatePath = (route: ParametricRoute): string => {
  if ("params" in route && route.params) {
    return routerGeneratePath(route.route, route.params);
  }
  return route.route;
};

const Link = (props: LinkProps) => {
  let to, forwardProps;
  if ("params" in props) {
    const { route, params, ...rest } = props;
    to = routerGeneratePath(route, params);
    forwardProps = rest;
  } else {
    const { route, ...rest } = props;
    to = route;
    forwardProps = rest;
  }

  return <RouterLink to={to} {...forwardProps} />;
};

const useNavigate = () => {
  const routerNavigate = useRouterNavigate();
  const navigate = useCallback(
    (route: ParametricRoute | string) => {
      const path = typeof route === "string" ? route : generatePath(route);
      routerNavigate(path);
    },
    [routerNavigate]
  );
  return navigate;
};

export { Link, Route, matchingRoute, matchingParametricRoute, useNavigate };
export type { LinkProps, ParametricRoute };
