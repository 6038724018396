import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "components/Button";
import CookiePolicyModal from "components/CookiePolicyModal";
import PrivacyPolicyModal from "components/PrivacyPolicyModal";
import TermsAndConditionsModal from "components/TermsAndConditionsModal";

const AppFooter = () => {
  const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(false);

  return (
    <div className="d-flex justify-content-center gap-3 pt-3">
      <Button
        variant="text-primary"
        onClick={() => setShowTermsAndConditionsModal(true)}
      >
        <small>
          <FormattedMessage
            id="components.AppFooter.termsAndConditions.link"
            defaultMessage="Terms & Conditions"
          />
        </small>
      </Button>
      <Button
        variant="text-primary"
        onClick={() => setShowPrivacyPolicyModal(true)}
      >
        <small>
          <FormattedMessage
            id="components.AppFooter.privacyPolicy.link"
            defaultMessage="Privacy Policy"
          />
        </small>
      </Button>
      <Button
        variant="text-primary"
        onClick={() => setShowCookiePolicyModal(true)}
      >
        <small>
          <FormattedMessage
            id="components.AppFooter.cookiePolicy.link"
            defaultMessage="Cookie Policy"
          />
        </small>
      </Button>
      {showCookiePolicyModal && (
        <CookiePolicyModal onClose={() => setShowCookiePolicyModal(false)} />
      )}
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal onClose={() => setShowPrivacyPolicyModal(false)} />
      )}
      {showTermsAndConditionsModal && (
        <TermsAndConditionsModal
          onClose={() => setShowTermsAndConditionsModal(false)}
        />
      )}
    </div>
  );
};

export default AppFooter;
